import { AppIcon, React } from '@/app'
import { TagComposition, TagPresets, TagStyles } from '@/app/stylesheets/Tag'
import { ComponentVariants, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { View, Text, Icon, Touchable, Image } from '@/components'

type TagProps =
  Omit<PropsOf<typeof View>, 'styles' | 'variants' | 'responsiveVariants'>
  & Omit<PropsOf<typeof Touchable>, 'styles' | 'variants' | 'responsiveVariants'>
  & ComponentVariants<typeof TagStyles>
  & {
    selected?: boolean
    text?: string
    debugName?: string
    onPress?: PropsOf<typeof Touchable>['onPress']
    textProps?: PropsOf<typeof Text>
    icon?: AppIcon
    iconProps?: Partial<PropsOf<typeof Icon>>
    leftIconProps?: Partial<PropsOf<typeof Image>>
    style?: {}
    styles?: StylesOf<TagComposition>
  }

export const Tag = (props: TagProps) => {
  const {
    selected = false,
    text,
    debugName = '',
    textProps,
    iconProps,
    icon = iconProps?.name,
    leftIconProps = null,
    style = {},
    styles = {},
    variants = [],
    responsiveVariants = {},
    onPress = null,
    ...rest
  } = props

  const variantStyles = useDefaultComponentStyle<'u:Tag', typeof TagPresets>('u:Tag', {
    variants,
    responsiveVariants,
    styles,
  })

  const isPressable = TypeGuards.isFunction(onPress)

  const getStyles = (key: TagComposition) => ({
    ...variantStyles?.[key],
    ...(selected ? variantStyles?.[key + ':selected'] : {}),
    ...(isPressable ? variantStyles?.[key + ':pressable'] : {}),
  })

  const _styles: StylesOf<TagComposition> = {
    wrapper: getStyles('wrapper'),
    innerWrapper: getStyles('innerWrapper'),
    text: getStyles('text'),
  }

  const pressableProps = isPressable ? {
    onPress,
  } : {}

  const Wrapper = isPressable ? Touchable : View

  return (
    <Wrapper
      debugName={debugName}
      {...pressableProps}
      css={[_styles.wrapper, style]}
      {...rest}
    >
      <View variants={['row', 'alignCenter']} style={_styles.innerWrapper} >
        {leftIconProps ? <Image {...leftIconProps} /> : null}
        {text ? <Text text={text} style={_styles.text} {...textProps} /> : null}
        {icon ? <Icon name={icon} {...iconProps} /> : null}
      </View>
    </Wrapper>
  )
}
