import { api } from '@/app'
import { PaginationResponse, QueryManager, useState } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { Source } from '@/types'

const BASE_URL = 'learn/sources/'

export const sourcesManager = new QueryManager({
  itemType: {} as Source,
  name: 'lessonSources',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Source>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Source>(`${BASE_URL}${id}/`)

    return response.data
  },
})

export const useSources = () => {

  const sources = sourcesManager.use()
  const [sourcesOptions, setSourcesOptions] = useState<Source[]>([])

  return {
    sources,
    sourcesOptions,
    setSourcesOptions,
  }
}

export type UseSourcesReturn = ReturnType<typeof useSources>
