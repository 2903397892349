import { I18N } from '@/app'
import { Button, Modal } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'

export const UnknownOrganisation = () => {
  const visible = useAppSelector(store => store.AppStatus.modals.unknownOrganisation)
  const toggle = () => AppStatus.setModal('unknownOrganisation')
  return (
    <Modal
      debugName={'Unknown Organisation Modal'}
      title={I18N.t('modals.unknownOrg.title')}
      description={I18N.t('modals.unknownOrg.description')}
      visible={visible}
      toggle={toggle}
      showClose={false}
      centered
      variants={['centered', 'boxPadding:4', 'box:responsiveness']}
    >
      <Button
        text={'Got it'}
        debugName={'On Press Unknown Organisation Model'}
        onPress={toggle}
      />
    </Modal>
  )
}
