import { LoginForm } from './LoginForm'
import { SignupForm } from './SignupForm'
import { ForgotPasswordAfterForm } from './ForgotPasswordAfterForm'
import { ForgotPasswordBeforeForm } from './ForgotPasswordBeforeForm'
import { AboutYouForm } from './AboutYouForm'
import { AboutJobForm } from './AboutJobForm'
import { VerifyEmailAfterForm } from './VerifyEmailAfterForm'
import { VerifyEmailBeforeForm } from './VerifyEmailBeforeForm'

export const AuthForms = {
  LoginForm,
  SignupForm,
  ForgotPasswordBeforeForm,
  ForgotPasswordAfterForm,
  AboutYouForm,
  AboutJobForm,
  VerifyEmailBeforeForm,
  VerifyEmailAfterForm,
}
