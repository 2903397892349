import { useBooleanToggle } from '@codeleap/common'
import { Text, View, Image, Button, ActionIcon } from '@/components'
import Favicon from '@/images/favicon.png'
import { Settings, useAppI18N, userAgentParser, variantProvider } from '@/app'
import { DEVICE } from 'ua-parser-js'

const device = userAgentParser.getDevice()
const os = userAgentParser.getOS()
const storeText = os.name?.toLowerCase() === 'ios' ? 'iosStore' : 'androidStore'

const handleUseApp = () => {
  const url = `${Settings.Fetch.ProductionURL}use_app/?os=${os.name}&current_url=${window.location}`
  window.location = url
}

const isVisible = device.type === DEVICE.MOBILE || device.type === DEVICE.TABLET

export const UseAppBanner = (props) => {
  const { t } = useAppI18N()

  const [visible, toggle] = useBooleanToggle(isVisible)

  return (
    <View style={[styles.wrapper, { maxHeight: visible ? 100 : 0 }]}>
      <View variants={['bg:neutral1', `padding:2`, 'row', 'justifySpaceBetween', 'fullWidth']}>
        <View variants={['row', 'center', 'gap:1']}>
          <Image source={Favicon} variants={['size:4']} style={styles.favicon} alt='Hurd Logo' />
          <View variants={['column']}>
            <Text text={Settings.AppName} variants={['p2', 'color:neutral5']} />
            <Text text={t(`useAppBanner.${storeText}`)} variants={['p3', 'color:neutral6']} />
          </View>
        </View>

        <View variants={['row', 'center', 'gap:1']}>
          <Button text={t('useAppBanner.useApp')} variants={['small', 'paddingHorizontal:2']} debugName={'Use app button'} onPress={handleUseApp} />
          <ActionIcon debugName='close banner button' name='x' variants={['neutral2', 'small']} onPress={toggle} />
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    zIndex: 1,
    overflow: 'hidden',
    transition: 'max-height 0.15s ease-in-out',
    transform: 'translateY(-2px)',
    flexShrink: 0,
  },
  favicon: {
    borderRadius: 4,
  },
}), true)
