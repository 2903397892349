import { Theme, useAppI18N, variantProvider } from '@/app'
import { textStyles } from '@/app/textStyles'
import { CardBase, CardBaseProps, Image, View, Text, Icon, ProgressBar } from '@/components'
import { Module } from '@/types'
import { PropsOf } from '@codeleap/common'
import { useMediaQuery } from '@codeleap/web'

type LearnCardProps = Partial<Omit<CardBaseProps, 'debugName'>> &{
 module: Module
 progressBarProps?: PropsOf<typeof ProgressBar>
 imageProps?: PropsOf<typeof Image>
}

// This will need to be changed to always match scaled font size
const ICON_SIZE = textStyles.p4.size

export const LearnCard = (props: LearnCardProps) => {
  const { module, progressBarProps, imageProps, ...cardProps } = props

  const { id, image, progress, title, chapters } = module

  const mediaQuery = Theme.media.down('tiny')

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  const GAP = isMobile ? 1 : 2

  const { t } = useAppI18N()

  const learnLessons = t('learn.cardLessonsCounter', {
    lessons: chapters.length,
  })

  return (

    <CardBase
      style={Theme.effects.light}
      styles={{ wrapper: styles.wrapper, innerWrapper: styles.innerWrapper }}
      variants={['fullWidth']}
      debugName={`LearnCard:${id}`}
      gap={GAP}
      {...cardProps}
    >
      <Image source={image} style={styles.image} variants={['fullWidth', 'fullHeight']} {...imageProps} />
      <View variants={['flex', 'column', 'padding:2', 'alignStart', 'justifyStart', 'fullWidth', 'gap:0.5']}>
        <View variants={['fullWidth', 'h:auto']}>
          <View variants={['flex', 'alignCenter', 'gap:0.5', 'noWrap']}>
            <Icon debugName={`LearnCard:${id} icon `} name={'book'} color={'neutral5'} size={ICON_SIZE}/>
            <Text variants={['p4']} text={learnLessons}/>
          </View>
          <View>
            <ProgressBar progress={progress} text={`${progress}%`} {...progressBarProps} />
          </View>
        </View>
        <View>
          <Text variants={['h4', 'wrap']} text={title}></Text>
        </View>
      </View>
    </CardBase>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.spacing.padding(0),
    ...theme.presets.fullWidth,
    maxWidth: 625,

  },
  innerWrapper: {
    ...theme.presets.row,
    ...theme.presets.fullWidth,
  },
  image: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    borderTopLeftRadius: theme.borderRadius.small,
    borderBottomLeftRadius: theme.borderRadius.small,

    [Theme.media.down('tiny')]: {

      width: 75,
      height: 75,
    },
  },
}), true)
