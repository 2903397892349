import React from 'react'
import { codeleapWebCache } from '@codeleap/web'
import { CacheProvider } from '@emotion/react'
import { Root } from './src/Root'
import { AppStatus } from './src/redux'
import { useEffect } from 'react'
import { PermissionUtils, useFirebaseUser } from './src/utils'
import { APIClient } from './src/services'
import { Redirect } from './src/components'
import { WrapPageElementBrowserArgs } from 'gatsby'

export const wrapRootElement = ({ element }) => {
  return (
    <CacheProvider value={codeleapWebCache}>

      <Root>
        {element}
      </Root>
    </CacheProvider>
  )
}

const ShowAppStatus = () => {
  useEffect(() => {

    AppStatus.set('splash')

    return () => {

      AppStatus.set('idle')
    }
  }, [])
  return null
}

const DEBUG = false

const debugLog = (...args) => {
  if (!DEBUG) return
  console.log(...args)
}

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
  const isRoutePublic = PermissionUtils.isRoutePublic(props.location.pathname)
  const firebase = useFirebaseUser()
  const user = APIClient.Session.useProfile()

  debugLog('isRoutePublic', isRoutePublic)

  if (isRoutePublic) return element

  const loadingVars = [
    user.isLoading,
  ]

  debugLog('loadingVars', loadingVars)

  const loading = loadingVars.some(Boolean)

  if (loading || firebase.isLoading) {
    debugLog('loading')
    return <ShowAppStatus />
  }

  debugLog({
    firebaseUser: firebase.firebaseUser,
    user: user.data,
  })

  const hasUser = [
    firebase.firebaseUser?.uid,
    user.data?.id,
  ].every(Boolean)
  debugLog('hasUser', hasUser)

  if (!hasUser) {
    return <Redirect to='/auth/login' />
  }

  return element
}
