import React from 'react'
import { ActionIcon, ActivityIndicator, Modal } from '@/components'
import { store } from './store'
import { YouTube } from './Player'
import { useWindowSize } from '@/utils'
import { toggleModal } from '@codeleap/web'
import { variantProvider } from '@/app'

const modalId = 'player_youtube'

export const Fullscreen = () => {
  const videoUrl = store(store => store.videoUrl)

  const [width, height] = useWindowSize()

  const handleClose = () => {
    toggleModal(modalId, false)
    store.setState({ videoUrl: null })
  }

  return (
    <Modal
      modalId={modalId}
      closable={false}
      variants={['fullscreen', 'center', 'player', 'noScroll']}
      debugName='Modal fullscreen player'
      showClose={false}
      onClose={() => store.setState({ videoUrl: null })}
    >
      <YouTube 
        video={videoUrl} 
        style={styles.youtube}
        options={{
          width,
          height,
          playerVars: {
            autoplay: 1,
          }
        }}
      />

      <ActivityIndicator debugName='loading:video' variants={['large']} style={styles.loading} />

      <ActionIcon 
        debugName='close player youtube'
        name='x' 
        variants={['playerClose']}
        onPress={handleClose}
      />
    </Modal>
  )
}

Fullscreen.modalId = modalId

const styles = variantProvider.createComponentStyle(theme => ({
  youtube: {
    zIndex: 10,
  },
  loading: {
    ...theme.presets.absolute,
    zIndex: 9,
  }
}), true)
