import { AppStatus, ModalName, useAppSelector } from '@/redux'
import { useIsMobile } from '@/utils'
import { View, Modal } from '@/components'
import { variantProvider, React } from '@/app'
import { PropsOf } from '@codeleap/common'
import { AuthModalHeader, AuthModalHeaderProps } from './AuthModalHeader'

type ModalProps = PropsOf<typeof Modal>

type AuthModalProps = Partial<ModalProps> & {
  image?: AuthModalHeaderProps['image']
  name: ModalName
  imageHeight?: number
  small?: boolean
  centered?: boolean
  center?: boolean
}

export const AuthModal = (props: AuthModalProps) => {
  const {
    children,
    image,
    name,
    imageHeight = 76,
    small = false,
    centered = true,
    closable = true,
    center = false,
    ...modalProps
  } = props

  const status = useAppSelector(store => store.AppStatus.status)

  const isOpen = useAppSelector(store => store.AppStatus.modals[name])

  const isMobile = useIsMobile()

  const modalBoxStyles = React.useMemo(() => ({
    ...styles.modalBox,
    ...(small ? {
      width: MODAL_WIDTH * 0.65,
      height: 'auto',
      minHeight: 700,
    } : {}),
  }), [])

  return (
    <Modal
      showClose={false}
      renderHeader={() => <AuthModalHeader showClose={closable} name={name} image={image} imageHeight={imageHeight} centered={centered} />}
      variants={[isMobile ? 'fullscreen' : (center && 'centered'), 'overflow:visible']}
      styles={{
        box: modalBoxStyles,
        body: styles.modalBody,
      }}
      visible={isOpen && !(status === 'loading' || status === 'done')}
      {...modalProps}
      closable={closable}
      toggle={() => {
        modalProps?.toggle?.()
        AppStatus.setModal([name, false])
      }}
    >
      <View css={[styles.formWrapper, small && { justifyContent: 'space-between', flex: 1 }]}>
        {children}
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 736

const styles = variantProvider.createComponentStyle((theme) => ({
  modalBox: {
    ...theme.spacing.padding(0),
    // @ts-ignore
    borderRadius: theme.borderRadius.large,
    width: MODAL_WIDTH,

    [theme.media.down('small')]: {
      ...theme.presets.fullWidth,
      height: 'auto',
      borderRadius: 0,
    },
  },
  modalBody: {
    flex: 1,
    borderRadius: theme.borderRadius.large,

    [theme.media.down('small')]: {
      maxHeight: 'none',
      minHeight: 'none',
    },
  },
  formWrapper: {
    ...theme.presets.column,
    ...theme.spacing.padding(4),

    [theme.media.down('small')]: {
      width: '100%',
    },
  },
}), true)
