import { variantProvider } from '@/app'
import { Modal, Text, View, Link, Button } from '@/components'
import { Source } from '@/types'
import { ensureHttps } from '@/utils'
import { AnyFunction, assignTextStyle, useI18N } from '@codeleap/common'

type ModalSourcesProps = {
  toggle: AnyFunction
  visible: boolean
  sources: Source[]
}

export const ModalSources = (props: ModalSourcesProps) => {

  const { visible, toggle, sources } = props

  const { t } = useI18N()

  const hasSources = sources?.length > 0

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      debugName={'Sources modal'}
      title={t('learn.chapterScreen.sourcesPartners')}
      showClose={false}
      variants={['centered', 'scroll']}
      styles={{
        box: styles.box,
        title: styles.title,
      }}
    >
      <View variants={['column', 'gap:1.25']}>
        {hasSources && sources.map(source => (
          <View variants={['column']}>
            <Text text={source.name} variants={['color:neutral6', 'textCenter']} />
            <Link variants={['noUnderline']} to={ensureHttps(source.link)} openNewTab>
              <Text text={source.link} variants={['textCenter', 'color:primary3']} />
            </Link>
          </View>
        ))}
      </View>
      <Button
        debugName='Dismiss sources modal'
        text={t('dismiss')}
        variants={['marginTop:2']}
        style={styles.btn}
        onPress={() => toggle(false)}
      />
    </Modal>
  )
}

const MODAL_WIDTH = 480

const styles = variantProvider.createComponentStyle(theme => ({
  box: {
    width: theme.presets.calcWidth(theme.spacing.value(4)),
    maxWidth: MODAL_WIDTH,
    ...theme.spacing.padding(4),
    borderRadius: theme.borderRadius.large,
  },
  title: {
    ...assignTextStyle('h2')(theme).text,
  },
  btn: {
    minHeight: theme.values.itemHeight.default,
  },
}), true)
