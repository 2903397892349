import { React, Settings } from '@/app'
import { AvatarStyles } from '../app/stylesheets/Avatar'
import {
  AvatarComposition,
  ComponentVariants,
  matchInitialToColor,
  PropsOf,
  StylesOf,
  TypeGuards,
  useDefaultComponentStyle,
  useMemo,
  WebInputFile,
  IconPlaceholder,
  getNestedStylesByKey,
} from '@codeleap/common'
import { Image, View, Text, Touchable, Icon, CropPicker } from '.'
import { Badge, BadgeComponentProps, useFileInput } from '@codeleap/web'

type ImageProps = PropsOf<typeof Image>

export type AvatarProps = Omit<
  PropsOf<typeof Touchable>,
  'styles' | 'variants'
> &
  BadgeComponentProps & {
    styles?: StylesOf<AvatarComposition>
    onChange?: (picture: WebInputFile) => void
    image?: ImageProps['source']
    badge?: boolean
    name?: string | string[]
    debugName: string
    firstNameOnly?: boolean
    text?: string
    description?: string
    icon?: IconPlaceholder
    badgeIcon?: IconPlaceholder
  } & ComponentVariants<typeof AvatarStyles>

export const Avatar = (props: AvatarProps) => {
  const {
    debugName,
    name = '',
    firstNameOnly = true,
    image,
    responsiveVariants = {},
    variants = [],
    styles = {},
    style = {},
    icon,
    badgeIcon,
    onChange,
    onPress,
    text = '',
    description,
    badge = false,
    badgeProps = {},
    ...viewProps
  } = props as AvatarProps

  const input = useFileInput()

  const editable = !!onChange
  const hasImage = !!image

  const handlePress = async () => {
    if (!editable) {
      onPress?.()
    } else {
      const files = await input.openFilePicker()

      if (!files.length) return

      onChange?.(files[0])
    }
  }

  const variantStyles = useDefaultComponentStyle<
    'u:Avatar',
    typeof AvatarStyles
  >('u:Avatar', {
    responsiveVariants,
    variants,
    styles,
  })

  const { initials, randomColor } = useMemo(() => {
    const [first = '', last = ''] = TypeGuards.isString(name)
      ? name.split(' ')
      : name
    const initials = [first[0]]
    if (!firstNameOnly) {
      initials.push(last[0])
    }
    return {
      initials: initials.join(' '),
      randomColor: matchInitialToColor(first[0]),
    }
  }, [name, firstNameOnly])

  const renderContent = () => {
    if (hasImage) return <Image source={image} style={variantStyles.image} />
    if (icon) return <Icon name={icon} style={variantStyles.icon} />
    return <Text text={text || initials} style={variantStyles.initials} />
  }

  const hasBackgroundColor = !!variantStyles?.touchable?.backgroundColor

  const badgeStyles = getNestedStylesByKey('badge', variantStyles)

  const Wrapper = TypeGuards?.isFunction(onPress) || editable ? Touchable : View

  return (
    <>
      <CropPicker ref={input.ref} targetCrop={Settings.Images.Avatar} />
      <View css={[variantStyles.wrapper, style]}>
        <Wrapper
          debugName={'Avatar ' + debugName}
          onPress={handlePress}
          css={[
            variantStyles.touchable,
            !hasBackgroundColor && {
              backgroundColor: randomColor,
            },
          ]}
          {...viewProps}
        >
          {renderContent()}
          {!!description ? (
            <View style={variantStyles.descriptionOverlay}>
              <Text text={description} style={variantStyles.description} />
            </View>
          ) : null}
          <Badge badge={badge} styles={badgeStyles} {...badgeProps} />
        </Wrapper>
        {badgeIcon && (
          <Touchable
            debugName={`${debugName} badge icon`}
            style={variantStyles.badgeIconWrapper}
            onPress={onPress}
          >
            <Icon name={badgeIcon} style={variantStyles.badgeIcon} />
          </Touchable>
        )}
      </View>
    </>
  )
}
