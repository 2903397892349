import { AnyFunction, TypeGuards } from '@codeleap/common'
import { PagerRef } from '@codeleap/web'
import React, { useRef, useState } from 'react'

type UsePager<T = any> = {
  pages: Array<T>
  onFinish?: AnyFunction
  onBack?: AnyFunction
  onSwitch?: (to: 'next' | 'back') => void
  initialPage?: number
}

export function usePager<T extends object = any>(args: UsePager<T>) {
  const {
    pages,
    onFinish = null,
    onBack = null,
    onSwitch = null,
    initialPage = 0,
  } = args

  const [page, setPage] = useState(initialPage)
  const pagerRef = useRef<PagerRef>(null)

  const isLast = React.useMemo(() => page === pages?.length - 1, [page])
  const isFirst = React.useMemo(() => page  === 0, [page])

  const currentPage = pages?.[page]

  const next = () => {
    if (isLast) {
      if (TypeGuards.isFunction(onFinish)) onFinish?.()
      return
    }

    setPage(page + 1)

    if (TypeGuards.isFunction(onSwitch)) onSwitch?.('next')
  }

  const previous = () => {
    if (isFirst) {
      if (TypeGuards.isFunction(onBack)) onBack?.()
      return
    }

    setPage(page - 1)

    if (TypeGuards.isFunction(onSwitch)) onSwitch?.('back')
  }

  return {
    isLast,
    isFirst,
    next,
    previous,
    page,
    setPage,
    ref: pagerRef,
    currentPage,
  }
}

export type UsePagerReturn = ReturnType<typeof usePager>
