import { IS_SSR } from '@/app'

export function rdt(type, metadata = {}) {
  if (IS_SSR) return
  // @ts-ignore
  if (window.rdt) {
    // @ts-ignore
    window.rdt('track', type, metadata)
  }
}
