import { IS_SSR, React, Settings, variantProvider } from '@/app'
import { TypeGuards, useI18N, useMemo } from '@codeleap/common'
import { Button, Modal, View, Text, Tooltip, ActivityIndicator, Icon } from '@/components'
import { Share } from '@/utils'

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TumblrShareButton,
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  TumblrIcon,
} from 'react-share'
import { APIClient } from '@/services'

const shareOptions = [
  [EmailShareButton, EmailIcon],
  [WhatsappShareButton, WhatsappIcon],
  [FacebookShareButton, FacebookIcon],
  [LinkedinShareButton, LinkedinIcon, 'linkedIn'],
  [TelegramShareButton, TelegramIcon],
  [TwitterShareButton, (props) => <Icon name='twitter' {...props} />],
  [TumblrShareButton, TumblrIcon],
  [InstapaperShareButton, InstapaperIcon],
]

export const ShareModal = () => {
  const shareData = Share.useShareStore(store => store.data)
  const setShareData = Share.useShareStore(store => store.setShare)

  const { t } = useI18N()

  const visible = React.useMemo(() => !TypeGuards?.isNil(shareData), [shareData])
  const onClose = () => setShareData(null)

  const content = {
    url: shareData?.url,
    title: shareData?.text,
    description: shareData?.text,
    text: shareData?.text,
    summary: shareData?.text,
    quote: shareData?.title,
    appId: Settings.ApiCredentials.FacebookSDK.AppId,
    separator: ' ',
  }

  const canClipboard = useMemo(() => {
    if (IS_SSR) return

    return !!navigator.clipboard
  }, [])

  const copyLink = React.useCallback(async () => {
    await navigator.clipboard.writeText(content?.url)
    const levelUp = await APIClient.Session.sharedApp()
    if(levelUp) {
      onClose?.()
    }
  }, [content?.url, onClose])

  const sharedApp = async () => {
    onClose?.()
    await APIClient.Session.sharedApp()
  }

  const options = React.useMemo(() => {
    return shareOptions.map(([ShareAction, Icon, provider = null], index) => {
      let url = content?.url

      if (provider === 'linkedIn') {
        // linkedin cannot process urls that receive parameters
        const origin = Share.getWebsite()
        const urlParts = content?.url?.split(origin)
        url = origin + '/' + urlParts?.[1]?.split('/')?.[1] + '/'
      }

      return (
        <View onClick={sharedApp} key={index + 'share-modal'} style={styles.share}>
          <ShareAction {...content} url={url} style={styles.share}>
            {/* @ts-ignore */}
            <Icon size={ICON_SIZE} />
          </ShareAction>
        </View>
      )
    })
  }, [content])

  return (
    <Modal
      visible={visible}
      toggle={onClose}
      debugName={'Share Modal'}
      showClose={false}
      variants={['centered']}
      styles={{
        wrapper: { zIndex: 99999 },
        box: styles.boxModal,
      }}
    >
      <View variants={['column', 'fullWidth', 'alignStart', 'justifyCenter', 'marginBottom:1']}>
        <Text
          variants={['h1', 'ellipsis']}
          style={styles.text}
          text={shareData?.title ?? t('share.title')}
        />
      </View>

      <View variants={['column', 'fullWidth', 'alignStart', 'justifyCenter', 'gap:3']}>
        <Text variants={['p1']} text={shareData?.text ?? t('share.description')} />

        <View variants={['row', 'wrap', 'gap:2', 'center']}>
          {options}
        </View>

        <View variants={['fullWidth', 'row', 'gap:2']}>
          {canClipboard ? (
            <Tooltip
              debugName={'shareModal:tooltip'}
              content={<Text text={t('share.copyMessage')} variants={['p2', 'color:positive2']} />}
              openOnPress
              openOnHover={false}
              side='top'
              closeOnClickOutside
              variants={['small', 'success']}
              style={styles.tooltip}
            >
              <Button
                debugName='shareModal:clipboard'
                text={t('share.copyText')}
                onPress={copyLink}
                variants={['outline', 'fullWidth', 'large', 'pill', 'flex']}
              />
            </Tooltip>
          ) : null}

          <Button
            debugName='shareModal:done'
            text={t('share.modalCancel')}
            onPress={onClose}
            variants={['large', 'pill', 'flex', 'fullWidth']}
          />
        </View>
      </View>

      {visible ? null : (
        <View variants={['absolute', 'whole', 'center', 'backgroundColor:neutral1']}>
          <ActivityIndicator debugName='shareModal:indicator' />
        </View>
      )}
    </Modal>
  )
}

const MAX_WIDTH = 500
const ICON_SIZE = 50

const styles = variantProvider.createComponentStyle((theme) => ({
  boxModal: {
    width: `calc(100vw - ${theme.spacing.value(4)}px)`,
    maxWidth: MAX_WIDTH,
    ...theme.spacing.padding(4),
    borderRadius: theme.borderRadius.large,

    [theme.media.down('small')]: {
      ...theme.spacing.padding(2),
    },
  },
  share: {
    borderRadius: theme.borderRadius.tiny,
    overflow: 'hidden',
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  text: {
    maxWidth: '100%',
  },
  tooltip: {
    width: '100%',
    flex: 1,
  },
}), true)
