import { AppStatus, useAppSelector } from '@/redux'
import { useIsMobile } from '@/utils'
import { Image, View, ActionIcon, Modal } from '@/components'
import { AppImages, Theme, variantProvider } from '@/app'
import { SuccessForm } from '../Auth/SuccessForm'

export const SuccessModal = (modalProps) => {
  const status = useAppSelector(store => store.AppStatus.status)
  const isOpen = useAppSelector(store => store.AppStatus.modals.success)

  const isMobile = useIsMobile()

  const ModalHeader = () => {
    return (
      <View variants={['column', 'justifyCenter', 'bg:primary1', 'paddingHorizontal:4', 'paddingTop:3']}>
        <View variants={['column', 'justifyEnd', 'relative']} style={styles.innerWrapper} >
          <ActionIcon
            debugName='SuccessModal:close'
            name={'x'}
            variants={['minimal', 'primary3', 'alignSelfEnd', 'absolute']}
            style={styles.modalHeaderIcon}
            iconProps={{
              size: Theme.values.iconSize[3],
            }}
            onPress={() => AppStatus.setModal('success')}
          />
          <Image source={AppImages.Celebration2} objectFit='contain' style={styles.modalHeaderImage} />
        </View>
      </View>
    )
  }

  return (
    <Modal
      showClose={false}
      renderHeader={ModalHeader}
      variants={[isMobile ? 'fullscreen' : 'centered']}
      styles={{ box: styles.modalBox, body: styles.modalBody }}
      visible={isOpen && !(status === 'loading' || status ==='done')}
      onClose={() => AppStatus.setModal('success')}
      {...modalProps}
    >
      <View css={[styles.formWrapper]}>
        <SuccessForm />
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 420
const MODAL_HEADER_IMAGE_HEIGHT = 380

const styles = variantProvider.createComponentStyle((theme) => ({
  innerWrapper: {
    ...theme.presets.fullHeight,
  },
  modalBody: {
    backgroundColor: theme.colors.primary1,
    flex: '1',

    [Theme.media.down('small')]: {
      maxHeight: 'none',
    },
  },
  modalBox: {
    ...theme.spacing.padding(0),
    borderRadius: theme.borderRadius.large,
    width: MODAL_WIDTH,

    [Theme.media.down('small')]: {
      ...theme.presets.fullWidth,
      height: 'auto',
      borderRadius: 0,
    },
  },
  modalHeaderImage: {
    height: MODAL_HEADER_IMAGE_HEIGHT,
  },
  modalHeaderIcon: {
    top: '0',
  },
  formWrapper: {
    ...theme.presets.column,
    ...theme.spacing.padding(4),
    paddingTop: 0,
    flex: '1',

    [Theme.media.down('small')]: {
      width: '100%',
    },
  },
}), true)
