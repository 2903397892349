import { parseFirebaseNotification } from './firebase'

export const handleNotification = (message, type) => {
  logger.log('handleNotification', { message, type })

  const parsedNotification = parseFirebaseNotification(message)
  
  // NOTE handle notifications stuff for the app here

  logger.info('Notification', parsedNotification)
}
