import { variantProvider } from '@/app'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { useI18N } from '@codeleap/common'
import { Button, Text, View } from '@/components'
import { navigate } from 'gatsby'

export const SuccessForm = () => {
  const { t } = useI18N()
  const pulse = APIClient.PulseQuestions.usePulseQuestions()
  const { profile } = APIClient.Session.useSession()

  const onGetStarted = () => {
    AppStatus.setModal(['success', false])
    if (!profile.organization.is_unknown && pulse.available) {
      pulse.openQuestions('onboarding_page')
      return
    }

  }

  const onEditProfile = () => {
    AppStatus.setModal(['success', false])
    AppStatus.authFinished()

    setTimeout(() => {
      navigate('/profile/edit')
    }, 1000)
  }

  if (!profile) return null

  return (
    <View variants={['flex', 'column', 'justifySpaceBetween']} >
      <View variants={['flex', 'column', 'marginBottom:4']}>
        <Text
          variants={['h1', 'extraBold', 'textCenter']}
          text={t('Success.title')}
        />
        <Text variants={['p1', 'textCenter']}>
          {t('Success.sub')}&nbsp;<Text variants={['bold']} text={profile.display_name} style={styles.profileName} />
        </Text>
        <Text variants={['p4', 'textCenter']} text={t('Success.txt2')} />
      </View>
      <Button
        text={t('Success.txt1')}
        debugName={'Onboarding finish - get started button press'}
        variants={['pill', 'large', 'neutral9', 'fullWidth']}
        onPress={onGetStarted}
      />
      <Button
        variants={['outline', 'large', 'fullWidth', 'marginTop:2']}
        text={t('Success.txt3')}
        debugName={'Onboarding finish - Navigate to edit profile'}
        onPress={onEditProfile} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  profileName: {
    display: 'inline-block',
  },
}), true)
