import { includePresets } from '@codeleap/common'
import { assignTextStyle, variantProvider } from '..'

export type StoryAvatarComposition =
  | 'wrapper'
  | 'textsWrapper'
  | 'textUsername'
  | 'textDate'
  | 'organisationWrapper'
  | 'textOrganisationAbout'
  | 'textOrganisation'

const createStoryAvatarStyle = variantProvider.createVariantFactory<StoryAvatarComposition>()

const presets = includePresets((styles) => createStoryAvatarStyle(() => ({ wrapper: styles })),
)

export const StoryAvatarStyles = {
  ...presets,
  default: createStoryAvatarStyle((theme) => ({
    textUsername: {
      color: theme.colors.neutral6,
      letterSpacing: 'unset',
    },
    textOrganisation: {
      textDecoration: 'underline',
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.primary3,
    },
    textDate: {
      color: theme.colors.neutral5,
    },
    textOrganisationAbout: {
      color: theme.colors.neutral5,
    },
  })),
  textsNeutral10: createStoryAvatarStyle((theme) => ({
    textUsername: {
      color: theme.colors.neutral10,
    },
    textDate: {
      color: theme.colors.neutral10,
    },
  })),
}
