import { useForceRender } from '@codeleap/common'
import { useEffect, useRef } from 'react'
import type ReactPlayer from 'react-player/lazy'

let Player:ReactPlayer = (() => {
  return null
}) as unknown as ReactPlayer

export const useReactPlayer = () => {
  const forceRender = useForceRender()

  useEffect(() => {

    import('react-player/lazy').then((module) => {
      Player = module.default as unknown as ReactPlayer
      forceRender()
    })
  }, [])

  return Player
}
