import { useEffect, useRef, useState } from 'react'

export const useHover = (stopAfter?: number) => {
  const timeoutRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleMouseMove = () => {
      if (!isHovered || !stopAfter) return
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(
        () => setIsHovered(false),
        stopAfter,
      )
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isHovered])

  return { isHovered, setIsHovered }
}
