import React, { useCallback, useRef } from 'react'
import { SliderProps } from '@codeleap/web'
import { Slider, View, Text } from '@/components'

type QuestionSliderProps = Partial<SliderProps> & {
  showScale?: boolean
  rightLabel?: string
  leftLabel?: string
}

const sliderValues = Array(11).fill(null).map((_, idx) => [idx, idx.toString()])

export function QuestionSlider({
  showScale,
  leftLabel = 'Completely dissatisfied',
  rightLabel = 'Completely satisfied',
  trackMarks,
  defaultValue,
  min,
  onValueChange,
  ...rest
}: QuestionSliderProps) {

  const [value, setValue] = React.useState(defaultValue)
  const valueRef = useRef(value?.[0])

  const Scale = React.useCallback(() => {
    if (!showScale) return null

    return <View variants={['fullWidth', 'row', 'justifySpaceBetween', 'relative']}>
      {sliderValues.map((v, idx) => {
        if (idx < min) return null

        return (
          <Text
            text={idx.toString()}
            variants={['p4', 'color:neutral9', 'marginLeft:1']}
            key={idx + 'pulse-slider-label'}
          />
        )
      })}
    </View>
  }, [])

  const changeTimer = useRef(null)

  const handleChange = useCallback((vl) => {

    if (changeTimer.current) clearTimeout(changeTimer.current)

    setValue(vl)
    valueRef.current = vl?.[0]
    changeTimer.current = setTimeout(() => {

      onValueChange([valueRef.current])
    }, 200)
  }, [onValueChange])

  return (
    <View variants={['column', 'fullWidth']}>
      <Scale />
      <Slider
        {...rest}
        value={value}
        onValueChange={handleChange}
        min={min}
        trackMarks={null}
        variants={['pulse', !showScale && 'pulse:noScale']}
        label={leftLabel}
        description={rightLabel}
        onPressThumbSetValue={false}
      />
    </View>
  )
}
