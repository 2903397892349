import { createForm } from '@codeleap/common'
import { I18N } from '../i18n'
import { signup } from './auth'

export const createPasswordResetForm = () => {
  const signupForm = signup()

  return createForm('passwordReset', {
    password: {
      ...signupForm.config.password,
      label: I18N.t('passwordReset.newPassword'),
    },
    repeatPassword: {
      ...signupForm.config.repeatPassword,
      label: I18N.t('passwordReset.confirmNewPassword'),
    },
  })
}

export const passwordReset = () => createPasswordResetForm()

