import { Button, Image, Link } from '@/components'
import { variantProvider, Settings, AppImages } from '@/app'

type StoreButtonsProps = {
  store: 'playStore' | 'appStore'
}

const styles = variantProvider.createComponentStyle((theme) => ({
  common: {
    backgroundColor: theme.colors.neutral10,
    borderRadius: theme.borderRadius.tiny,
    ...theme.presets.center,
    display: 'flex',
    height: 56,
  },
  appStore: {
    width: '153.87px',
  },
  playStore: {
    width: '150.25px',
  },
  appStoreImage: {
    height: '30.56px',
  },
  playStoreImage: {
    height: '28.81px',
  },
}), true)

export function StoreButton({ store }: StoreButtonsProps) {
  const isPlayStore = store === 'playStore'

  const linkTo = isPlayStore ? Settings.StoresLinks.PlayStore : Settings.StoresLinks.AppStore

  const currentImage = {
    img: isPlayStore ? AppImages.PlayStore : AppImages.AppStore,
    alt: isPlayStore ? 'Play Store' : 'App Store',
  }

  return (
    <Link
      to={linkTo}
      css={[styles.common, isPlayStore ? styles.playStore : styles.appStore]}
    >
      <Image source={currentImage.img} alt={currentImage.alt} css={[isPlayStore ? styles.playStoreImage : styles.appStoreImage]}/>
    </Link>
  )
}
