import { includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'

export type ImageComposition = 'wrapper'

const createImageStyle = variantProvider.createVariantFactory<ImageComposition>()

export const ImagePresets = includePresets((styles) => createImageStyle(() => ({ wrapper: styles })))

export const AppImageStyles = {
  ...ImagePresets,
  default: createImageStyle(() => ({
    wrapper: {},
  })),
  skeleton: createImageStyle(theme => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  round: createImageStyle(theme => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  'fileSelect:preview': createImageStyle(() => ({
    wrapper: {
      objectFit: 'contain',
      margin: '0 auto',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  })),
  'size:1': createImageStyle((theme) => ({
    wrapper: {
      height: theme.values.iconSize[1],
      width: theme.values.iconSize[1],
    },
  })),
  'size:4': createImageStyle((theme) => ({
    wrapper: {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
  })),
}
