export const BlockedRoutes = [
  'achievements/{{id}}',
  'auth/aboutJob',
  'auth/aboutYou',
  'learn/lessonView/{{id}}',
  'profile/edit',
  'profile/menu',
  'profile/settings',
  'profile/achievements',
  'profile',
]

type isPathAllowedProps = {

  pathname: string

}

function matchRoutes(pathname: string, routes: string[]) {
  const normalizedRoutes = routes.map(route => {
    return route.replace(/{{\w+}}/g, '[^/]+')
  })

  const normalizedPathname = pathname.length > 1 ? pathname.replace(/\/$/, '') : pathname

  return normalizedRoutes.some(route => {
    const regex = new RegExp(`^${route}$`)
    return regex.test(normalizedPathname)
  })
}

function isRouteBlocked({ pathname }: isPathAllowedProps) {
  const blockedRoutes = BlockedRoutes.map(route => {
    // Convert '{{id}}' or similar placeholders into a pattern
    return route.replace(/{{\w+}}/g, '[^/]+')
  },
  )

  // Normalize the pathname to ensure it doesn't end with a slash unless it's the only character
  const normalizedPathname = pathname.length > 1 ? pathname.replace(/\/$/, '') : pathname

  const isBlocked = blockedRoutes.some(routePattern => {
    const regex = new RegExp(`^${routePattern}$`)
    return regex.test(normalizedPathname)
  })

  return isBlocked
}

const isRoutePublic = (pathname: string) => {
  const matched = matchRoutes(pathname, BlockedRoutes)
  const isPublic = !matched

  return isPublic

}

export const PermissionUtils = {

  isRouteBlocked,

  isRoutePublic,

}
