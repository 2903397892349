import { includePresets } from '@codeleap/common'
import { assignTextStyle, variantProvider } from '..'

export type PlayerComposition =
  | 'wrapper'
  | 'player'
  | 'overlay'
  | 'controlsWrapper'
  | 'header'
  | 'playPause'
  | 'timeline'
  | 'durationsWrapper'
  | 'duration'
  | 'controlsFooter'
  | 'volumeWrapper'
  | 'playPauseWrapper'
  | 'overlay:hover'
  | 'controls:hover'

const createPlayerStyle =
  variantProvider.createVariantFactory<PlayerComposition>()

const presets = includePresets((styles) => createPlayerStyle(() => ({ wrapper: styles })),
)

const OVERLAY_ANIMATION = -200
const PLAYER_MIN_HEIGHT = 400

export const PlayerStyles = {
  ...presets,
  default: createPlayerStyle((theme) => ({
    wrapper: {
      ...theme.presets.relative,
      ...theme.presets.full,
      minHeight: PLAYER_MIN_HEIGHT,
      height: '100svh',
      backgroundColor: theme.colors.neutral10,
    },
    'overlay:visible': { opacity: 1 },
    'overlay:hidden': { opacity: 0 },
    'controls:visible': { bottom: 0 },
    'controls:hidden': { bottom: OVERLAY_ANIMATION },
    player: {
      ...theme.presets.absolute,
      top: 0,
      left: 0,
      overflow: 'hidden',
      maxHeight: '100vh',
    },
    playPauseWrapper: {
      ...theme.presets.flex,
    },
    overlay: {
      zIndex: 2,
      ...theme.presets.absolute,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      ...theme.presets.justifyCenter,
      maxHeight: '100vh',
      overflow: 'hidden',
      top: 0,
      left: 0,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 0.01%, rgba(0, 0, 0, 0.90) 100%, rgba(0, 0, 0, 0.70) 100%)',
      transition: 'opacity 0.3s ease-in-out',
    },
    header: {
      ...theme.presets.fullWidth,
      ...theme.presets.justifySpaceBetween,
    },
    playPause: {
      ...theme.presets.absolute,
      ...theme.presets.alignSelfCenter,
    },

    controlsWrapper: {
      ...theme.presets.absolute,
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      transition: 'bottom 0.1s ease-in-out',
      padding: theme.spacing.value(2),
    },
    timeline: {
      ...theme.presets.fullWidth,
    },
    durationsWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.justifySpaceBetween,
      marginTop: theme.spacing.value(0.5),
    },
    volumeWrapper: {
      flex: 1,
      ...theme.presets.alignCenter,
    },
    duration: {
      ...assignTextStyle('p3'),
      color: theme.colors.neutral2,
    },
    controlsFooter: {
      marginTop: theme.spacing.value(2),
      ...theme.presets.fullWidth,
      ...theme.presets.justifySpaceBetween,
    },
  })),
}
