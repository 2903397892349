import React, { useState } from 'react'
import { APIClient } from '@/services'
import { useI18N } from '@codeleap/common'
import { FilterModal } from './FilterModal'
import { SortStoriesBy } from '@/components/Stories'

type FilterProps = {
  section: 'lessons' | 'stories'
  stories: APIClient.Stories.UseStoriesReturn
  modules: APIClient.Learn.UseModulesReturn
  sources: APIClient.Learn.UseSourcesReturn
}

export function FilterLearn(props: FilterProps) {
  const { section, modules, stories, sources } = props

  const isLessonsSection = section === 'lessons'

  const { t } = useI18N()

  const [sorting, setSorting] = useState<APIClient.Stories.StoriesSortBy>(APIClient.Stories.SORT_BY.created_datetime)

  const departmentOptions = APIClient.Session.useProfileOptions()?.data?.department

  const setStoryCategories = (value) => {
    stories.setCategories(value.map(i => ({
      value: i.id,
      label: i.tag,
    })))
  }

  const setSourceOptions = (value) => {
    sources.setSourcesOptions(value.map(i => ({
      id: i.id,
      label: i.tag,
    })))
  }

  const departments = departmentOptions?.map(i => ({
    id: i.value,
    tag: i.label,
  }))

  const selectedCategories = stories.categories.map(i => ({
    id: i.value,
    tag: i.label,
    created_datetime: null,
  }))

  const selectedSources = sources?.sourcesOptions?.map(i => ({
    id: i.id,
    tag: i.name,
    link: i.link,
  }))

  const sourceOptions = sources?.sources?.items?.map(i => ({
    id: i.id,
    tag: i.name,
  }))

  const data = [
    {
      title: t('learn.stories.departmentFilter'),
      key: 'tags',
      state: modules?.tags,
      setter: modules?.setTags,
      data: departments ?? [],
      enabled: isLessonsSection,
    },
    {
      title: t('learn.stories.category'),
      key: 'categories',
      state: selectedCategories,
      setter: setStoryCategories,
      data: stories?.storyCategories ?? [],
      enabled: !isLessonsSection,
    },
    {
      title: t('learn.stories.departmentFilter'),
      key: 'departments',
      state: stories.authorDepartment ?? [],
      setter: stories.setAuthorDepartment,
      data: departments,
      enabled: !isLessonsSection,
    },
    {
      title: t('learn.stories.source'),
      key: 'sources',
      state: selectedSources,
      setter: setSourceOptions,
      data: sourceOptions,
      enabled: isLessonsSection,
    },
  ]

  const sections = React.useMemo(() => {
    return data?.filter(section => !!section?.enabled)
  }, [data])

  return (
    <FilterModal
      sortBy={!isLessonsSection && <SortStoriesBy setSortBy={setSorting} sortBy={sorting} />}
      sorting={sorting}
      isLessonsSection={isLessonsSection}
      sections={sections}
      clearDependence={section}
      onApply={() => {
        stories.setSort(sorting)
      }}
      onReset={() => {
        setSorting(stories.sort)
      }}
    />
  )
}
