import React from 'react'
import { variantProvider } from '@/app'
import { View } from '@/components'

const gradientComposition = [
  'rgba(0, 0, 0, 0.9)',
  'rgba(0, 0, 0, 0.5)',
  'rgba(0, 0, 0, 0.2)',
  'rgba(0, 0, 0, 0)',
]

const PageGradientTopCP = () => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.inner} />
    </View>
  )
}

export const PageGradientTop = React.memo(PageGradientTopCP)

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    background: `linear-gradient(to bottom, ${gradientComposition.join(', ')})`,
    pointerEvents: 'none',
  },
  inner: {
    width: theme.values.width,
    height: '15vh',
    pointerEvents: 'none',
  },
}), true)
