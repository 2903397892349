import React, { useCallback, useMemo } from 'react'
import { Theme, variantProvider } from '@/app'
import { Button, Icon, Modal, LearnOption, Text, Touchable, ActivityIndicator, View, ActionIcon } from '@/components'
import { LessonCategory } from '@/types'
import { onUpdate, useI18N, usePrevious, useState } from '@codeleap/common'
import { Sections } from './Sections'
import { useIsMobile, useModal } from '@/utils'
import { useBreakpointMatch } from '@codeleap/web'
import { APIClient, analytics } from '@/services'

type FilterModalProps = {
  sortBy?: React.ReactNode
  sorting?: APIClient.Stories.StoriesSortBy
  isLessonsSection?: boolean
  sections: {
    title: string
    key: string
    state: Array<any>
    setter: (value: any) => void
    data: Array<any>
  }[]
  clearDependence?: any
  onApply?: () => void
  onClear?: () => void
  onReset?: () => void
}

type FiltersState = Record<string, Array<any>>

export const FilterModal = (props: FilterModalProps) => {
  const { sortBy = null, sorting, isLessonsSection, sections, clearDependence = null, onApply, onClear, onReset } = props

  const { t } = useI18N()

  const getSectionFilters = () => {
    let _filters = {}

    sections?.forEach(section => {
      _filters = {
        ..._filters,
        [section?.key]: section?.state,
      }
    })

    return _filters
  }

  const appliedFilters = React.useMemo(() => {
    return getSectionFilters()
  }, [sections])

  const setSectionFilters = React.useCallback((values: FiltersState) => {
    sections?.forEach(section => {
      section?.setter(values?.[section?.key] ?? [])
    })
  }, [sections])

  const { visible, toggle } = useModal()
  const prevSorting = usePrevious(sorting)
  const [filters, setFilters] = useState<FiltersState>(() => {
    return getSectionFilters()
  })

  const isMobile = useIsMobile()

  const { badge, selectedIds } = React.useMemo(() => {
    let count = 0
    let _tags = []

    Object.values(filters)?.forEach(_filters => {
      count = count + _filters?.length
      _tags = [..._tags, ..._filters]
    })

    return {
      badge: count >= 1 ? count : null,
      selectedIds: _tags?.map(i => i?.id),
    }
  }, [appliedFilters])

  onUpdate(() => {
    setSectionFilters({})
    setFilters({})
  }, [clearDependence])

  const onCloseFilters = () => {
    const _filters = getSectionFilters()
    setFilters(_filters)
    onReset?.()
  }

  const handleFilter = React.useCallback((_category: LessonCategory, apply = false, key: string) => {
    const sectionFilters = filters?.[key] ?? []
    const idx = sectionFilters?.findIndex(filter => filter?.id === _category?.id)
    const isSelected = idx >= 0

    if (isSelected) {
      const newFilters = sectionFilters.filter((_, i) => i !== idx)
      const newState = {
        ...filters,
        [key]: newFilters,
      }

      setFilters(newState)
      if (apply) setSectionFilters(newState)
    } else {
      const newFilters = [...sectionFilters, _category]
      const newState = {
        ...filters,
        [key]: newFilters,
      }

      setFilters(newState)
      if (apply) setSectionFilters(newState)
    }
  }, [filters])

  const handleApplyFilters = () => {
    if (sorting && !isLessonsSection) {
      analytics.track(`story_filter_${sorting === 'likes' ? 'likes' : 'date'}`)
    }
    if (filters?.tags) {
      analytics.track('lesson_filter', { filters: filters.tags.map(filter => filter?.id) })
    }
    setSectionFilters(filters)
    toggle()
    onApply?.()
  }

  const handleClearFilters = () => {
    setSectionFilters({})
    setFilters({})
    toggle()
    onClear?.()
  }

  const SectionItem = React.useCallback(({ item, isFirst, sectionKey }) => {
    const isSelected = filters?.[sectionKey]?.filter(_item => JSON.stringify(_item) === JSON.stringify(item))?.length === 1

    return (
      <LearnOption
        spacingTop={!isFirst}
        key={'learn-filter-' + item?.id}
        text={item?.tag || item?.label}
        onPress={() => handleFilter(item, false, sectionKey)}
        selected={!!isSelected}
      />
    )
  }, [filters])

  const TagItem = React.useCallback(({ item, sectionKey }) => {
    const isSelected = selectedIds?.includes(item?.id)

    return (
      <Touchable
        debugName={`learn-tag-row-` + item?.id}
        key={item?.id + '-learn-tag'}
        style={[styles.item, isSelected && styles.itemSelected]}
        onPress={() => handleFilter(item, true, sectionKey)}
        tabIndex={0}
        aria-label={item?.tab || item?.label}
      >
        <Text variants={['p3', 'color:neutral9', 'noBreakline', 'ellipsis']} text={item?.tag || item?.label} />
      </Touchable>
    )
  }, [selectedIds])

  const isLoading = !sections?.[0]?.data?.length

  const maxVisibleTags = useBreakpointMatch({
    'mid': 1,
    'large': 2,
    'xlarge': 3,
  })

  const itemsCount = React.useMemo(() => {
    let count = 0

    sections?.forEach(section => {
      count = count + section?.data?.length
    })

    return count
  }, [sections])

  const PreviewTagItem = ({ item, section, indexBySection }) => {
    if ((indexBySection >= maxVisibleTags) && !isMobile) {
      if (indexBySection > maxVisibleTags) return null

      return (
        <View style={styles.tagIndicator}>
          <Text variants={['p3', 'color:neutral9', 'noBreakline']} text={`+${itemsCount - maxVisibleTags}`} />
        </View>
      )
    }

    return (
      <TagItem item={item} sectionKey={section?.key} />
    )
  }

  return (
    <>
      <Touchable style={[styles.item, badge && styles.itemSelected]} onPress={toggle} debugName='open filter Modules' aria-label={t('learn.components.filterModal.buttonActionText')} tabIndex={0}>
        <Text variants={['p3', 'color:neutral9', 'marginRight:0.5']} text={t('learn.components.filterModal.buttonActionText')} />

        {badge ? (
          <View style={styles.badge}><Text variants={['p5', 'color:neutral1']} text={badge} /></View>
        ) : (
          <Icon debugName='q' name='chevron-down' variants={['neutral5', 'iconSize:2']} />
        )}
      </Touchable>

      {
        !isLoading ? (
          <Sections
            vertical={!isMobile}
            row
            sections={sections as any}
            SectionHeaderComponent={() => null}
            SectionSeparatorComponent={({ indexBySection }) => {
              if ((indexBySection >= maxVisibleTags + 1) && !isMobile) return null

              return <View style={styles.separator} />
            }}
            renderItem={PreviewTagItem}
          />
        ) : (
          <View variants={['marginLeft:1']} style={styles.tagIndicator}>
            <ActivityIndicator debugName='filters indicator' variants={['small']} />
          </View>
        )
      }

      <Modal
        visible={visible}
        toggle={toggle}
        onClose={onCloseFilters}
        debugName={`Modal filter lessons`}
        title={null}
        variants={[isMobile ? 'fullscreen' : 'centered', 'center']}
        showClose={false}
        scroll={false}
        styles={{
          'body': styles.body,
          'box': styles.box,
        }}
      >
        <View variants={['fullWidth', 'row', 'center', 'justifySpaceBetween', 'marginBottom:3']}>
          <Text variants={['h1']} text={t('learn.components.filterModal.title')} />

          <ActionIcon
            name='x'
            onPress={() => {
              onCloseFilters()
              toggle()
            }}
            debugName='close modal'
            variants={['minimal', 'iconSize:3', 'neutral5']}
          />
        </View>

        <View style={styles.scroll}>
          {sortBy}

          <Sections
            vertical
            sections={sections as any}
            SectionHeaderComponent={({ section, isFirst }) => (
              <Text variants={['p2', 'color:neutral5', 'marginBottom:1', (!isFirst || !!sortBy) && 'marginTop:4']} text={section?.title} />
            )}
            SectionSeparatorComponent={() => <View variants={['marginTop:0.5']} />}
            renderItem={({ item, section, index, isFirst }) => (
              <SectionItem
                isFirst={isFirst}
                item={item}
                key={section?.key + index}
                sectionKey={section?.key}
              />
            )}
          />
        </View>

        <View
          variants={['row', 'justifySpaceBetween', 'alignEnd', 'paddingVertical:4', 'gap:2', 'fullWidth', 'flex']}
          responsiveVariants={{ small: ['paddingVertical:2'] }}
        >
          <Button
            variants={['flex', 'outline', 'border-radius:rounded']}
            text={t('learn.components.filterModal.buttonClear')}
            debugName={`Modal filter clear`}
            onPress={handleClearFilters}
          />

          <Button
            variants={['flex', 'border-radius:rounded']}
            text={t('learn.components.filterModal.buttonSubmit')}
            debugName={`Modal filter apply`}
            onPress={handleApplyFilters}
          />
        </View>
      </Modal>
    </>
  )
}

const ITEM_HEIGHT = 32
const MODAL_WIDTH = 400 + Theme.spacing.value(4)

const styles = variantProvider.createComponentStyle(theme => ({
  item: {
    ...theme.presets.row,
    ...theme.presets.center,
    ...theme.presets.justifySpaceBetween,
    ...theme.spacing.padding(1),
    borderRadius: theme.borderRadius.tiny,
    backgroundColor: theme.colors.neutral2,
    height: ITEM_HEIGHT,
    maxWidth: ITEM_HEIGHT * 7,
  },
  itemSelected: {
    backgroundColor: theme.colors.primary1,
  },
  tagIndicator: {
    backgroundColor: theme.colors.neutral2,
    height: ITEM_HEIGHT,
    width: ITEM_HEIGHT,
    borderRadius: theme.borderRadius.tiny,
    ...theme.presets.center,
  },
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  body: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    padding: theme.spacing.value(4),
    paddingBottom: theme.spacing.value(0),

    [theme.media.up('small')]: {
      width: '100vw',
      maxWidth: MODAL_WIDTH,
      minHeight: '85svh',
      maxHeight: '85svh',
    },

    [theme.media.down('small')]: {
      height: '100svh',
      maxHeight: '100svh',
      flex: 1,
      padding: theme.spacing.value(2),
      paddingBottom: theme.spacing.value(0),
    },
  },
  box: {
    padding: theme.spacing.value(0),
  },
  badge: {
    backgroundColor: theme.colors.positive2,
    borderRadius: theme.borderRadius.rounded,
    ...theme.presets.center,
    width: theme.values.iconSize[1],
    height: theme.values.iconSize[1],
  },
  separator: {
    width: theme.spacing.value(1),
  },
}), true)
