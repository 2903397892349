import { LocalStorageKeys, Settings, api } from '@/app'
import { Question, QuestionSample } from '@/types'
import { ReactQuery, TypeGuards, onUpdate } from '@codeleap/common'
import { checkSignupCompletion, useAuthState, useSession } from './session'
import { queryClient } from './queryClient'
import { AppStatus, Pulse, PulseFrom, useAppSelector } from '@/redux'
import { useLocation } from '@reach/router'
import { useSearchParams } from '@codeleap/web'
import { navigate } from 'gatsby'
import { organisationsManager } from './organisations'
import { LocalStorage } from '@/utils'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { verifyAchievementProgress } from '../../components/Achievements/Store'

async function sampleQuestions(questionIds: Question['id'][] = null) {

  const response = await api.get<QuestionSample>('questions/pulse/', {
    params: {
      questions: questionIds?.length ? questionIds : undefined,
    },
  })

  return response.data
}

export type AnswerInfo = {
  question: Question['id']
  value: number
  skip?: boolean
  comment?: string
}

async function answerQuestion(data: AnswerInfo) {
  const response = await api.post<QuestionSample>('questions/answers/', {
    ...data,
    comment: data.comment?.trim() ?? '',
  })

  return response.data

}

async function answerManyQuestions(data: AnswerInfo[]) {
  try {
    const response = await api.post('questions/answers/answer_many/', data)

    verifyAchievementProgress(response?.data?.achievement_progress)

    return response.data
  } catch (e) {
    logger.error(e)
    return null
  }
}

const queryKey = ['pulse-questions']

export const prefetch = (ids: Question['id'][] = null) => {
  return queryClient.client.prefetchQuery([...queryKey, ids], () => sampleQuestions(ids))
}
export type AnswerData = Pick<AnswerInfo, 'comment' | 'value' | 'skip'>

const shouldAutoOpenQuestions = (auto_open:boolean) => {
  if (!auto_open) return false

  const lastAutoOpened = LocalStorage.getItem?.(LocalStorageKeys.QUESTIONS_LAST_AUTO_OPENENED)

  if (TypeGuards.isNil(lastAutoOpened)) return true

  const lastAutoOpenedDate = new Date(lastAutoOpened)

  const shouldOpen = Settings.GATSBY_DEV_ENV ? differenceInMinutes(new Date(), lastAutoOpenedDate) >= 5 : differenceInHours(new Date(), lastAutoOpenedDate) >= 2

  return shouldOpen
}

// The ids param is what comes from the notification
export const usePulseQuestions = () => {
  const session = useSession()

  const { answers, from, modalOpen } = useAppSelector(state => state.Pulse)
  const setAnswers = Pulse.setAnswers

  const page = useAppSelector(state => state.Pulse.page)

  const setPage = Pulse.goTo

  const query = ReactQuery.useQuery({
    queryFn: () => sampleQuestions(),
    queryKey: [...queryKey],
    enabled: session.isLoggedIn,
  })

  const answerMutation = ReactQuery.useMutation({
    mutationFn: answerManyQuestions,
  })

  const questions = query.data?.questions ?? []

  const defaultValue = !!questions?.[page]?.choices?.length ? null : questions?.[page]?.default_value

  const answer = answers[page] ?? {
    value: defaultValue,
    comment: null,
  }

  const clearAnswers = () => {
    setAnswers([])
    Pulse.goTo(0)
  }

  const setAnswer = (answer: Partial<AnswerData>, at?: number) => {
    const atPage = at ?? page
    const newAnswers = [...answers]

    if (!newAnswers[atPage]) {
      const _value = !!questions?.[atPage]?.choices?.length ? null : questions?.[atPage]?.default_value

      newAnswers[atPage] = {
        value: _value,
        comment: null,
      }
    }

    newAnswers[atPage] = { ...newAnswers[atPage], ...answer, skip: answer?.skip ?? false }

    setAnswers(newAnswers)

    return newAnswers
  }

  const moreAvailable = query.data?.more_available === true || query.data?.questions?.length >= 2

  const isLast = page === questions.length - 1
  const isFirst = page === 0

  const currentQuestion = questions?.[page]

  const onAnswer = async (withAnswers = answers) => {
    if (isLast) {
      const answers = withAnswers.map((answer, idx) => ({
        question: questions?.[idx].id,
        ...answer,
      }))

      AppStatus.set('loading')

      await answerMutation.mutateAsync(answers)

      AppStatus.set('loading')

      Pulse.clearInitialQuestions()

      setTimeout(() => {
        AppStatus.set('done')

        setTimeout(() => {

          Pulse.toggleSuccess(true)

          setTimeout(() => {
            Pulse.goTo(0)
            clearAnswers()
            organisationsManager.refreshItem(session.profile?.organization?.id)
          }, 200)
        }, 2000)
      }, 2500)

      await query.refetch()
    } else {
      Pulse.next()
    }
  }

  const skip = () => {
    const newAnswers = setAnswer({ skip: true, value: null, comment: null })

    onAnswer(newAnswers)
  }

  const onAnswerMore = async (loading = false) => {
    clearAnswers()

    if (!moreAvailable) return

    AppStatus.set('loading')

    setPage(0)

    await query.refetch()

    setTimeout(() => {
      if (loading) {
        AppStatus.set('done')
      } else {
        AppStatus.set('idle')
      }
    }, 3000)
  }

  const onBack = () => {
    if (page > 0) {
      Pulse.previous()
    }
  }

  const available = query.isFetched && !!questions.length && session?.isLoggedIn

  const openQuestions = (from: PulseFrom) => {
    clearAnswers()
    Pulse.toggleModal(from)
  }

  onUpdate(() => {
    if (!query.data?.auto_open) return
    if (
      !session?.profile ||
      !session?.profile?.organization ||
      session?.profile?.organization?.is_unknown
    ) return

    if (!shouldAutoOpenQuestions(query?.data?.auto_open)) return

    openQuestions('start_page')

    LocalStorage.setItem?.(LocalStorageKeys.QUESTIONS_LAST_AUTO_OPENENED, new Date().toISOString())
  }, [query.data?.auto_open, session?.profile?.organization?.is_unknown])

  return {
    questions,
    currentQuestion,
    moreAvailable,
    available,
    onAnswer,
    skip,
    onAnswerMore,
    onBack,
    modalOpen,
    isLast,
    page,
    isFirst,
    setPage,
    openQuestions,
    answer,
    setAnswer,
    clearAnswers,
    answers,
    refetch: query.refetch,
    from,
  }
}

const extractQuestionIds = (param: string) => param?.split(',').filter((x) => !!x.trim()).map((x) => Number(x))

export const usePulseQuestionsAutoOpen = () => {
  const [params, setParams] = useSearchParams({
    openQuestions: undefined,
    questions: undefined,
  })

  const {
    awaitAuth,
  } = useAuthState()

  const handleOpen = async () => {

    try {
      const { state, profile } = await awaitAuth()

      setParams({
        openQuestions: undefined,
        questions: undefined,
      })

      if (state === 'logged_in') {
        const { isComplete } = await checkSignupCompletion(profile)

        if (!isComplete) {
          AppStatus.setModal(['profileRedirect', true])
          return
        }

        if (profile?.organization?.is_unknown) {
          navigate('/profile/edit/')
          AppStatus.setModal(['unknownOrganisation', true])
          return
        }

        Pulse.toggleModal('start_page')
        return
      }

      const nextUrl = window.location.pathname + encodeURIComponent(window.location.search)

      navigate('/auth/login/' + `?next=${nextUrl}`)

    } catch (e) {
      logger.error('Error with pulse questions auto open', e)
    }
  }

  const { search } = useLocation()

  onUpdate(() => {
    const shouldOpen = params?.openQuestions === 'true'
    const locationParams = new URLSearchParams(search)

    const shouldOpenFromUrl = locationParams.get('openQuestions') === 'true' && !params?.openQuestions

    if (shouldOpen || shouldOpenFromUrl) {
      handleOpen().finally(() => {
        setParams({
          openQuestions: undefined,
          questions: undefined,
        })

      })
    }
  }, [
    params?.openQuestions,
    search,
  ])

  onUpdate(() => {
    const shouldOpen = !!extractQuestionIds(params?.questions)?.length
    const locationParams = new URLSearchParams(search)

    const shouldOpenFromUrl = !!extractQuestionIds(locationParams.get('questions'))?.length && !params?.questions

    if (shouldOpen || shouldOpenFromUrl) {
      handleOpen().finally(() => {
        setParams({
          openQuestions: undefined,
          questions: undefined,
        })

      })
    }
  }, [params?.questions, search])
}
