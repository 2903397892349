import { AppImages } from '@/app'
import { useI18N } from '@codeleap/common'

export const useOnboardingPages = () => {
  const { t } = useI18N()

  return [
    {
      title: t('onboarding.page2.title'),
      text: t('onboarding.page2.text'),
      button: t('onboarding.page2.button'),
      image: AppImages.Onboarding2,
    },
    {
      title: t('onboarding.page3.title'),
      text: t('onboarding.page3.text'),
      button: t('onboarding.page3.button'),
      image: AppImages.Onboarding3,
    },
    {
      title: t('onboarding.page1.title'),
      text: t('onboarding.page1.text'),
      button: t('onboarding.page1.button'),
      image: AppImages.Onboarding,
    },
  ]
}
