import { Text } from '@/components'

function pad(string) {
  return ('0' + string).slice(-2)
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

export function Duration({ seconds, ...rest }) {
  return <Text {...rest} text={format(seconds)} />
}
