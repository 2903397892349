import { useAppI18N, variantProvider } from '@/app'
import { Button, Modal, Text, View } from '@/components'
import { Profile } from '@/types'
import { AnyFunction } from '@codeleap/common'
import { ModalProps } from '@codeleap/web'

type ScreenNameExplainerModalProps = Partial<ModalProps> & {
  visible: boolean
  toggle: AnyFunction
  screenName: Profile['display_name']
}

export const ScreenNameExplainerModal = (props: ScreenNameExplainerModalProps) => {
  const { toggle, visible, screenName, ...modalProps } = props
  const { t } = useAppI18N()

  return (
    <Modal
      visible={visible}
      showClose={false}
      toggle={toggle}
      style={styles.modalWrapper}
      debugName={'ScreenName Explainer Modal'}
      {...modalProps}
      variants={['centered']}
    >
      <View variants={['gap:2', 'column']}>
        <Text variants={['h4', 'color:neutral9', 'textCenter']} text={screenName} />
        <Text
          variants={['center', 'textCenter']}
          text={t('modals.screenName.description')}
        />
        <Button text={t('modals.screenName.confirmButton')} onPress={toggle} debugName={'Dismiss Modal'} />
      </View>
    </Modal>
  )
}

const MAX_WIDTH = 358

export const styles = variantProvider.createComponentStyle((theme) => ({
  modalWrapper: {
    maxWidth: MAX_WIDTH,
  },
}), true)
