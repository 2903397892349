import { getYouTubeId } from '@/utils'
import { TypeGuards, createForm, yup } from '@codeleap/common'
import { I18N } from '../i18n'

export * from '../testers'

export const urlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

export const createStory = () => createForm('story', {
  headline: {
    type: 'text',
    placeholder: I18N.t('form.story.headline.placeholder'),
    label: I18N.t('form.story.headline.label'),
    validate: yup
      .string()
      .required(I18N.t('form.story.validations.required'))
      .min(6, I18N.t('form.story.validations.min'))
      .max(50, I18N.t('form.story.validations.headlineMax'))
      .verifyProfanity(),
  },
  url: {
    type: 'text',
    placeholder: 'www.example.com',
    label: I18N.t('form.story.url.label'),
    validate: (v) => {
      if (!TypeGuards.isString(v) || v?.trim?.()?.length < 1) return { valid: true, message: '' }
      return {
        valid: urlRegex.test(v),
        message: I18N.t('form.story.validations.url'),
      }
    },
    // yup.string()
    //   .optional(),
    // .matches(
    //   urlValidator,
    //   I18N.t('form.story.validations.url'),
    // ),
    // validate: yup.string().url(
    //   I18N.t('form.story.validations.url'),
    // ),
  },
  categories: {
    label: I18N.t('form.story.categories.label'),
    type: 'list',
    placeholder: I18N.t('form.story.categories.placeholder'),

  },
  overview: {
    label: I18N.t('form.story.overview.label'),
    type: 'text',
    placeholder: I18N.t('form.story.overview.placeholder'),
    validate: yup
      .string()
      .required('This is a required field')
      .min(6, I18N.t('form.story.validations.min'))
      .max(256, I18N.t('form.story.validations.max'))
      .verifyProfanity(),
    multiline: true,
  },
  positive_outcomes: {
    label: I18N.t('form.story.positiveOutcomes.label'),
    type: 'text',
    placeholder: I18N.t('form.story.positiveOutcomes.placeholder'),
    multiline: true,
    validate: yup
      .string()
      .max(256, I18N.t('form.story.validations.max'))
      .verifyProfanity(),
  },
  learned: {
    label: I18N.t('form.story.learned.label'),
    type: 'text',
    placeholder: I18N.t('form.story.learned.placeholder'),
    multiline: true,
    validate: yup
      .string()
      .max(256, I18N.t('form.story.validations.max'))
      .verifyProfanity(),
  },
  advice: {
    label: I18N.t('form.story.advice.label'),
    type: 'text',
    placeholder: I18N.t('form.story.advice.placeholder'),
    multiline: true,
    validate: yup
      .string()
      .max(256, I18N.t('form.story.validations.max'))
      .verifyProfanity(),
  },
  file: {
    type: 'file',
  },
  video: {
    type: 'text',
    placeholder: I18N.t('form.story.video.placeholder'),
    label: I18N.t('form.story.video.label'),
    validate: (v) => {
      if (!TypeGuards.isString(v) || v?.trim?.()?.length < 1) return { valid: true, message: '' }
      return {
        valid: !!getYouTubeId(v),
        message: I18N.t('form.story.validations.url'),
      }
    },
  },
})
