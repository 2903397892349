import type { AnswerData } from '@/services/api/pulse'
import { Question } from '@/types'
import { createSlice, TypeGuards } from '@codeleap/common'

export type PulseFrom = 'org_page' | 'act_page' | 'start_page' | 'onboarding_page'

export type PulseState = {
  page: number
  answers: AnswerData[]
  answeredQuestions: Question['id'][]
  modalOpen: boolean
  successOpen: boolean
  from: PulseFrom
  initialQuestions?: Question['id'][]
}

const initialState: PulseState = {
  page: 0,
  answers: [],
  answeredQuestions: [],
  modalOpen: false,
  successOpen: false,
  from: null,
  initialQuestions: undefined,
}

export const pulseSlice = createSlice({
  name: 'Pulse',
  initialState,
  reducers: {
    clear: () => initialState,

    next: ({ page }) => ({ page: page + 1 }),
    previous: ({ page }) => ({ page: page - 1 }),
    goTo: (_, page: number) => ({ page }),
    setAnswers: (_, answers: AnswerData[]) => ({ answers }),
    setAnsweredQuestions: (_, answeredQuestions: Question['id'][]) => ({ answeredQuestions }),
    setInitialQuestions: (_, initialQuestions: Question['id'][]) => ({ initialQuestions }),
    toggleModal: ({ modalOpen }, from: PulseState['from'] = null, to?: boolean) => {
      const visible = TypeGuards.isBoolean(to) ? to : !modalOpen
      return { modalOpen: visible, from }
    },
    toggleSuccess: ({ successOpen }, to?: boolean) => ({ successOpen: TypeGuards.isBoolean(to) ? to : !successOpen }),
    clearInitialQuestions: () => ({ initialQuestions: undefined }),
  },
  asyncReducers: {},
})
