import { View, Text, Image } from '@/components'
import { AppImages, variantProvider } from '@/app'
import { useI18N } from '@codeleap/common'

export const OrganisationNotFound = () => {
  const { t } = useI18N()

  return (
    <View variants={['column', 'gap:4', 'alignCenter', 'justifyCenter']}>
      <Image style={{ ...styles.image }} source={AppImages.NotFound} objectFit='contain' />
      <View style={{ ...styles.textWrapper }} variants={['column', 'gap:1', 'textCenter', 'alignCenter', 'justifyCenter']}>
        <Text variants={['h4', 'color:neutral9']}>{t('organisations.organisationNotFound.title')}</Text>
        <Text variants={['p1', 'color:neutral6']}>{t('organisations.organisationNotFound.description')}</Text>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  image: {
    width: '140px',
    height: '120px',
  },
  textWrapper: {
    maxWidth: '345px',
  },
}), true)
