import { IS_SSR } from '@/app'
import { TypeGuards } from '@codeleap/common'

const getLocalStorage = (): Storage => {
  if (IS_SSR) {
    return {} as Storage
  }

  return localStorage
}

const parseValue = (value: any) => {
  if (TypeGuards.isObject(value)) {
    return JSON.stringify(value)
  } else {
    return String(value)
  }
}

const replaceItem = (key: string, value: any) => {
  const storage = getLocalStorage()

  storage.removeItem(key)

  const parsedValue = parseValue(value)

  storage.setItem(key, parsedValue)

  return parsedValue
}

const setItem = (key: string, value: any) => {

  const parsedValue = parseValue(value)

  getLocalStorage().setItem?.(key, parsedValue)

  return parsedValue
}

const getItem = (key: string): string | null => {
  const storage = getLocalStorage()

  if(!storage.getItem) return null

  return storage.getItem(key)
}

const removeItem = (key: string) => {
  const storage = getLocalStorage()

  storage.removeItem(key)
}

export const LocalStorage = {
  replaceItem,
  getItem,
  setItem,
  removeItem,
  getLocalStorage,
}
