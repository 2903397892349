import { APIClient } from '@/services'
import { useSession } from './useSession'
import { Profile } from './views'
import { analytics } from '@/services/analytics'

export function useDeleteAccount() {
  const { logout } = useSession()

  const onDeleteAccount = async (id: Profile['id']) => {
    logout(true, true)
    APIClient.Session.deleteProfile(id)
    analytics?.track?.('delete_account')
  }

  return {
    onDeleteAccount,
  }
}
