import { AppImages, variantProvider } from '@/app'
import { View, Text, Image } from '@/components'
import { useI18N } from '@codeleap/common'

export const ScoreNotFound = () => {
  const { t } = useI18N()

  return (
    <View variants={['column', 'gap:2', 'justifyCenter', 'alignCenter']} style={styles.wrapper}>
      <Image source={AppImages.PlaceholderReviews} style={styles.image} />
      <View variants={['column', 'gap:1', 'alignCenter', 'justifyCenter', 'textCenter']}>
        <Text variants={['h4', 'color:neutral9']}>{t('organisations.noReviewsYet.title')}</Text>
        <Text variants={['p1', 'color:neutral6']}>{t('organisations.noReviewsYet.description')}.</Text>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: '460px',
    width: '100%',
  },
  image: {
    width: '180px',
    height: '120px',
  },
}), true)
