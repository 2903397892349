import { getFirebase } from '@/services'
import { FirebaseInstance, cachedFirebaseInstance } from '@/services/firebaseApp'
import { UnwrapPromise, useIsomorphicEffect } from '@codeleap/common'
import { useState } from 'react'

type FBInstance = UnwrapPromise<ReturnType<typeof getFirebase>>

export const useFirebase = () => {
  const [_firebase, _setFirebase] = useState<FBInstance>(cachedFirebaseInstance)

  useIsomorphicEffect(() => {

    if (!_firebase) {
      getFirebase().then(_setFirebase)
    }
  }, [])

  return _firebase
}
