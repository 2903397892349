import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

const IMAGE_HEIGHT = 200

export const AboutYouModal = () => {
  return (
    <AuthModal 
      name='aboutYou' 
      image={AppImages.AboutYou}
      centered={false}
      imageHeight={IMAGE_HEIGHT}
    >
      <AuthForms.AboutYouForm />
    </AuthModal>
  )
}
