import { Duration } from './components'
import { ViewProps } from '@codeleap/web'
import { useHover } from '@/utils'
import { useControls } from './hooks/useControls'
import type _ReactPlayer from 'react-player/lazy'
import type { ReactPlayerProps } from 'react-player/lazy'
import {
  AnyFunction,
  ComponentVariants,
  onUpdate,
  StylesOf,
  useDefaultComponentStyle,
  useRef,
} from '@codeleap/common'
import { PlayerComposition, PlayerStyles } from '@/app/stylesheets/Player'
import { ActionIcon, Touchable, View, Slider, Icon } from '@/components'
import { forwardRef, useMemo, MutableRefObject, useImperativeHandle } from 'react'
import { useReactPlayer } from './hooks/useReactPlayer'
import { IS_SSR } from '@/app'

export type PlayerProps = {
  styles?: StylesOf<PlayerComposition>
} & ViewProps<'div'> &
  ComponentVariants<typeof PlayerStyles> &
  ReactPlayerProps & {
    fullscreen: boolean
    paused: boolean
    onFullscreenChange: (fullscreenState: boolean) => void
    toggleFullscreen: AnyFunction
  }

const HIDE_CONTROLS_TIMEOUT = 2000

const _Player = (props: PlayerProps, ref: MutableRefObject<_ReactPlayer>) => {
  const { style, variants, styles, responsiveVariants, fullscreen, onFullscreenChange, paused, toggleFullscreen, ...rest } = props

  const variantStyles = useDefaultComponentStyle<
    'u:Player',
    typeof PlayerStyles
  >('u:Player', {
    variants,
    styles,
    responsiveVariants,
  })

  const playerRef = useRef<_ReactPlayer>(null)
  const fullscreenElement = useRef(null)
  const { isHovered, setIsHovered } = useHover(HIDE_CONTROLS_TIMEOUT)
  const { isFullscreen, controls, actions } = useControls({
    initialControls: rest,
    playerRef,
    fullscreenElement,
    onFullscreenChange,
  })

  useImperativeHandle(ref, () => playerRef.current)

  const ReactPlayer = useReactPlayer()

  const canEnablePIP = useMemo(() => {
    if (IS_SSR) return false

    if (!ReactPlayer?.canEnablePIP) return false

    return ReactPlayer.canEnablePIP(rest.url as string)
  }, [])

  return (
    <View
      ref={fullscreenElement}
      css={[variantStyles.wrapper]}
      onMouseMove={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={style}
    >
      <View
        css={[
          variantStyles.overlay,
          variantStyles[`overlay:${isHovered ? 'visible' : 'hidden'}`],
        ]}
      >
        <Touchable
          onPress={actions.handlePlayPause}
          debugName='Player:PlayPause'
          css={variantStyles.playPauseWrapper}
        >
          <Icon
            debugName='Player:Button'
            css={variantStyles.playPause}
            variants={['white', 'huge']}
            name={controls.playing ? 'pause-circle-fill' : 'play-circle-fill'}
          />
        </Touchable>

        <View
          css={[
            variantStyles.controlsWrapper,
            variantStyles[`controls:${isHovered ? 'visible' : 'hidden'}`],
          ]}
        >
          <Slider
            debugName='Player:Timeline'
            min={0}
            max={0.999999}
            step={0.00001}
            value={[controls.played]}
            onValueChange={actions.handleSeekChange}
            onPointerDown={actions.handleSeekMouseDown}
            onPointerUp={actions.handleSeekMouseUp}
            css={variantStyles.timeline}
            variants={['player']}
          />

          <View css={variantStyles.durationsWrapper}>
            <Duration
              seconds={controls.duration * controls.played}
              css={variantStyles.duration}
            />
            <Duration
              seconds={controls.duration * (1 - controls.played)}
              css={variantStyles.duration}
            />
          </View>

          <View css={variantStyles.controlsFooter}>
            <View {...rest} css={variantStyles.volumeWrapper}>
              <ActionIcon
                debugName='Player:Volume'
                variants={['player', 'marginRight:1']}
                name={'volume-2'}
              />
              <Slider
                debugName='Player:Volume'
                min={0}
                max={0.999999}
                step={0.00001}
                value={[controls.volume]}
                onValueChange={actions.handleVolumeChange}
                variants={['player:volume']}
              />
            </View>

            {canEnablePIP && (
              <ActionIcon
                debugName='Player:PIP'
                variants={['player', 'marginRight:1', 'iconSize:3']}
                name={'pip'}
                onPress={actions.togglePIP}
              />
            )}

            <ActionIcon
              debugName='Player:Expand'
              variants={['player']}
              name={'minimize'}
              onPress={toggleFullscreen}
            />
          </View>
        </View>
      </View>

      <ReactPlayer
        {...controls}
        {...rest}
        playing={paused ? false : controls?.playing}
        ref={playerRef}
        css={variantStyles.player}
        width='100%'
        height='100%'
        onPlay={actions.play}
        onEnablePIP={actions.handleEnablePIP}
        onDisablePIP={actions.handleDisablePIP}
        onPause={actions.pause}
        onPlaybackRateChange={actions.handleOnPlaybackRateChange}
        onEnded={actions.handleEnded}
        onProgress={actions.handleProgress}
        onDuration={actions.handleDuration}
      />
    </View>
  )
}

export const Player = forwardRef(_Player)
