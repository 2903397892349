import { createDefaultVariantFactory, includePresets } from '@codeleap/common'
import { ModalComposition } from '@codeleap/web'
import { assignTextStyle } from './Text'

export type AlertParts = 'body' | 'actions' | 'option' | `modal${Capitalize<ModalComposition>}`
export type AlertComposition = AlertParts

const createAlertStyle = createDefaultVariantFactory<AlertComposition>()

export const AlertPresets = includePresets((style) => createAlertStyle(() => ({ body: style })))

const WIDTH = 450

export const AppAlertStyles = {
  ...AlertPresets,
  default: createAlertStyle((theme) => ({
    option: {
      ...theme.presets.fullWidth,
      ...theme.presets.flex,
      borderRadius: theme.borderRadius.medium,
    },
    body: {
      ...assignTextStyle('p1'),
      ...theme.presets.textCenter,
    },
    actions: {
      ...theme.presets.fullWidth,
      ...theme.spacing.marginTop(2),
      gap: theme.spacing.value(2),
    },
    'modalBox': {
      maxWidth: theme.presets.calcWidth(theme.spacing.value(4)),
      width: WIDTH,

      ...theme.spacing.padding(4),

      [theme.media.down('small')]: {
        ...theme.spacing.padding(2),
      }
    },
  })),
  profileAlert: createAlertStyle((theme) => ({
    body: {
      ...assignTextStyle('h5'),
    },
    modalTitle: {
      ...assignTextStyle('h4'),
    },
    'modalBox': {
      maxWidth: theme.presets.calcWidth(theme.spacing.value(4)),
      width: WIDTH,
    },
  })),
}
