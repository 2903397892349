import { IS_SSR, LocalStorageKeys } from '../constants'
import enGB from './en-GB/en-GB.json'
import ptBR from './pt-BR/pt-BR.json'

import { getSimilarLocale, make18n, useI18N } from '@codeleap/common'
import { Paths } from '@codeleap/common/dist/types/pathMapping'
import { dateLocales } from './date'
import { registerLocale } from 'react-datepicker'

export const I18NDictionary = {
  'en-GB': enGB,
  'pt-BR': ptBR,
}
type I18Keys = Paths<typeof I18NDictionary['en-GB'], 5>
const storage = (): Storage => {
  if (IS_SSR) {
    return null
  }

  return localStorage
}

import { format as formatFn } from 'date-fns'

Object.keys(dateLocales).forEach((locale) => {
  registerLocale(locale, dateLocales[locale])
})

export const formatDateLocalized = (date: Date, format: I18Keys) => {
  if (!date || !format || !(date instanceof Date && !isNaN(date.getDate()))) return ''
  const locale = I18N.locale

  const dateFormat = I18N.t(format)

  return formatFn(date, dateFormat, { locale: dateLocales[locale] })
}

export const useAppI18N = useI18N<I18Keys>

export type TranslateFunction = typeof I18N.t

export const I18N = make18n<I18Keys>({
  initialLocale: 'en-GB',
  persistor: {
    getLocale: async () => storage()?.getItem(LocalStorageKeys.LOCALE),
    setLocale: async (locale) => storage()?.setItem(LocalStorageKeys.LOCALE, locale),
  },
  languageDictionary: I18NDictionary,
})

export * from './date'

