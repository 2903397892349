import { createForm, yup } from '@codeleap/common'
import { I18N } from '../i18n'

export const registerOrganisationModal = () => createForm('registerORGModal', {
  name: {
    type: 'text',
    label: I18N.t('form.label.organization'),
    placeholder: I18N.t('form.placeholder.organization'),
    validate: yup.string().max(60),
    required: true,
  },
  website: {
    type: 'text',
    label: I18N.t('form.label.url'),
    placeholder: I18N.t('form.placeholder.url'),
    required: true,
    validate: yup.string().max(60, I18N.t('SearchOrganisations.registerModal.urlMax', { max: 60 })).matches(
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
      I18N.t('SearchOrganisations.registerModal.invalidURL'),
    ),
  },
})
