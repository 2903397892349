import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

const IMAGE_HEIGHT = 126

export const VerifyEmailAfterModal = () => {
  return (
    <AuthModal
      image={AppImages.CheckEmail}
      name='verifyEmailAfter'
      imageHeight={IMAGE_HEIGHT}
      small
      closable={false}
      center
    >
      <AuthForms.VerifyEmailAfterForm />
    </AuthModal>
  )
}
