import { formatDateLocalized, useAppI18N, variantProvider } from '@/app'
import { GradientModal, Tag, Text, View, CardReply, ReplyDisclaimer } from '@/components'
import { ProgressCircle } from '@/components/Progress/Circle'
import React from 'react'

const BREAKPOINT = 'small'

export const ReviewCardModal = (props) => {
  const { t } = useAppI18N()
  const { visible, toggle, review, onShare, ...modalProps } = props

  const isReplied = review?.replies?.length > 0
  const organisationName = review?.replies?.[0]?.organization_name

  const formattedDate = review?.created_datetime ? formatDateLocalized(
    new Date(review?.created_datetime),
    'learn.stories.date_format',
  ) : null

  return (
    <GradientModal
      toggle={() => toggle(!visible)}
      visible={visible}
      debugName={'ReviewCardModal'}
      onSharePress={onShare}
      headerStyle={styles.gradientHeader}
      boxStyle={styles.gradientBox}
      bodyStyle={styles.gradientBody}
      {...modalProps}
    >
      {
        !!review && (
          <>
            <View variants={['column', 'gap:2', 'paddingHorizontal:4', isReplied ? null : 'paddingBottom:4']}>
              <View variants={['alignCenter', 'justifySpaceBetween']}>
                <View>
                  <Tag debugName='Organisations review tag' text={t('organisations.review')} />
                </View>
                <ProgressCircle
                  progress={review.value * 10}
                  image={review.question.sub_category.web_icon || review.question.sub_category.mobile_icon}
                  variants={['tiny', 'noLabel']}
                />
              </View>
              {isReplied && <ReplyDisclaimer organisationName={organisationName} />}
              <Text variants={['h1', 'color:neutral9']} responsiveVariants={{ [BREAKPOINT]: ['h5', 'color:neutral7'] }} >
                {review.question.comment}
              </Text>
              {
                formattedDate ? (
                  <Text
                    variants={['p3', 'color:neutral6']}
                    responsiveVariants={{ [BREAKPOINT]: ['p4', 'color:neutral5'] }}
                  >{formattedDate}</Text>
                ) : null
              }
              <Text variants={['p1', 'color:neutral6']} text={review.comment} />

            </View>

            {isReplied && (
              <CardReply replies={review.replies} />
            )}
          </>
        )
      }
    </GradientModal>
  )
}

const MAX_WIDTH = 700

const styles = variantProvider.createComponentStyle((theme) => ({
  gradientHeader: {
    ...theme.spacing.padding(4),
    ...theme.spacing.paddingBottom(0),
    ...theme.presets.relative,
    ...theme.presets.fullWidth,

    [theme.media.down('small')]: {
      marginBottom: theme.spacing.value(0),
      ...theme.spacing.padding(0),
    },
  },
  gradientBox: {
    ...theme.spacing.padding(0),
    width: '100vw',
    maxWidth: MAX_WIDTH,

    overflow: 'auto',
    maxHeight: `calc(100dvh - ${theme.spacing.value(14)}px)`,

    [theme.media.down('small')]: {
      padding: theme.spacing.value(0),
      paddingTop: theme.spacing.value(1),
      height: '100svh',
      maxHeight: '100svh',
    },
  },
  gradientBody: {
    paddingLeft: theme.spacing.value(0),
    paddingRight: theme.spacing.value(0),
    [theme.media.down('small')]: {
      flex: 1,
      paddingTop: theme.spacing.value(2),
      paddingLeft: theme.spacing.value(0),
      paddingRight: theme.spacing.value(0),
      marginTop: theme.spacing.value(1),
      background: theme.colors.neutral1,
      overflow: 'scroll',
      borderRadius: 32,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}), true)
