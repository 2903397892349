import { AppImages, variantProvider } from '@/app'
import { Button, Image, Modal, Text } from '@/components'
import { AnyFunction, useI18N } from '@codeleap/common'

type ModalAnswerProps = {
  type: 'correct' | 'incorrect' | 'neutral'
  toggle: AnyFunction
  visible: boolean
  text: string
  onNext: AnyFunction
  title: string
}

export const ModalLessonAnswer = (props: ModalAnswerProps) => {
  const {
    type,
    toggle,
    visible,
    text,
    onNext,
    title,
  } = props

  const { t } = useI18N()

  const isCorrect = type === 'correct'
  const isNeutral = type === 'neutral'

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      debugName={`Modal ${type} answer`}
      title={title}
      variants={['center', 'centered', 'boxPadding:4']}
      closable={false}
      styles={{
        box: styles.box,
      }}
    >
      {!isNeutral && (
        <Image
          source={isCorrect ? AppImages.Success : AppImages.Error}
          objectFit='cover'
          style={styles.icon}
        />
      )}
      <Text variants={['p1', 'color:neutral6', 'marginBottom:2', isNeutral ? 'marginTop:0' : 'marginTop:2', 'textCenter']} text={text} />

      <Button
        variants={['fullWidth', 'border-radius:rounded']}
        text={t(`learn.components.quizAnswerModal.button`)}
        debugName={`Modal ${type} answer - next`}
        onPress={onNext}
      />
    </Modal>
  )
}

const imageSize = 95
const MODAL_WIDTH = 400

const styles = variantProvider.createComponentStyle(theme => ({
  icon: {
    width: imageSize,

    [theme.media.up('small')]: {
      width: imageSize * 1.5,
    },
  },
  box: {
    width: theme.presets.calcWidth(theme.spacing.value(4)),
    maxWidth: MODAL_WIDTH,
  },
}), true)
