import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

export const SignupModal = () => {
  return (
    <AuthModal
      image={AppImages.Logo}
      name='signup'
    >
      <AuthForms.SignupForm />
    </AuthModal>
  )
}
