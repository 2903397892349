import { AchievementsStore } from './Store'
import { Modal, View, Text } from '@/components'
import { TypeGuards } from '@codeleap/common'
import { ModalHeaderActions } from './ModalHeaderActions'
import { useAppI18N, variantProvider } from '@/app'
import { LevelCarousel } from './LevelCarousel'
import { LevelTag } from './LevelTag'
import { LinkedInActions } from './LinkedInActions'
import { shareAchievementLevel } from '@/utils'
import { APIClient } from '@/services'
import { ConfettiAnimation } from './ConfettiAnimation'

export const AchievementModal = () => {
  const { visibleLevelModal, toggleLevelModal, levels } = AchievementsStore(store => store)
  const achievements = APIClient.Achievements.useAchievements()
  const { t } = useAppI18N()

  const level = levels?.[0] ?? null
  const achievement = achievements?.items?.find(achievement => achievement?.id == level?.achievement?.id)

  const close = () => {
    const remainingLevels = levels?.slice(1)?.filter(l => !!l)
    const hasLevels = remainingLevels?.length >= 1

    if (hasLevels) {
      AchievementsStore.setState({ visibleLevelModal: false })

      setTimeout(() => {
        AchievementsStore.setState({ levels: null })
        setTimeout(() => {
          toggleLevelModal(remainingLevels, true)
        }, 250)
      }, 400)
    } else {
      AchievementsStore.setState({ visibleLevelModal: false })

      setTimeout(() => {
        toggleLevelModal(null, false)
      }, 400)
    }
  }

  const _visible = visibleLevelModal && !TypeGuards.isNil(level) && !TypeGuards.isNil(achievement)

  return (
    <Modal
      variants={['noPadding']}
      responsiveVariants={{ small: ['fullscreen'] }}
      visible={_visible}
      toggle={close}
      styles={{
        'box': {
          backgroundColor: level?.bg_color
        },
        'body': styles?.body,
      }}
      renderHeader={() => (
        <View variants={['fullWidth', 'relative']}>
          <ModalHeaderActions onShare={() => shareAchievementLevel(level)} onClose={close} />
          <ConfettiAnimation visible={_visible} width={WIDTH} />
        </View>
      )}
    >
      {!!level?.congratulatory_message && <Text variants={['h2']} text={level?.congratulatory_message} />}

      <LevelTag level={level} variant='large' />

      <LevelCarousel 
        currentLevel={achievement?.levels?.list?.findIndex(l => l?.id == level?.id)} 
        levels={achievement?.levels?.list ?? []} 
      />

      <View variants={['fullWidth', 'center', 'column']}>
        {!!level?.achievement?.name && <Text variants={['h1', 'color:neutral9']} text={level?.achievement?.name} />}
        {!!level?.description && <Text variants={['p1', 'color:neutral9']} text={level?.description} />}
      </View>

      <View variants={['paddingHorizontal:4', 'gap:2', 'fullWidth', 'column', 'center']} responsiveVariants={{ small: ['padding:2'] }}>
        <Text variants={['p1', 'color:neutral8']} text={t('achievements.modalLevelLinkedInMessage')} />

        <View variants={['gap:2', 'fullWidth']}>
          <LinkedInActions achievement={achievement} />
        </View>
      </View>
    </Modal>
  )
}

const WIDTH = 700

const styles = variantProvider.createComponentStyle(theme => ({
  body: {
    maxWidth: WIDTH,
    width: '100vw',
    minHeight: WIDTH / 2,
    ...theme.presets.column,
    ...theme.presets.center,
    ...theme.spacing.gap(4),
    ...theme.spacing.paddingBottom(4),
    ...theme.spacing.paddingTop(12),

    [theme.media.down('small')]: {
      ...theme.spacing.paddingBottom(2),
      ...theme.spacing.paddingTop(10),
    }
  }
}), true)

