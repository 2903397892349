export type FirebaseInstance = any

let initialized = false

type FirebaseFunction<T> = (fb: FirebaseInstance) => T

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: 'AIzaSyDfxhpHJdf85gT7Wk4CoW9goh0iobC6ZC8',
  authDomain: 'x-kite.firebaseapp.com',
  projectId: 'x-kite',
  storageBucket: 'x-kite.appspot.com',
  messagingSenderId: '960640478043',
  appId: '1:960640478043:web:930684039abd572001087e',
  measurementId: 'G-D51WS67XN6',
}

export let cachedFirebaseInstance: FirebaseInstance = null

export async function getFirebase() {
  const fbApp = await import('firebase/compat/app')
  await import('firebase/compat/auth')
  await import('firebase/compat/analytics')

  const _firebase = fbApp.default

  if (!initialized) {
    _firebase.initializeApp(config)
    cachedFirebaseInstance = _firebase
    initialized = true
  }

  return _firebase
}

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth
  return firebaseAuth
}
