import { FileInput, useFileInput } from '@codeleap/web'
import { Button, View, Text, Image, ActionIcon } from '@/components'
import { WebInputFile } from '@codeleap/common'
import { Settings, Theme, variantProvider } from '@/app'

type FileSelectProps = {
  type: 'simplified' | 'complete'
  images: Array<WebInputFile>
  clearable?: boolean
  setImages: (images: Array<WebInputFile>) => void
}

export function FileSelect({ type, images, setImages, clearable = true }: FileSelectProps) {
  const { openFilePicker, ref } = useFileInput()

  const isSimplified = type === 'simplified'
  const hasImage = !!images?.length

  const textSimplified = hasImage ? images[0].file.name : 'Choose file'
  const textComplete = hasImage ? null : 'Add a picture (Optional)'

  return (
    <View variants={['column', 'gap:1', 'fullWidth', 'relative']}>
      {isSimplified && <Text variants={['p2']} text={`Upload an image`} />}
      <FileInput
        multiple={false}
        ref={ref}
        onFileSelect={(f) => setImages(f)}
        {...Settings.FileInputPresets.default}
      />
      <Button
        rightIcon={isSimplified && 'chevron-right'}
        icon={isSimplified ? 'upload-cloud' : (hasImage ? null : 'image-plus')}
        debugName='File select'
        text={isSimplified ? textSimplified : textComplete}
        variants={[isSimplified ? 'fileSelect:simplified' : 'fileSelect:complete', hasImage ? 'gap:0' : 'gap:1']}
        onPress={() => openFilePicker()}
        styles={{
          text: hasImage && { color: Theme.colors.light.neutral8 },
        }}
      >
        {!isSimplified && hasImage ? <Image source={images[0].preview} variants={['fileSelect:preview']} /> : null}
      </Button>
      {(!isSimplified && hasImage && clearable) ? (
        <ActionIcon
          variants={['outline']}
          icon='x'
          debugName={`Pressed Hello World!`}
          onPress={() => setImages([])}
          css={styles.clearButton}
        />
      ) : null}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  clearButton: {
    ...theme.presets.absolute,
    top: theme.spacing.value(1),
    right: theme.spacing.value(1),
  },
}), true)

