import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

const IMAGE_HEIGHT = 180

export const LoginModal = () => {
  return (
    <AuthModal
      image={AppImages.Door}
      name='login'
      centered={false}
      imageHeight={IMAGE_HEIGHT}
      small
    >
      <AuthForms.LoginForm />
    </AuthModal>
  )
}
