import { React, useAppI18N, variantProvider } from '@/app'
import { useIsMobile, useModal } from '@/utils'
import { View, Text, WorkingAt, AvatarProps, MyAvatar, ActionIcon, Modal, Button, ScreenNameExplainerModal } from '@/components'
import { useI18N } from '@codeleap/common'

type ProfileAvatarProps = {
  organisationName: string
  displayName: string
} & Partial<AvatarProps>

export const ProfileAvatar = (props: ProfileAvatarProps) => {
  const { organisationName = 'Kite Insights', displayName, ...avatarProps } = props
  const { visible, toggle } = useModal()

  const { t } = useAppI18N()
  const isMobile = useIsMobile()

  return (
    <>
      <View variants={['center']} responsiveVariants={{ small: ['column'] }}>
        <MyAvatar
          // @ts-ignore
          badgeIcon={'camera'}
          variants={['large', 'profileAvatar']}
          {...avatarProps}
        />

        <View variants={['column', 'marginLeft:4']} responsiveVariants={{ small: ['marginLeft:0', 'center'] }}>

          <View variants={['row', 'alignCenter', 'gap:1']} responsiveVariants={{ small: ['marginTop:2', 'textCenter'] }}>
            <Text
              variants={['h1', 'extraBold', 'color:neutral1']}
              responsiveVariants={{ small: ['textCenter', 'h2', 'color:neutral1'] }}
              text={displayName}
            />
            {isMobile && (
              <ActionIcon
                name='info'
                variants={['iconOnly', 'iconSize:3', 'color:neutral1']}
                onPress={toggle}
                debugName={'Open screen name modal'}
              />
            )}
          </View>

          {!isMobile && (
            <Text
              text={t('profile.description')}
              variants={['p3', 'color:neutral1']}
            />
          )}

          <WorkingAt white />
        </View>
      </View>
      <ScreenNameExplainerModal visible={visible} toggle={toggle} screenName={displayName} />
    </>
  )
}

const MAX_WIDTH = 360

const styles = variantProvider.createComponentStyle((theme) => ({
  header: {
    marginBottom: theme.spacing.marginBottom(1),
  },
  box: {
    maxWidth: MAX_WIDTH,
  },
}), true)
