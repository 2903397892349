import { Icon, Link, Text, View, Touchable } from '@/components'
import { Image, ImageProps } from '../Image'

import { Theme, variantProvider } from '@/app'
import { ViewProps } from '@codeleap/web'

type OrganisationHeaderProps = {
  title: string
  employees: string
  image: ImageProps['source']
  externalLink: string
  type: string
} & Partial<ViewProps<'div'>>

export function OrganisationHeader(props: OrganisationHeaderProps) {
  const { title, employees, image, externalLink, type, ...otherProps } = props
  return (
    <View variants={['fullWidth']} {...otherProps}>
      <View>
        <Image source={image} />
      </View>
      <View variants={['justifySpaceBetween', 'fullWidth', 'inlineFlex']}>
        <View>
          <View variants={['flex', 'column', 'justifyCenter', 'marginLeft:4']}>
            <Text variants={['h1', 'color:neutral1']} text={title} />

            <View variants={['inlineFlex', 'gap:2', 'alignCenter', 'marginTop:1']}>
              <View variants={['backgroundColor:primary5', 'padding:1', 'border-radius:tiny']}>
                <Text variants={['p3', 'color:neutral1']} text='Food & Drink' />
              </View>
              <View variants={['inlineFlex', 'alignCenter', 'gap:1']}>
                <Icon name={'users'} debugName='organisation employees icon' size={Theme.values.iconSize[1]} color={Theme.colors.light.neutral1} />
                <Text variants={['p3', 'color:neutral1']} text={`${employees} employees`} />
              </View>
              <View variants={['inlineFlex', 'alignCenter', 'gap:1']}>
                <Icon name={'external-link'} debugName='share organisation icon' size={Theme.values.iconSize[1]} color={Theme.colors.light.neutral1} />
                <Link
                  to={externalLink}
                  text={externalLink}
                  variants={['p3', 'color:neutral1']}
                  openNewTab
                />
              </View>
            </View>
          </View>
        </View>
        <Touchable
          debugName='share organisation'
          variants={['h:24', 'alignStart']}
          styles={styles.shareButton}
          onPress={() => alert('Share organisation')}
        >
          <Icon name={'share'} debugName='share organisation icon' size={Theme.values.iconSize[3]} color={Theme.colors.light.neutral1} />
        </Touchable>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(() => ({
  shareButton: {
    height: 'fit-content',
  },
}), true)
