import { getYouTubeId } from '@/utils'
import React from 'react'
import PlayerYouTube from 'react-youtube'
import { Options } from 'youtube-player/dist/types'

type YouTubeProps = {
  video: string
  options?: Options
  style?: React.CSSProperties
}

export const YouTube = (props: YouTubeProps) => {
  const { video, options = {}, style = {} } = props

  const videoId = getYouTubeId(video)

  if (!videoId) return null

  return <PlayerYouTube style={style} videoId={videoId} opts={options} />
}
