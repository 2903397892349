import { analytics } from '@/services'
import { Achievement, AchievementLevel } from '@/types'
import { AnyFunction, TypeGuards } from '@codeleap/common'
import { create } from 'zustand'

type TAchievementsStore = {
  achievement: Achievement | null
  levels: AchievementLevel[] | null
  toggleAchievementModal: (achievement: Achievement | null) => void
  visibleAchievementModal: boolean
  toggleLevelModal: (level?: AchievementLevel[] | null, to?: boolean) => void
  visibleLevelModal: boolean
  triggerLevelModal: AnyFunction
}

export const AchievementsStore = create<TAchievementsStore>((set) => ({
  achievement: null,
  levels: null,
  visibleAchievementModal: false,
  toggleAchievementModal: (achievement) => {
    if (TypeGuards.isNil(achievement)) {
      set({ visibleAchievementModal: false })
    } else {
      set({ achievement, visibleAchievementModal: true })
    }
  },
  visibleLevelModal: false,
  toggleLevelModal: (levels = null, to = null) => set(state => {
    const visible = TypeGuards.isBoolean(to) ? to : !state?.visibleLevelModal

    return {
      levels,
      visibleLevelModal: visible,
    }
  }),
  triggerLevelModal: () => set(state => {
    const visible = (TypeGuards.isNil(state?.levels) || state?.levels?.length) <= 0 ? false : true

    if (visible) {
      return {
        visibleLevelModal: visible,
      }
    }
  }),
}))

export const verifyAchievementProgress = (achievementProgress: AchievementLevel[], to: boolean = null) => {
  const achievementLevel = achievementProgress?.[0]

  if (!TypeGuards.isNil(achievementLevel)) {
    AchievementsStore.getState().toggleLevelModal(achievementProgress, to)
    analytics?.track?.('badge_awarded', {
      badge_level: `${achievementLevel.achievement.name} ${achievementLevel.name}`,
    })
    return true
  }

  return false
}
