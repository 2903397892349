export const imageIcons = {
  'achievement-rocket': require('./achievement-rocket.svg'),
  'achievement-star': require('./achievement-star.svg'),
  'apple': require('./apple.svg'),
  'arrow-right': require('./arrow-right.svg'),
  'baby': require('./baby.svg'),
  'book-open': require('./book-open.svg'),
  'book': require('./book.svg'),
  'briefcase': require('./briefcase.svg'),
  'bug': require('./bug.svg'),
  'calendar': require('./calendar.svg'),
  'camera': require('./camera.svg'),
  'check-circle': require('./check-circle.png'),
  'check': require('./check.svg'),
  'chevron-down': require('./chevron-down.svg'),
  'chevron-left': require('./chevron-left.svg'),
  'chevron-right': require('./chevron-right.svg'),
  'chevron-up': require('./chevron-up.svg'),
  'chevrons-up-down': require('./chevrons-up-down.svg'),
  'clock': require('./clock.svg'),
  'edit': require('./edit.svg'),
  'empty-check-circle': require('./empty-check-circle.png'),
  'expand': require('./expand.svg'),
  'external-link': require('./external-link.svg'),
  'eye-off': require('./eye-off.svg'),
  'eye': require('./eye.svg'),
  'facebook-fill': require('./facebook-fill.svg'),
  'facebook-outline': require('./facebook-outline.svg'),
  'facebook': require('./facebook.svg'),
  'file': require('./file.svg'),
  'gear': require('./gear.svg'),
  'gem': require('./gem.svg'),
  'google': require('./google.svg'),
  'hand': require('./hand.svg'),
  'head-outline': require('./head-outline.svg'),
  'heart': require('./heart.svg'),
  'helping-hand': require('./helping-hand.svg'),
  'home': require('./home.svg'),
  'image-plus': require('./image-plus.svg'),
  'image': require('./image.svg'),
  'info': require('./info.svg'),
  'instagram': require('./instagram.svg'),
  'key': require('./key.svg'),
  'languages': require('./languages.svg'),
  'linkedin-fill': require('./linkedin-fill.svg'),
  'linkedin': require('./linkedin.svg'),
  'linkedin-outline': require('./linkedin-outline.svg'),
  'list': require('./list.svg'),
  'lock': require('./lock.svg'),
  'log-out': require('./log-out.svg'),
  'mail-fill': require('./mail-fill.svg'),
  'mail': require('./mail.svg'),
  'maximize': require('./maximize.svg'),
  'menu': require('./menu.svg'),
  'message-square': require('./message-square.svg'),
  'minimize': require('./minimize.svg'),
  'pause-circle-fill': require('./pause-circle-fill.svg'),
  'pencil': require('./pencil.svg'),
  'pin': require('./pin.svg'),
  'pip': require('./pip.svg'),
  'play-circle-fill': require('./play-circle-fill.svg'),
  'play-circle': require('./play-circle.svg'),
  'plus': require('./plus.svg'),
  'rocket': require('./rocket.svg'),
  'search': require('./search.svg'),
  'share': require('./share.svg'),
  'star-fill': require('./star-fill.svg'),
  'star': require('./star.svg'),
  'thumbs-up-fill': require('./thumbs-up-fill.svg'),
  'thumbs-up': require('./thumbs-up.svg'),
  'trash': require('./trash.svg'),
  'twitter-bird': require('./twitter-bird.svg'),
  'twitter': require('./twitter.png'),
  'upload-cloud': require('./upload-cloud.svg'),
  'user': require('./user.svg'),
  'users': require('./users.svg'),
  'vertical-dots': require('./vertical-dots.svg'),
  'volume-1': require('./volume-1.svg'),
  'volume-2': require('./volume-2.svg'),
  'volume-mute': require('./volume-mute.svg'),
  'voume-0': require('./voume-0.svg'),
  'x': require('./x.svg'),
  'reply': require('./reply.svg'),
  'padlock': require('./padlock.svg'),
  'facebook-primary4': require('./facebook-primary4.svg'),
  'linkedin-primary4': require('./linkedin-primary4.svg'),
  'twitter-bird-primary4': require('./twitter-bird-primary4.svg'),
}
