import { Alert } from '@/components'
import { useAppSelector } from '@/redux'
import { useI18N } from '@codeleap/common'

type LogoutAlertProps = {
  visible: boolean
  toggle: () => void
  handleLogout: () => void
}

export const LogoutAlert = (props: LogoutAlertProps) => {
  const {
    visible,
    toggle,
    handleLogout,
  } = props

  const status = useAppSelector(state => state?.AppStatus?.status)

  const { t } = useI18N()

  return (
    <Alert
      visible={visible && !(status === 'loading' || status === 'done')}
      title={t('logout')}
      type='destructive'
      body={t('profile.logoutModal.subtitle')}
      onAction={handleLogout}
      variants={['profileAlert']}
      toggle={toggle}
      debugName={'Profile edit - logout alert'}
      actionButtonProps={{
        text: t('logout'),
        debugName: 'profile edit - log out alert button',
      }}
      dismissButtonProps={{
        text: t('cancel'),
        debugName: 'profile edit - cancel log out alert button',
        variants: ['flex', 'outline', 'large', 'pill'],
      }}
    />
  )
}
