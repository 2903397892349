import React from 'react'

import { LoginModal } from './LoginModal'
import { SignupModal } from './SignupModal'
import { AboutJobModal } from './AboutJobModal'
import { AboutYouModal } from './AboutYouModal'
import { ForgotAfterModal } from './ForgotAfterModal'
import { ForgotBeforeModal } from './ForgotBeforeModal'
import { VerifyEmailAfterModal } from './VerifyEmailAfterModal'
import { VerifyEmailBeforeModal } from './VerifyEmailBeforeModal'

export const AuthModals = () => {
  return <>
    <LoginModal />
    <SignupModal />
    <AboutJobModal />
    <AboutYouModal />
    <ForgotAfterModal />
    <ForgotBeforeModal />
    <VerifyEmailAfterModal />
    <VerifyEmailBeforeModal />
  </>
}
