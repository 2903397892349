import React from 'react'
import { Organisation } from '@/types'
import { formatOrganisationEmployees, SELF_EMPLOYED } from '@/utils'
import { useI18N } from '@codeleap/common'
import { Text, View } from '@/components'

type OrganisationCategoryInfoProps = {
  organisation: Partial<Organisation>
}

export const OrganisationCategoryInfo = (props: OrganisationCategoryInfoProps) => {
  const { organisation } = props

  const { t } = useI18N()

  const employees = formatOrganisationEmployees(organisation)
  const isSelfEmployed = employees === SELF_EMPLOYED

  const employeesText = employees && (isSelfEmployed ? (
    t('organisations.selfEmployed')
  ) : (
    t('organisations.employees', { employees })
  ))

  const showInfo = employees && !!organisation?.category

  return (
    <>
      {!showInfo ? (
        <View>
          {organisation?.category?.label && (
            <Text
              variants={['p1', 'color:neutral6']}
              responsiveVariants={{ small: ['p3'] }}
            >
              {`${organisation?.category?.label}`}
            </Text>
          )}
          {employeesText && (
            <Text
              variants={['p1', 'color:neutral6']}
              responsiveVariants={{ small: ['p3'] }}
            >
              {`(${employeesText})`}
            </Text>
          )}
        </View>
      ) : (
        <Text
          variants={['p1', 'color:neutral6']}
          responsiveVariants={{ small: ['p3'] }}
          text={`${organisation?.category?.label} (${employeesText})`}
        />
      )
      }
    </>
  )
}
