import { variantProvider } from '@/app'
import { ensureHttps, getYouTubeId, useYoutubeThumbnail } from '@/utils'
import { toggleModal } from '@codeleap/web'
import { CSSInterpolation } from '@emotion/serialize'
import React from 'react'
import { Fullscreen } from './Fullscreen'
import { store } from './store'
import { View, Icon} from '@/components'
import { arePropsEqual } from '@codeleap/common'

type PreviewProps = {
  video: string
  style?: CSSInterpolation
  openVideo?: boolean
}

export const Preview = React.memo((props: PreviewProps) => {
  const { video: _video, style = {}, openVideo = true } = props

  const video = ensureHttps(_video)

  const videoId = getYouTubeId(video)

  const thumbnailUrl = useYoutubeThumbnail(videoId)

  const onPress = () => {
    store.setState({ videoUrl: video })
    toggleModal(Fullscreen.modalId, true)
  }

  return (
    <View css={[styles.preview, openVideo && { cursor: 'pointer' }]} onClick={openVideo ? onPress : undefined}>
      <img
        src={thumbnailUrl}
        alt='Video Thumbnail'
        css={[styles.preview, style]}
      />

      <View style={styles.overlay}>
        <Icon debugName='player youtube icon' name='play-circle-fill' variants={['iconSize:5']} />
      </View>
    </View>
  )
}, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['video'],
  })
})

const styles = variantProvider.createComponentStyle(theme => ({
  preview: {
    width: '100%',
    height: 'auto',
    aspectRatio: 16/9,
    borderRadius: theme.borderRadius.medium,
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    ...theme.presets.whole,
    ...theme.presets.absolute,
    ...theme.presets.center,
    backgroundColor: '#00000050',
  }
}), true)
