import { Story } from '@/types'
import { create } from 'zustand'

type CreateStoryShare = {
  id: Story['id']
  setStory: (data: Story['id']) => void
  data: Story['id']
}

export const shareStoryId = create<CreateStoryShare>((set) => ({
  id: null,
  data: null,
  setStory: (storyId: Story['id']) => set({ id: storyId }),
}))
