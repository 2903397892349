import { Text, View } from '@/components'
import { useMediaQueryDown } from '@/utils'
import { useI18N } from '@codeleap/common'

type SearchLabelProps = {
  search: string
}

export const SearchLabel = ({ search }: SearchLabelProps) => {
  const isMobile = useMediaQueryDown('small')
  const { t } = useI18N()

  if (search?.length <= 0) return null

  return (
    <View variants={['row', 'fullWidth', 'gap:1']}>
      <Text variants={['h5', 'color:neutral6', 'alignSelfStart', isMobile ? 'marginBottom:2' : 'marginBottom:4']} text={t('learn.learnScreen.searchLabel')}/>
      <Text variants={['p1', 'color:primary3']} text={`"${search}"`} />
    </View>
  )
}
