import { React, Settings, variantProvider, assignTextStyle } from '@/app'
import { useI18N } from '@codeleap/common'
import { ActionIcon, Button, Modal, View, Text, SearchInput } from '@codeleap/web'
import { useState } from 'react'

type SearchModalProps = {
  visible: boolean
  toggle: () => void
  onSearch: (text: string) => void
}

type ApplyButtonProps = {
  onApply: (search: string) => void
}

type CancelButtonProps = {
  onCancel: () => void
}

function ApplyButton({ onApply }: ApplyButtonProps) {

  return (
    <Button
      text={'Apply'}
      debugName={`Apply button from search modal`}
      variants={['flex', 'large', 'pill', 'neutral10']}
      onPress={onApply}
    />
  )
}

function CancelButton({ onCancel }: CancelButtonProps) {

  return (
    <Button
      text={'Cancel'}
      debugName={`Cancel button from search modal`}
      variants={['flex', 'large', 'pill', 'neutral2']}
      onPress={onCancel}
      styles={{
        text: styles.cancelButtonText,
      }}
    />
  )
}

export const SearchModal: React.FC = ({ visible, toggle, onSearch }: SearchModalProps) => {
  const [search, setSearch] = useState('')
  const { t } = useI18N()

  const onChangeSearch = async (searchValue: string) => {
    setSearch(searchValue)
  }

  async function onApply() {
    onSearch(search)
    await setSearch('')
    toggle()
  }

  async function onCancel() {
    await setSearch('')
    toggle()
  }

  return <>
    <Modal
      title='Search'
      visible={visible}
      toggle={toggle}
      debugName={'Search Modal'}
      variants={['centered']}
      styles={{
        box: styles.boxModal,
        title: styles.titleModal,
      }}
    >
      <View variants={['column', 'gap:4']}>
        <SearchInput
          placeholder={t('searchModal.placeholder')}
          debugName={'Search Input'}
          onSearchChange={onChangeSearch}
          variants={['marginTop:2', 'noError']}
          debounce={800}
          value={search}
          onValueChange={setSearch}
        />

        <View variants={['gap:3']}>
          <CancelButton onCancel={onCancel}/>
          <ApplyButton onApply={onApply} />
        </View>
      </View>
    </Modal>
  </>
}

const styles = variantProvider.createComponentStyle((theme) => ({
  boxModal: {
    width: '100%',
    maxWidth: '523px',
    ...theme.spacing.padding(4),
    borderRadius: theme.spacing.value(4),
  },
  titleModal: {
    ...assignTextStyle('h2')(theme).text,
  },
  cancelButtonText: {
    color: theme.colors.neutral10,
  },
}), true)
