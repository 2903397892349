exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-achievements-level-id-tsx": () => import("./../../../src/pages/achievements/[levelId].tsx" /* webpackChunkName: "component---src-pages-achievements-level-id-tsx" */),
  "component---src-pages-act-tsx": () => import("./../../../src/pages/act.tsx" /* webpackChunkName: "component---src-pages-act-tsx" */),
  "component---src-pages-auth-about-job-tsx": () => import("./../../../src/pages/auth/aboutJob.tsx" /* webpackChunkName: "component---src-pages-auth-about-job-tsx" */),
  "component---src-pages-auth-about-you-tsx": () => import("./../../../src/pages/auth/aboutYou.tsx" /* webpackChunkName: "component---src-pages-auth-about-you-tsx" */),
  "component---src-pages-auth-forgot-tsx": () => import("./../../../src/pages/auth/forgot.tsx" /* webpackChunkName: "component---src-pages-auth-forgot-tsx" */),
  "component---src-pages-auth-linked-in-tsx": () => import("./../../../src/pages/auth/linkedIn.tsx" /* webpackChunkName: "component---src-pages-auth-linked-in-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-screens-tsx": () => import("./../../../src/pages/auth/screens.tsx" /* webpackChunkName: "component---src-pages-auth-screens-tsx" */),
  "component---src-pages-auth-signup-tsx": () => import("./../../../src/pages/auth/signup.tsx" /* webpackChunkName: "component---src-pages-auth-signup-tsx" */),
  "component---src-pages-email-reset-tsx": () => import("./../../../src/pages/email-reset.tsx" /* webpackChunkName: "component---src-pages-email-reset-tsx" */),
  "component---src-pages-email-verified-tsx": () => import("./../../../src/pages/email-verified.tsx" /* webpackChunkName: "component---src-pages-email-verified-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-chapter-[id]-tsx": () => import("./../../../src/pages/learn/chapter/[id].tsx" /* webpackChunkName: "component---src-pages-learn-chapter-[id]-tsx" */),
  "component---src-pages-learn-index-tsx": () => import("./../../../src/pages/learn/index.tsx" /* webpackChunkName: "component---src-pages-learn-index-tsx" */),
  "component---src-pages-learn-lesson-view-[id]-tsx": () => import("./../../../src/pages/learn/lessonView/[id].tsx" /* webpackChunkName: "component---src-pages-learn-lesson-view-[id]-tsx" */),
  "component---src-pages-organisations-[id]-tsx": () => import("./../../../src/pages/organisations/[id].tsx" /* webpackChunkName: "component---src-pages-organisations-[id]-tsx" */),
  "component---src-pages-organisations-index-tsx": () => import("./../../../src/pages/organisations/index.tsx" /* webpackChunkName: "component---src-pages-organisations-index-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/passwordReset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-profile-achievements-tsx": () => import("./../../../src/pages/profile/achievements.tsx" /* webpackChunkName: "component---src-pages-profile-achievements-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-menu-tsx": () => import("./../../../src/pages/profile/menu.tsx" /* webpackChunkName: "component---src-pages-profile-menu-tsx" */),
  "component---src-pages-profile-settings-tsx": () => import("./../../../src/pages/profile/settings.tsx" /* webpackChunkName: "component---src-pages-profile-settings-tsx" */),
  "component---src-templates-org-info-tsx": () => import("./../../../src/templates/OrgInfo.tsx" /* webpackChunkName: "component---src-templates-org-info-tsx" */),
  "component---src-templates-sector-tsx": () => import("./../../../src/templates/Sector.tsx" /* webpackChunkName: "component---src-templates-sector-tsx" */)
}

