import { AppForms, variantProvider, assignTextStyle, LocalStorageKeys } from '@/app'
import { Modal, View, Text, Tooltip, Slider, Button } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { LocalStorage } from '@/utils'
import { useForm, TypeGuards } from '@codeleap/common'

function Question({ label, description, options, ...props }) {
  const _options = options.slice().reverse()
  const optionsLength = _options.length
  const bottomTrackMarks = {
    0: String(_options[0].label),
    100: String(_options[optionsLength - 1].label),
  }

  return (
    <View variants={['column']} {...props}>
      <Text variants={[`p4`]} text={label} />
      <Text variants={['h3', 'marginBottom:4']} text={description} />
      <View style={styles.sliderWrapper}>
        <View style={styles.scaleWrapper}>
          {Array.from({ length: optionsLength }).map((_, index) => (
            <Tooltip
              debugName={`tooltip-${_options[index].value}`}
              content={<Text variants={['p1', 'color:neutral1']} text={_options[index].label} />}
              open={props.value[0] === index && !(index === 0 || index === optionsLength - 1)}
              key={`${index}-label`}
              side='top'
              arrowProps={{ style: styles.tooltipArrow }}
              styles={{ wrapper: styles.tooltip }}
            >
              <View style={styles.scaleMarks} />
            </Tooltip>
          ))}
        </View>
        <Slider {...props} step={1} max={optionsLength - 1} trackMarks={bottomTrackMarks} styles={{ trackMark: styles.sliderTrack }} />
      </View>
    </View>
  )
}

export function OnboardingQuestions() {
  const { options, updateProfile, profile } = APIClient.Session.useSession()
  const form = useForm(AppForms.onboardingQuestions)

  const visible = useAppSelector((state) => state?.AppStatus?.modals?.onboardingQuestions)

  function onSkip() {
    form.setFormValues({
      climate_priority: [0],
      climate_actionability: [0],
    })
    AppStatus.setModal(['onboardingQuestions', false])
  }

  async function onAnswer() {
    AppStatus.set('loading')
    const formValues = Object.entries(form.values).reduce((acc, [key, value]) => {
      const index = TypeGuards.isArray(value) ? value[0] : value
      const _options = [...options[key]].reverse()

      return { ...acc, [key]: _options[index].value }
    }, {})

    if (profile) {
      await updateProfile.mutateAsync(formValues as any)
    } else {
      LocalStorage.setItem(LocalStorageKeys.ONBOARDING_QUESTIONS, formValues)
    }
    onSkip()

    setTimeout(() => {
      AppStatus.set('done')
    }, 500)
  }

  if (!options) return null

  return (
    <Modal visible={visible} variants={['centered']} closable={false} styles={{ box: styles.modal }}>
      <Question {...form.register('climate_priority')} options={options?.climate_priority} />
      <Question {...form.register('climate_actionability')} options={options?.climate_actionability} />
      <View variants={['fullWidth', 'justifySpaceBetween']}>
        <Button text='Skip' onPress={onSkip} debugName='on skip - onboarding questions' variants={['outline', 'elongated']}/>
        <Button text='Access Hurd' onPress={onAnswer} debugName='on answer - onboarding questions' />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modal: {
      maxWidth: 850,
      ...theme.presets.fullWidth,
      ...theme.spacing.margin(2),
      ...theme.spacing.padding(4),
    },

    tooltip: {
      backgroundColor: theme.colors.neutral6,
      ...theme.spacing.paddingHorizontal(1),
      ...theme.spacing.paddingVertical(0.25),
      borderRadius: 4,
      zIndex: 1000,
    },
    tooltipArrow: {
      fill: theme.colors.neutral6,
    },

    sliderWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.presets.relative,
    },
    sliderTrack: {
      ...assignTextStyle('p3')(theme).text,
    },

    scaleWrapper: {
      width: 'calc(100% - 20px)',
      ...theme.presets.absolute,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.row,
      height: 16,
      ...theme.spacing.marginHorizontal(1.25),
    },
    scaleMarks: {
      height: 16,
      width: 2,
      backgroundColor: theme.colors.neutral2,
      borderTopLeftRadius: theme.borderRadius.rounded,
      borderTopRightRadius: theme.borderRadius.rounded,
    },
  }),
  true,
)
