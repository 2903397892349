import React from 'react'
import { View, Text, ActionIcon, Image, useNavigation } from '@/components'
import { AppImages, useAppI18N, variantProvider } from '@/app'
import { navigate } from 'gatsby'

export const AchievementsBanner = React.memo(() => {
  const { t } = useAppI18N()
  const { matchPath } = useNavigation()

  return (
    <View 
      variants={['backgroundColor:primary2', 'border-radius:small']}
      style={styles.wrapper}
    >
      <View variants={['column', 'gap:0.5']} style={{ maxWidth: '60%', zIndex: 11 }}>
        <Text variants={['h4']} style={{ maxWidth: '80%' }} text={t('achievements.banner.title')} />
        <Text variants={['p3']} text={t('achievements.banner.body')} />
      </View>

      {matchPath('/achievements') ? null : (
        <ActionIcon 
          debugName='achievements:banner' 
          name='arrow-right' 
          variants={['outline', 'noBorder', 'alignSelfStart', 'overlap']}
          onPress={() => navigate('/profile/achievements')} 
        />
      )}

      <Image source={AppImages.BadgeTrophy} style={styles.image} />
    </View>
  )
}, () => true)

const BANNER_WIDTH = 330

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.center,
    ...theme.presets.justifySpaceBetween,
    ...theme.spacing.padding(2),
    ...theme.spacing.gap(6),
    ...theme.presets.fullWidth,
    ...theme.presets.relative,
    maxWidth: BANNER_WIDTH,
    width: '100vw',
    overflow: 'hidden',

    [theme.media.down('mid')]: {
      maxWidth: 'unset',
      width: '100%',
    }
  },
  image: {
    ...theme.presets.absolute,
    right: theme.spacing.value(0),
    top: theme.spacing.value(0),
    bottom: theme.spacing.value(0),
    height: '100%',
    zIndex: 0,
  }
}), true)
