import { easeOut, animate, motion, useMotionValue, useTransform, AnimationPlaybackControls } from 'framer-motion'
import { Logo } from './Logo'
import { ActionIcon } from '@/components'
import { React, Settings, Theme, useAppI18N, variantProvider } from '@/app'
import { useWindowSize } from '@codeleap/web'
import { useRef } from 'react'

const TRANSITION_DURATION = 1

const BOUNCE_DURATION = 1

const TRANSITION_END_VALUE = 2

export const useInteractiveSplash = () => {

  const [_, windowHeight] = useWindowSize()

  const TRANSLATE_LOGO_Y = windowHeight * 0.3

  const TRANSLATE_TEXT_Y = windowHeight * 0.4

  const TRANSLATE_ICON_Y = Theme.spacing.value(2)

  const transitionProgress = useMotionValue(0)

  const logoOpacity = useTransform(transitionProgress, [0, 1], [1, 0])
  const logoTranslateY = useTransform(transitionProgress, [0, 1], [0, -TRANSLATE_LOGO_Y])

  const logoStyle = {
    opacity: logoOpacity,
    translateY: logoTranslateY,
  }

  const textOpacity = useTransform(transitionProgress, [1, TRANSITION_END_VALUE], [0, 1])
  const textTranslateY = useTransform(transitionProgress, [1, TRANSITION_END_VALUE], [TRANSLATE_TEXT_Y, 0])

  const textStyle = {
    opacity: textOpacity,
    translateY: textTranslateY,
  }

  const iconBounceProgress = useMotionValue(0)

  const iconBounceAnimation = useRef<AnimationPlaybackControls | null>(null)

  const stopIconBounce = () => {
    if (!!iconBounceAnimation.current) {
      iconBounceAnimation.current.stop()
      iconBounceAnimation.current = null
    }
  }

  const startIconBounce = () => {
    iconOpacity.on('change', (v) => {
      if (!!iconBounceAnimation.current && v === 0) {
        stopIconBounce()
      }
    })

    iconBounceAnimation.current = animate(iconBounceProgress, 1, {
      duration: BOUNCE_DURATION,
      repeat: Infinity,
      repeatType: 'reverse',
      ease: easeOut,
    })
  }

  const iconOpacity = useTransform(transitionProgress, [0, 1], [1, 0])

  const iconTranslateY = useTransform(iconBounceProgress, [0, 1], [0, -TRANSLATE_ICON_Y])

  const iconStyle = {
    opacity: iconOpacity,
    translateY: iconTranslateY,
  }

  const startTransition = async () => {
    let toValue = TRANSITION_END_VALUE

    if (InteractiveSplashContent.DEBUG) {
      toValue = transitionProgress.get() === TRANSITION_END_VALUE ? 0 : TRANSITION_END_VALUE
    }

    animate(transitionProgress, toValue, {
      duration: TRANSITION_DURATION,
    })

  }

  return {
    startIconBounce,
    transitionProgress,
    iconBounceProgress,
    logoStyle,
    textStyle,
    iconStyle,
    startTransition,

  }
}

type InteractiveSplashContentProps = {
  animationData: ReturnType<typeof useInteractiveSplash>
}

export const InteractiveSplashContent = (props: InteractiveSplashContentProps) => {
  const { animationData } = props
  const { logoStyle, textStyle } = animationData

  const { t } = useAppI18N()

  return (
    <>
      <motion.div style={logoStyle}>
        <Logo variants={['splash']} responsive={false} />
      </motion.div>

      <motion.p css={[styles.text]} style={textStyle}>{t('splashText')}</motion.p>
    </>
  )
}

const Button = ({ style, onPress }) => {
  return (

    <motion.div css={[styles.icon]} style={style}>

      <ActionIcon
        name='chevron-down'
        onPress={onPress}
        debugName='Interactive app status icon'

      />
    </motion.div>
  )
}

InteractiveSplashContent.Button = Button
InteractiveSplashContent.DEBUG = Settings.Environment.IsDev && true

if (InteractiveSplashContent.DEBUG && Settings.Environment.IsDev) {
  console.log('Splash content is in debug mode. You may disable this in src/components/InteractiveSplash.tsx')
}

const styles = variantProvider.createComponentStyle(theme => ({

  icon: {
    ...theme.presets.absolute,
    bottom: theme.spacing.value(1),
    alignSelf: 'center',
  },
  text: {
    color: theme.colors.primary3,
    fontSize: 48,
    textAlign: 'center',
    fontWeight: '800',
    position: 'absolute',
    fontFamily: 'Karla',
  },

}), true)
