import { View, Text } from '@/components'
import { formatScore } from '@/utils'
import { ProgressCircle } from '../Progress/Circle'

export const Score = ({ img = null, score, name }) => {
  return (
    <View variants={['gap:2', 'alignCenter']}>
      <ProgressCircle
        progress={score}
        image={img}
        variants={['small', 'noLabel']}
      />
      <View variants={['column']}>
        <Text variants={['h5', 'color:neutral5']}>{score ? `${formatScore(score)}%` : '0%'}</Text>
        <Text variants={['p2', 'color:neutral9']}>{name}</Text>
      </View>
    </View>
  )
}