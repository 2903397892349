import { AppImages, useAppI18N } from '@/app'
import { AuthModal } from './auth/AuthModal'
import { AuthForms } from '../Auth'

export const AuthWall = () => {

  const { t } = useAppI18N()

  return <AuthModal
    image={AppImages.AuthWall}
    name='authWall'
    imageHeight={211}
  >
    <AuthForms.SignupForm
      title={t('registrationWall.title')}
      modalName='authWall'
    />
  </AuthModal>
}
