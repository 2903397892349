import { AchievementLevel } from '@/types'
import { View } from '@/components'
import { LevelBadge } from './HexagonBadge'

type LevelCarouselProps = {
  currentLevel: number
  levels: AchievementLevel[]
}

export const LevelCarousel = (props: LevelCarouselProps) => {
  const {
    currentLevel,
    levels,
  } = props

  const activeLevel = levels[currentLevel]

  if (!activeLevel) return null

  const previousLevel = levels[currentLevel - 1]
  const nextLevel = levels[currentLevel + 1]

  return (
    <View variants={['justifyCenter', 'row', 'alignCenter']}>
      <LevelBadge level={previousLevel} variant={previousLevel ? 'small' : 'hidden'} />
      <LevelBadge level={activeLevel} />
      <LevelBadge level={nextLevel} variant={nextLevel ? 'small' : 'hidden'} />
    </View>
  )
}
