import { ProgressBarComposition, ProgressBarStyles } from '@/app/stylesheets/ProgressBar'
import { ComponentVariants, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import {
  Root as ProgressRoot,
  Indicator as ProgressIndicator,
  ProgressIndicatorProps,
  ProgressProps,
} from '@radix-ui/react-progress'
import { Text, View } from '@/components'

type ProgressBarProps = ComponentVariants<typeof ProgressBarStyles> & Omit<PropsOf<typeof View>, 'variants' | 'responsiveVariants' | 'styles'> & {
  progress: number
  text?: string | number
  styles?: StylesOf<ProgressBarComposition>
  textProps?: PropsOf<typeof Text>
  progressIndicatorProps?: ProgressIndicatorProps
  progressRootProps?: ProgressProps
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    progress = 0,
    text,
    variants = [],
    responsiveVariants = {},
    styles = {},
    textProps = {},
    progressIndicatorProps = {},
    progressRootProps = {},
    ...rest
  } = props

  const variantStyles = useDefaultComponentStyle<'u:ProgressBar', typeof ProgressBarStyles>('u:ProgressBar', {
    variants,
    responsiveVariants,
    styles,
  })

  const isTextNumber = TypeGuards.isNumber(text)

  return (
    <View css={variantStyles.wrapper} {...rest}>
      <ProgressRoot css={variantStyles.progress} value={progress} {...progressRootProps}>
        <ProgressIndicator
          css={[variantStyles.indicator, { transform: `translateX(-${100 - progress}%)` }]}
          {...progressIndicatorProps}
        />
      </ProgressRoot>
      {(text || isTextNumber) && <Text css={variantStyles.text} {...textProps}>{isTextNumber ? `${text}%` : text }</Text>}
    </View>
  )
}
