import { makeFetcher, TypeGuards } from '@codeleap/common'
import { Settings } from './Settings'
import { getFirebase } from '../services/firebaseApp'
import { isUsingAdBlocker } from '@/utils'
import { userAgentParser } from './constants'

let expirationTime = null
let _adblockerOn: boolean = null

const isAdblockerOn = async () => {
  if (!TypeGuards.isNil(_adblockerOn)) return _adblockerOn

  const usingAdblocker = await isUsingAdBlocker()

  _adblockerOn = usingAdblocker

  return usingAdblocker
}

const shouldRefreshToken = () => {
  if (!expirationTime) return true

  const now = Date.now()
  const diff = expirationTime - now

  return diff > 0
}

const client = makeFetcher(Settings, {
  // logger,
  multipartParser: (body) => {
    const form = new FormData()

    for (const [key, value] of Object.entries(body)) {
      if (value instanceof File) {
        form.append(key, value)
      } else if (TypeGuards.isArray(value) && value.every((v) => v instanceof File)) {
        value.forEach((v) => form.append(key, v))
      } else {
        if (TypeGuards.isNil(value)) continue

        form.append(key, JSON.stringify(value))
      }
    }
    return form
  },
  requestMiddleware: [
    async (req) => {
      let token = ''
      const firebase = await getFirebase()
      const user = firebase.auth().currentUser
      const { Session } = await import('../redux')
      const APIClient = await import('../services/api')

      const isLoggedIn = !!APIClient.Session.QueryKeys.me.getData()?.id

      const usingAdblocker = await isAdblockerOn()

      if (user) {
        const refresh = shouldRefreshToken()
        const tokenResult = await user.getIdTokenResult(refresh)
        expirationTime = tokenResult.expirationTime

        Session.setUserClaims(tokenResult.claims ?? {})
        token = tokenResult.token

        if (isLoggedIn) {

          const type = userAgentParser.getDevice().type
          const isDesktop = type === undefined || !['wearable', 'mobile'].includes(type)

          req.headers.set('x-device', isDesktop ? 'desktop' : 'mobile')
          req.headers.set('x-web', 'true')
          req.headers.set('x-blocker', usingAdblocker ? `true` : 'false')
        }

      }

      if (token) {
        req.headers.set('Authorization', token)
      }

      const userLocale = 'en'

      req.headers.set('Accept-Language', userLocale)

      return req
    },
  ],
})

export const api = client
