import { React, I18N } from '@/app'
import { List } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Organisation } from '@/types'
import { IconPlaceholder, PropsOf, QueryManager, QueryManagerItem, TypeGuards, usePagination } from '@codeleap/common'
import { ListProps, PaginationIndicator, View, Button } from '@codeleap/web'
import { formatNumberToShort } from './misc'

type GetFlatlistPropsOptions = {
  noMoreItemsText?: string
  forceLoading?: boolean
  forceHasNextPage?: boolean

}

export const useFlatlistProps = <
  TItem extends QueryManagerItem,
  T extends QueryManager<TItem, any, any, any>,
>(hookReturn: ReturnType<T['use']>, options?: GetFlatlistPropsOptions) => {
  const {
    noMoreItemsText = 'No more items to show',
    forceHasNextPage = false,
    forceLoading = false,

  } = (options || {})

  const listQuery = hookReturn.list.query

  const hasMore = hookReturn.list.query.isLoading || hookReturn.list.query.hasNextPage

  const showPaginationIndicator = hookReturn.list.query.isFetchingNextPage || !hasMore

  const ListPaginationIndicator = React.useCallback(({ isEmpty = false }) => {
    if (!showPaginationIndicator || isEmpty) return null

    return (
      <PaginationIndicator
        isFetching={hookReturn.list.query.isFetchingNextPage || forceHasNextPage}
        noMoreItemsText={noMoreItemsText}
        hasMore={hasMore}
      />
    )
  }, [showPaginationIndicator, hasMore])

  return {
    data: hookReturn.items as TItem[],
    fetchNextPage: () => {
      if (!hasMore || !hookReturn?.items?.length) return
      hookReturn.getNextPage?.()
    },
    keyExtractor: (item) => item?.id,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    loading: forceLoading || hookReturn.list.query.isLoading,
    ListFooterComponent: ListPaginationIndicator,
    isFetchingNextPage: hookReturn.list.query.isFetchingNextPage,
    ListLoadingIndicatorComponent: () => null,
    hasNextPage: hasMore,
    isLoading: hookReturn.list.query.isLoading,
    placeholder: {
      loading: (listQuery.isFetching || listQuery?.isLoading) && !listQuery?.isRefreshing,
    },
    showPaginationIndicator,
  }
}

type PaginationFlatListProps<TData = any> = Pick<
  ListProps<PropsOf<typeof List>, TData>,
  'onRefresh' | 'data' | 'renderItem' | 'placeholder' |
  'keyExtractor' | 'ListFooterComponent' | 'refreshing' | 'ListLoadingIndicatorComponent'
>

export function getFlatListProps<
  P extends ReturnType<typeof usePagination>
>(pagination: P): PaginationFlatListProps<P['items'][number]> {

  const listQuery = pagination.queries.list

  return {
    ...listQuery,
    data: pagination?.items,
    renderItem: () => null,
    keyExtractor: (item) => pagination.params.keyExtractor(item).toString(),
    refreshing: listQuery.isRefreshing,
    // @ts-ignore
    placeholder: {
      loading: listQuery.isFetching || listQuery?.isLoading || listQuery?.loading || listQuery?.isFetchingNextPage,
      icon: 'placeholderNoItems-select' as IconPlaceholder,
    },
    onRefresh: () => listQuery.refresh(),
    ListLoadingIndicatorComponent: () => {
      const limited = pagination?.items?.length <= pagination?.options?.limit

      if (!pagination?.items?.length || limited) return null

      return <PaginationIndicator
        hasMore={listQuery.hasNextPage}
        noMoreItemsText={`No more ${pagination.itemName}`}
        isFetching={listQuery.isFetchingNextPage}
      />
    },
  }
}

export const SELF_EMPLOYED = 'selfEmployed'

export const formatOrganisationEmployees = (organisation?: Partial<Organisation>) => {
  if (!organisation) return null

  const onlyMinEmployeesKnown = !TypeGuards.isNil(organisation.min_employees) && TypeGuards.isNil(organisation.max_employees)

  const employeesKnown = !TypeGuards.isNil(organisation.min_employees) && !TypeGuards.isNil(organisation.max_employees)

  const selfEmployed = organisation.min_employees === 1 && organisation.max_employees === 1

  if (selfEmployed) return SELF_EMPLOYED

  const employees = onlyMinEmployeesKnown ? (
    `${formatNumberToShort(organisation.min_employees)}+`
  ) : (
    employeesKnown ? `${formatNumberToShort(organisation.min_employees)} - ${formatNumberToShort(organisation.max_employees)}` : null
  )

  return employees
}

export function getListQueryParams(values: string[]) {
  if (TypeGuards.isNil(values) || values?.length <= 0) return undefined
  return values.map(s => String(s)).join(',')
}

const AuthWallButton = () => {
  return (
    <View variants={['marginVertical:2', 'fullWidth', 'center']}>
      <Button
        variants={['outline', 'fullWidth']}
        debugName='Register to see more'
        text={I18N.t('logInToViewMore')}
        onPress={() => AppStatus.setModal('authWall')}
      />
    </View>
  )
}

export const useAuthLimitedFlatlistProps = <
  TItem extends QueryManagerItem,
  T extends QueryManager<TItem, any, any, any>,
>(hookReturn: ReturnType<T['use']>, options?: GetFlatlistPropsOptions) => {
  const flatListProps = useFlatlistProps(hookReturn, options)

  const { profile } = APIClient.Session.useSession()

  const isUnknown = React.useMemo(() => TypeGuards.isNil(profile), [profile])

  if (isUnknown) {
    const showButton = !!flatListProps?.data?.length && !flatListProps.loading

    flatListProps.ListFooterComponent = showButton ? AuthWallButton : null
  }

  return flatListProps
}
