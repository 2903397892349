import { AppStatus } from '@/redux'
import { useSession } from './useSession'
import { ERRORS, isEmailAvailable, Profile } from './views'

import { SocialProviderTypes } from './social'
import { getFirebaseAuth } from '@/services/firebaseApp'
import { QueryKeys } from './queryKeys'
import { analytics } from '@/services/analytics'
import { rdt } from '@/services/reddit'

export function useSignup() {
  const { createProfile, warnEmailInUse } = useSession()

  const signup = async (data: Partial<Profile>, source: SocialProviderTypes | 'email' = 'email') => {
    const firebaseAuth = await getFirebaseAuth()

    AppStatus.set('loading')
    try {
      const emailAvailable = await isEmailAvailable(data.email)
      logger.debug('Email available', emailAvailable, 'Auth')
      let currentUser = firebaseAuth().currentUser
      logger.debug('Firebase currentUser', currentUser, 'Sign Up')

      currentUser?.reload?.()

      logger.debug('Firebase currentUser (after reload)', currentUser, 'Sign Up')

      if (!emailAvailable) {
        warnEmailInUse()
        throw ERRORS.emailInUse
        return
      }

      try {
        if (source === 'email') {
          logger.debug('Creating user with email and password', data, 'Sign Up')
          const newUser = await firebaseAuth().createUserWithEmailAndPassword(data.email, data.password)
          currentUser = newUser.user
        }

        currentUser?.reload?.()

        // if (!currentUser?.emailVerified) {
        //   await sendEmailVerification(currentUser)

        // }

        currentUser?.reload?.()

      } catch (e) {
        logger.error('Error on signup', e, 'Auth')

        if (e.code === 'auth/email-already-in-use') {
          warnEmailInUse()
        }
      }
      logger.debug('Creating profile', data, 'Sign Up')

      const user = await createProfile.mutateAsync(data)

      analytics.track('sign_up', {
        sign_up_source: source,
        email: data.email,
        verification_link: user.email_verification_link,
      })
      rdt('SignUp')
      await QueryKeys.me.refresh()

      AppStatus.set('done')
      analytics.track('login', {
        email: currentUser.email,
      })

      return {
        user: currentUser,
      }
    } catch (e) {
      AppStatus.set('idle')
      logger.error('Error on signup', e, 'Auth')
    }

  }

  return { signup }
}
