import { Theme, themeBreakpoints } from '@/app'
import { useMediaQuery } from '@codeleap/web'

export const useIsMobile = () => {
  const mediaQuery = Theme.media.down('small')

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}

type Breakpoints = keyof typeof themeBreakpoints

export const useMediaQueryDown = (breakpoint: Breakpoints) => {
  const mediaQuery = Theme.media.down(breakpoint)

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}
