import { createForm } from '@codeleap/common'

export const onboardingQuestions = createForm('onboardingQuestions', {
  climate_priority: {
    label: 'Question 1',
    description: 'How important is it to you that you can act on climate at work?',
    type: 'slider',
    min: 0,
    max: 100,
    defaultValue: [0] as any,
  },
  climate_actionability: {
    label: 'Question 2',
    description: 'How able do you feel to act on climate at work?',
    type: 'slider',
    min: 0,
    max: 100,
    defaultValue: [0] as any,
  },
})

