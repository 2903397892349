import { FormTypes, TypeGuards, useCallback, useDefaultComponentStyle, useState } from '@codeleap/common'
import ReactDatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker'
import { View } from '.'
import { ActionIcon, ActionIconProps, Text, TextInput, TextInputProps } from '@codeleap/web'
import { format, getYear } from 'date-fns'
import { CalendarComposition, CalendarHeaderComposition, CalendarPresets } from '@/app/stylesheets/Calendar'
import { useAppI18N } from '@/app'
import { dateLocales } from '@/app/i18n'

export type CalendarOuterInputProps = TextInputProps & {
  valueLabel: FormTypes.Label
}

export type CalendarHeaderComponent = ReactDatePickerCustomHeaderProps & {
  styles?: CalendarHeaderComposition
}

export type CalendarArrowProps = Partial<ActionIconProps>

type CalendarProps = {
  styles?: CalendarComposition
  style?: any
  hideInput?: boolean
  value: Date
  outerInputComponent?: React.ComponentType<Partial<CalendarOuterInputProps>>
  headerComponent?: React.ComponentType<CalendarHeaderComponent>
  formatDate?: (date: Date | string) => FormTypes.Label
  calendarProps?: Omit<Partial<ReactDatePickerProps>, 'customInput' | 'renderCustomHeader'>
  onValueChange: (date: Date) => void
  defaultValue?: Date
} & Omit<TextInputProps, 'defaultValue'>

const OuterInputComponent: CalendarProps['outerInputComponent'] = (props) => {
  const {
    valueLabel,
    focused,
    ...otherProps
  } = props

  return (
    <TextInput
      {...otherProps}
      validate={!focused && otherProps?.validate}
      masking={{
        options: {
          mask: 'xx/xx/xxxx',
          placeholder: otherProps.placeholder,
          formatChars: {
            'x': '[0123456789]',
          },
          maskChar: '',
        },
      }}
    />
  )
}

export const dateformat = 'dd/MM/yyyy'

const NextLabelComponent: React.FC<CalendarArrowProps> = (props) => {
  return (
    <ActionIcon
      name='chevron-right'
      debugName={'Calendar nextLabel'}
      {...props}
    />
  )
}

const PrevLabelComponent: React.FC<CalendarArrowProps> = (props) => {
  return (
    <ActionIcon
      name='chevron-left'
      debugName={'Calendar prevLabel'}
      {...props}
    />
  )
}

const HeaderComponent: CalendarProps['headerComponent'] = (props) => {
  const {
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
    nextMonthButtonDisabled,
    setYearShow,
    styles,
  } = props

  const month = format(date, 'MMMM')
  const year = getYear(date)

  const isMinimumYear = year <= startDate.getFullYear()
  const isMaxYear = year >= maxDate.getFullYear()

  return (
    <View style={styles?.wrapper}>
      <View style={styles?.arrowsWrapper}>
        {/*@ts-ignore*/}
        <PrevLabelComponent styles={styles?.prevArrow} onPress={decreaseYear} disabled={prevYearButtonDisabled || isMinimumYear} />
        <Text onPress={() => setYearShow(curr => !curr)} style={styles?.title} text={month + ' ' + year} />
        {/*@ts-ignore*/}
        <NextLabelComponent styles={styles?.nextArrow} onPress={increaseYear} disabled={nextYearButtonDisabled || isMaxYear} />
      </View>

      <View style={styles?.arrowsWrapper}>
        {/*@ts-ignore*/}
        <PrevLabelComponent styles={styles?.prevArrow} onPress={decreaseMonth} disabled={prevMonthButtonDisabled || isMinimumYear} />
        {/*@ts-ignore*/}
        <NextLabelComponent styles={styles?.nextArrow} onPress={increaseMonth} disabled={nextMonthButtonDisabled || isMaxYear} />
      </View>
    </View>
  )
}

const defaultProps = {
  outerInputComponent: OuterInputComponent,
  headerComponent: HeaderComponent,
}

const minDate = new Date(1910, 0, 1)
const startDate = new Date(1922, 0, 1) // keep it while we dont have a better solution
const maxDate = new Date()

const nYears = maxDate.getFullYear() - minDate.getFullYear()

export const Calendar: React.FC<CalendarProps> = (props) => {
  const allProps = {
    ...defaultProps,
    ...props,
  }

  const {
    hideInput,
    value,
    onValueChange,
    variants = [],
    styles = {},
    style,
    responsiveVariants,
    defaultValue,
    outerInputComponent,
    headerComponent,
    disabled,
    calendarProps,
    ...otherProps
  } = allProps
  const [visible, setVisible] = useState(false)
  const [yearShow, setYearShow] = useState(false)
  const variantStyles = useDefaultComponentStyle<'u:Calendar', typeof CalendarPresets>('u:Calendar', {
    variants,
    responsiveVariants,
    styles,
  })

  const DayContentComponent = useCallback((param) => {
    const { day, date: _date } = param

    let selected = false
    const date = format(new Date(_date), 'eee MMM dd yyyy')
    const dateValue = value ? format(new Date(value), 'eee MMM dd yyyy') : ''

    if (date === dateValue) {
      selected = true
    }

    return (
      <View style={[variantStyles.dayWrapper, selected && variantStyles['dayWrapper:selected']]}>
        <Text style={[variantStyles.day, selected && variantStyles['day:selected']]} text={day} />
      </View>
    )
  }, [value])

  const YearContentComponent = useCallback((param) => {
    const { year } = param

    const selected = String(value)?.includes(year)

    return (
      <View style={[variantStyles.yearWrapper, selected && variantStyles['yearWrapper:selected']]}>
        <Text style={[variantStyles.year, selected && variantStyles['year:selected']]} text={year} />
      </View>
    )
  }, [value])

  const OuterInput = outerInputComponent
  const Header = headerComponent
  const DayContent = DayContentComponent

  const { locale } = useAppI18N()

  return (
    <View style={[variantStyles.wrapper, style]}>
      <ReactDatePicker
        onChange={onValueChange}
        open={visible}
        selected={value}
        todayButton={null}
        shouldCloseOnSelect={false}
        openToDate={defaultValue ?? value}
        dateFormat='dd/MM/yyyy'
        formatWeekDay={(t) => t[0]}
        calendarStartDay={1}
        placeholderText={otherProps?.placeholder}
        disabled={disabled}
        locale={dateLocales.en}
        renderDayContents={(day, date) => <DayContent day={day} date={date} />}
        customInput={!hideInput ? <OuterInput {...otherProps} styles={variantStyles.input} focused={visible} /> : null}
        renderCustomHeader={(headerProps) => <Header styles={variantStyles.header} {...headerProps} setYearShow={setYearShow} prevYearButtonDisabled={yearShow} nextYearButtonDisabled={yearShow} />}
        onFocus={() => setVisible(true)}
        onSelect={() => yearShow ? setYearShow(false) : setVisible(false)}
        onClickOutside={() => {
          setVisible(false)
          setYearShow(false)
        }}
        minDate={minDate}
        maxDate={maxDate}
        yearItemNumber={nYears}
        startDate={startDate}
        endDate={maxDate}
        {...calendarProps}
        onYearChange={() => setYearShow(false)}
        showYearPicker={yearShow}
        filterDate={(date) => {
          if (date.getFullYear() < startDate.getFullYear() || date.getFullYear() > maxDate.getFullYear()) return false
          return true
        }}
        renderYearContent={(year) => {
          if (year < startDate.getFullYear() || year > maxDate.getFullYear()) return null
          return <YearContentComponent year={year} />
        }}
      />
    </View>
  )
}
