import { api } from '@/app'
import { PaginationResponse, QueryManager, TypeGuards } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { QuizChoice, QuizQuestion } from '@/types'

const BASE_URL = 'learn/questions/'

export const sendAnswer = async (question: QuizQuestion['id'], choice: QuizChoice['id']) => {
  const response = await api.post('learn/answers/', {
    question,
    choice,
  })

  return response.data
}

export const quizQuestionsManager = new QueryManager({
  itemType: {} as QuizQuestion,
  name: 'quizQuestions',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<QuizQuestion>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<QuizQuestion>(`${BASE_URL}${id}/`)

    return response.data
  },
})

type UseQuizQuestions = {
  questionId: QuizQuestion['id']
}

export const useQuizQuestion = ({ questionId }: UseQuizQuestions) => {
  if (TypeGuards.isNil(questionId)) return null
  
  const question = quizQuestionsManager.useItem({
    id: questionId,
  })

  return question?.data
}
