import { AppImages, React, variantProvider } from '@/app'
import { Modal, Image, View, ActionIcon, Text, Button } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { useIsMobile } from '@/utils'
import { AnyFunction } from '@codeleap/common'
import { navigate } from 'gatsby'

const Header = React.memo(({ toggle, isMobile }: { toggle: AnyFunction; isMobile: boolean }) => (
  <View variants={['column', 'justifyCenter', 'bg:primary1', 'relative', 'alignCenter', isMobile && 'paddingTop:7']}>
    <ActionIcon
      debugName={'closeButton'}
      name={'x'}
      variants={['minimal', 'primary3', 'alignSelfEnd', 'absolute', 'iconSize:3']}
      onPress={toggle}
      style={styles.actionIcon}
    />
    <Image source={AppImages.SignInNewsFeed} objectFit='contain' css={{ width: '100%' }} />
  </View>
))

type SignInNewsFeedProps = {
  visible: boolean
  toggle: AnyFunction
}

const title = 'You need to sign in to see this page'
const description = 'To access this content, you must sign in to your account. Signing in ensures a personalized experience and secure access to your data.'

export function SignInNewsFeed() {
  const visible = useAppSelector((state) => state.AppStatus.modals.signInNewsFeed)
  const toggle = () => AppStatus.setModal('signInNewsFeed')
  const isMobile = useIsMobile()

  return (
    <Modal
      toggle={toggle}
      showClose={false}
      visible={visible}
      renderHeader={() => <Header toggle={toggle} isMobile={isMobile} />}
      variants={[isMobile && 'fullscreen', 'centered']}
      styles={{ box: styles.modalBox, body: styles.modalBody }}
    >
      <View variants={['column', 'gap:2', 'padding:4']}>
        <Text variants={['h2']} text={title} />
        <Text variants={['p1']} text={description} />
        <Button debugName='next-btn' variants={['marginTop:2']} text='Next' onPress={() => navigate('/auth/signup')} />
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modalBox: {
      ...theme.spacing.padding(0),
      // @ts-ignore
      borderRadius: theme.borderRadius.large,
      width: MODAL_WIDTH,
      maxWidth: `calc(100vw - ${theme.spacing.value(4)}px)`,

      [theme.media.down('small')]: {
        position: 'fixed',
        overflowY: 'auto',
        ...theme.presets.fullWidth,
        height: '100%',
        borderRadius: 0,
        maxWidth: `100vw`,
      },
    },
    modalBody: {
      [theme.media.down('small')]: {
        height: '100%',
        maxHeight: 'none',
      },
    },
    actionIcon: {
      top: 24,
      right: 24,
    },
  }),
  true,
)
