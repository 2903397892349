import { MutableRefObject, useRef, useState } from 'react'
import type ReactPlayer from 'react-player/lazy'
import type { ReactPlayerProps } from 'react-player/lazy'
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import { AnyFunction, onUpdate } from '@codeleap/common'

const DEFAULT_CONTROLS: ReactPlayerProps = {
  pip: false,
  playing: true,
  controls: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  seeking: false,
}

type UseControlsProps = {
  initialControls: ReactPlayerProps
  playerRef: MutableRefObject<ReactPlayer>
  fullscreenElement: MutableRefObject<any>
  onFullscreenChange: (fullscreenState: boolean) => void
}

export const useControls = ({
  playerRef,
  fullscreenElement,
  initialControls,
  onFullscreenChange,
}: UseControlsProps) => {
  const [controls, setControls] = useState<ReactPlayerProps>({
    ...DEFAULT_CONTROLS,
    ...initialControls,
  })
  const { seeking } = controls

  onUpdate(() => {
    setControls((prevState) => ({ ...prevState, ...initialControls }))
  }, [initialControls])

  const handlePlayPause = () => {
    setControls((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }))
  }

  const handleVolumeChange = (e) => {
    setControls((prevState) => ({
      ...prevState,
      volume: e[0],
    }))
  }

  const handleOnPlaybackRateChange = (speed) => {
    setControls((prevState) => ({
      ...prevState,
      playbackRate: parseFloat(speed),
    }))
  }

  const togglePIP = () => {
    setControls((prevState) => ({
      ...prevState,
      pip: !prevState.pip,
    }))
  }

  const play = () => {
    setControls((prevState) => ({
      ...prevState,
      playing: true,
    }))
  }

  const handleEnablePIP = () => {
    setControls((prevState) => ({
      ...prevState,
      pip: true,
    }))
  }

  const handleDisablePIP = () => {
    setControls((prevState) => ({
      ...prevState,
      pip: false,
    }))
  }

  const pause = () => {
    setControls((prevState) => ({
      ...prevState,
      playing: false,
    }))
  }

  const handleSeekMouseDown = () => {
    setControls((prevState) => ({
      ...prevState,
      seeking: true,
    }))
  }

  const handleSeekChange = (e) => {
    setControls((prevState) => ({
      ...prevState,
      played: e[0],
    }))
  }

  const handleSeekMouseUp = () => {
    setControls((prevState) => {
      playerRef?.current?.seekTo(prevState.played)

      return {
        ...prevState,
        seeking: false,
      }
    })
  }

  const handleProgress = (controls) => {
    if (!seeking) {
      setControls((prevState) => ({
        ...prevState,
        ...controls,
      }))
    }
  }

  const handleEnded = () => {
    setControls((prevState) => ({
      ...prevState,
      playing: controls.loop,
    }))
  }

  const handleDuration = (duration) => {
    setControls((prevState) => ({
      ...prevState,
      duration,
    }))
  }

  const toggleFullscreen = () => {
    if (screenfull.isFullscreen) {
      screenfull.exit()
      onFullscreenChange(false)
      pause()
    } else {
      // @ts-ignore
      screenfull.request(findDOMNode(fullscreenElement.current))
      onFullscreenChange(true)
    }
  }

  const actions = {
    handlePlayPause,
    toggleFullscreen,
    handleDuration,
    handleEnded,
    handleProgress,
    handleSeekChange,
    handleSeekMouseUp,
    handleSeekMouseDown,
    pause,
    handleDisablePIP,
    handleVolumeChange,
    handleEnablePIP,
    togglePIP,
    handleOnPlaybackRateChange,
    play,
  }

  return {
    controls,
    isFullscreen: screenfull.isFullscreen,
    actions,
  }
}
