import React from 'react'
import { ActivityIndicator, View } from '@/components'

const LoadingScreenCP = ({ type = 'screen', transparent = false }) => {
  const isScreen = type === 'screen'

  return (
    <View 
      variants={[isScreen && 'fullViewport', !transparent && 'backgroundColor:neutral1', 'center']}
      css={[isScreen ? {} : { height: '50svh' }]}
    >
      <View variants={['padding:1', 'border-radius:rounded', 'backgroundColor:neutral1']}>
        <ActivityIndicator debugName='loadingScreen:indicator' />
      </View>
    </View>
  )
}

export const LoadingScreen = React.memo(LoadingScreenCP, () => true)
