import { useAppSelector } from '@/redux'
import { TypeGuards } from '@codeleap/common'
import { MutableRefObject, useEffect, useRef, useState } from 'react'

type UseModalOptions = {}

export const useModal = (options: UseModalOptions = {}) => {
  const [visible, setVisible] = useState(false)

  const toggle = (to = null) => {
    const _visible = TypeGuards.isBoolean(to) ? to : !visible

    setVisible(_visible)
  }

  return {
    visible,
    toggle,
  }
}

type UseElementScroll = {
  offsetY?: number
  ref: MutableRefObject<any>
}

export const useElementScroll = (): UseElementScroll => {
  const elementRef = useRef(null)
  const [scrollProps, setScrollProps] = useState({})

  const handleScroll = () => {
    if (elementRef.current) {
      const _scroll = {
        offsetY: elementRef.current?.scrollTop,
      }

      setScrollProps(_scroll)
    }
  }

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [elementRef])

  return {
    ref: elementRef,
    ...scrollProps,
  }
}

export const useScroll = () => {
  const [isScrollAtTop, setIsScrollAtTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window?.pageYOffset ?? document?.documentElement?.scrollTop
      setIsScrollAtTop(scrollTop === 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return {
    atTop: isScrollAtTop,
  }
}

export function useAppStatus() {
  const status = useAppSelector(state => state.AppStatus.status)

  const isVisible = status === 'loading' || status === 'done'

  return {
    status,
    isVisible,
  }
}
