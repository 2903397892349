import React from 'react'
import { AnyFunction } from '@codeleap/common'
import { Modal, Player } from '@/components'

type PlayerFullscreenProps = {
  visible: boolean
  toggle: AnyFunction
  url: string
}

export const PlayerFullscreen = (props: PlayerFullscreenProps) => {
  const {
    visible,
    toggle,
    url,
  } = props

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      closable={false}
      variants={['fullscreen', 'center', 'player']}
      debugName='Modal fullscreen player'
      showClose={false}
    >
      <Player
        url={url}
        paused={!visible}
        toggleFullscreen={toggle}
      />
    </Modal>
  )
}
