import { variantProvider } from '@/app'
import { ImageProps } from './Image'
import { View, Image } from '@/components'

type PageWrapperProps = {
  children: React.ReactNode
  backgroundColor?: string
  imageSize?: number
  image?: ImageProps['source']
}

export const TopImageWrapper = (props: PageWrapperProps) => {
  const {
    children,
    backgroundColor,
    imageSize = 95,
    image,
  } = props

  return (
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        <View variants={['paddingVertical:5']} style={[styles.imagesWrapper, { backgroundColor: backgroundColor }]}>
          <Image source={image} style={{ width: imageSize }} objectFit='contain' />
        </View>
        <View variants={['padding:4', 'justifyCenter']}>
          {children}
        </View>
      </View>
    </View>
  )
}

const MAX_WIDTH = 420
const MAX_HEIGHT = 220
const BREAKPOINT = 'small'

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.media.down(BREAKPOINT)]: {
      height: '100%',
    },
  },
  innerWrapper: {
    maxWidth: MAX_WIDTH,
    width: '100%',
    borderRadius: theme.borderRadius.large,
    ...theme.border.neutral3(theme.values.borderWidth.small),
    flexDirection: 'column',
    [theme.media.down(BREAKPOINT)]: {
      maxWidth: '100%',
      border: 'none',
      borderRadius: 0,
    },
  },
  icon: {
    color: theme.colors.neutral5,
  },
  imagesWrapper: {
    width: '100%',
    maxHeight: MAX_HEIGHT,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: theme.borderRadius.large,
    borderTopRightRadius: theme.borderRadius.large,
    [theme.media.down(BREAKPOINT)]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      maxHeight: 240,
      width: '100vw',
      height: '100vh',
    },
  },
}), true)
