import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { getFirebase } from '@/services/firebaseApp'
import { useWindowFocus } from '@/utils'
import { onUpdate, useState } from '@codeleap/common'
import { requestEmailVerification } from './views'

export function useVerifyEmail() {
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  async function resendEmailConfirmation() {

    AppStatus.set('loading')

    try {
      await requestEmailVerification()
    } catch (e) {
      logger.error(e)
    }

    AppStatus.set('done')
  }

  const windowFocused = useWindowFocus()

  const checkEmail = async () => {
    const firebaseAuth = (await getFirebase()).auth
    await firebaseAuth().currentUser?.reload()

    const user = firebaseAuth().currentUser

    if (user?.emailVerified) {
      setIsEmailVerified(true)
    }

  }

  onUpdate(() => {
    if (isEmailVerified) return

    if (windowFocused) {
      checkEmail()
    }
  }, [windowFocused])

  onUpdate(() => {
    if (isEmailVerified) return

    const intervalId = setInterval(() => {
      checkEmail()
    }, 2000)

    return () => {
      clearInterval(intervalId)
    }
  }, [isEmailVerified])

  const cancelVerification = async () => {
    const firebaseAuth = (await getFirebase()).auth

    await APIClient.Session.deleteProfile(firebaseAuth().currentUser?.uid)
    firebaseAuth().currentUser?.delete()
  }

  return {
    resendEmailConfirmation,
    checkEmail,
    isEmailVerified,
    cancelVerification,
  }
}
