import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, Script } from 'gatsby'
import favicon from '../images/favicon.png'
import { Settings as _Settings, GATSBY_DEV_ENV, IS_SSR } from '@/app'

export type SEOProps = {
  title: string
  description: string
  image: string
  pathname: string
  article: string
  author: string
  doNotTrack: boolean
}

const Settings = {
  site_name: 'Hurd',
  application_name: 'Hurd',
  revisit_after: '15 days',
  image_logo: {
    alt: 'Hurd Logo',
    width: '256',
    height: '256',
  },
  static_infos: {
    locality: 'London, GB',
    country_name: 'GB',
    copyright: 'Kite Insights Limited',
    classification: 'Business',
    email: 'support@hurd.world',
  },
}

const SEO: React.FC<Partial<SEOProps>> = ({ title, description, image, pathname, article, author, doNotTrack }) => {
  const data = useStaticQuery(query).site.siteMetadata

  const seo = {
    title: title ?? data.defaultTitle,
    description: description ?? data.defaultDescription,
    image: `${data.siteUrl}${image ?? data.defaultImage}`,
    url: `${data.siteUrl}${pathname ?? (IS_SSR ? '' : window.location.pathname)}`,
    doNotTrack: GATSBY_DEV_ENV,
    type: article ? 'article' : 'website',
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={data.titleTemplate}
      link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
    >
      <html lang='en' />
      <meta charSet='utf-8' />
      <body className='root' />
      {_Settings.EnableAnalytics && (
        <Script strategy='idle'>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KZHZ4GSF');
          `}</Script>
      )}

      {_Settings.EnableAnalytics && (
        <Script strategy='idle'>
          {`(function(c,l,a,r,i,t,y){
               c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "jrfxpipsdm");
          `}
        </Script>
      )}

      {
        _Settings.EnableAnalytics && (
          <Script strategy='idle'>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K557QPHC');
          `}</Script>
        )
      }

      {_Settings.EnableAnalytics && (
        // <!-- Reddit Pixel -->
        <Script strategy='idle'>
          {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};
            p.callQueue=[];
            var t=d.createElement("script");
            t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;
            var s=d.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(t,s)}}(window,document);
            rdt('init','a2_edj3isncjzg0');
            rdt('track', 'PageVisit');
          `}
        </Script>
        // rdt('init','a2_edj3isncjzg0',  {"email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>"});
        // <!-- DO NOT MODIFY UNLESS TO REPLACE A USER IDENTIFIER -->
        // <!-- End Reddit Pixel -->
      )}

      <meta name='application-name' content={Settings.application_name} />
      <meta name='revisit-after' content={Settings.revisit_after} />
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta httpEquiv='expires' content='43200' />

      {/* <meta name='apple-itunes-app' content={`app-id=${_Settings.AppId}`}/> */}

      {/* TODO i18n props */}
      <meta property='og:locale' content='en_GB' />
      <meta name='language' content='English' />
      <meta name='coverage' content='Worldwide' />

      <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
      <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no' />

      <link rel='icon' type='image/png' href='/images/favicon.png' />
      <link rel='apple-touch-icon' type='image/png' href='/images/favicon.png' />

      <link
        rel='stylesheet'
        type='text/css'
        charSet='UTF-8'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
      />
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
      />

      {/* SEO */}
      <meta name='robots' content={seo.doNotTrack ? 'noindex,nofollow' : 'index,follow'} />
      <meta name='description' content={seo.description} />
      <meta name='identifier-URL' content={seo.url} />
      <meta name='image' content={seo.image} />
      <link rel='canonical' href={seo.url} />

      <meta name='og:site_name' content={Settings.site_name} />
      <meta property='title' content={seo.title} />
      <meta property='pagename' content={seo.title} />
      <meta property='og:title' content={seo.title} />
      <meta property='og:type' content={seo.type} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:url' content={seo.url} />

      {/* Blog articles */}

      {/* Social */}
      <meta name='twitter:card' content='summary_large_image' />
      {data.twitterUsername && <meta name='twitter:creator' content={data.twitterUsername} />}
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />

      {/* Additional static SEO */}
      <meta name='og:locality' content={Settings.static_infos.locality} />
      <meta name='og:country-name' content={Settings.static_infos.country_name} />
      <meta name='copyright' content={Settings.static_infos.copyright} />
      <meta name='Classification' content={Settings.static_infos.classification} />
      <meta name='og:email' content={Settings.static_infos.email} />
      <meta name='theme-color' content='#000' />
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        
        twitterUsername
      }
    }
  }
`
