import React from 'react'
import { PropsOf } from '@codeleap/common'
import { Image, View, Text } from '@/components'
import { variantProvider } from '@/app'
import { useIsMobile } from '@/utils'

type PageProps = {
  title: string
  text: string
  button: string
  image: PropsOf<typeof Image>['source']
}

export const Page = (props: PageProps) => {
  const {
    title,
    text,
    image,
  } = props

  const isMobile = useIsMobile()

  return (
    <View 
      variants={['center', 'column', 'textCenter', 'gap:4']}
    >
      <View style={styles.imageWrapper}>
        <Image source={image} objectFit={isMobile ? 'cover' : 'contain'} style={styles.image} />
      </View>

      <View 
        variants={['paddingHorizontal:4', 'gap:2', 'column', 'center', 'fullWidth', 'flex']}
        responsiveVariants={{
          small: ['paddingHorizontal:2', 'gap:1']
        }}
      >
        <Text variants={['h2']} text={title} />

        <Text variants={['p1']} text={text} />
      </View>
    </View>
  )
}

const IMAGE_HEIGHT = 350

const styles = variantProvider.createComponentStyle(theme => ({
  imageWrapper: {
    background: theme.colors.primary1,
    height: IMAGE_HEIGHT,
    ...theme.presets.fullWidth,
    ...theme.presets.center,
  },
  image: {
    width: '100%',
    height: IMAGE_HEIGHT,

    [theme.media.down('small')]: {
      width: '100vw',
    }
  },
  close: {
    ...theme.presets.absolute,
    top: theme.spacing.value(4),
    right: theme.spacing.value(4),
    zIndex: 1,

    [theme.media.down('small')]: {
      top: theme.spacing.value(2),
      right: theme.spacing.value(2),
    }
  }
}), true)
