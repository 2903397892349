import { AchievementLevel } from '@/types'
import { View, Text, Icon } from '@/components'
import { Theme } from '@/app'

type LevelTagProps = {
  level: AchievementLevel
  variant?: 'large' | 'small'
}

export const LevelTag = (props: LevelTagProps) => {
  const { level, variant = 'small' } = props

  if (!level?.id) return null

  const icon = `achievement-${level?.tag_image}`

  const { iconSize, iconWrapperSize, textHierarchy, padding, gap } = LevelTag?.sizes?.[variant]

  return (
    <View 
      variants={['backgroundColor:neutral1', 'border-radius:small', 'center', 'justifySpaceBetween']}
      style={{ padding, gap }}
    >
      <View 
        variants={['border-radius:tiny', 'center']} 
        style={{ backgroundColor: level?.bg_color_light, width: iconWrapperSize, height: iconWrapperSize }}
      >
        <Icon 
          debugName='achievementLevel:icon' 
          name={icon} 
          color={level?.bg_color} 
          size={iconSize}
        />
      </View>

      <Text variants={[textHierarchy as any]} text={level?.name} />
    </View>
  )
}

LevelTag.sizes = {
  large: {
    iconSize: 16,
    iconWrapperSize: Theme.values.iconSize[4],
    textHierarchy: 'h2',
    padding: Theme.spacing.value(2),
    gap: Theme.spacing.value(2),
  },
  small: {
    iconSize: 16,
    iconWrapperSize: Theme.values.iconSize[4],
    textHierarchy: 'h4',
    padding: Theme.spacing.value(1.5),
    gap: Theme.spacing.value(1),
  }
}
