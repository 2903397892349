import { useIsomorphicEffect } from '@codeleap/common';
import { useEffect, useState } from 'react'

export const useWindowFocus = () => {
  const [focused, setFocused] = useState(true)

  useEffect(() => {
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  return focused
}

export function useWindowSize() {
  const [size, setSize] = useState([])

  function handleResize() {
    setSize([window.innerWidth, window.innerHeight])
  }

  useIsomorphicEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}
