import { React } from '@/app'
import { View, CenterWrapper, Logo, Link, Navigation, AuthModals, Onboarding, ProfileRedirectModal, AuthWall, UseAppBanner } from '@/components'
import { SuccessModal } from '../Modals/SuccessModal'
import { concatStyles, useMediaQueryDown, useModal } from '@/utils'
import { DrawerMenu } from './Drawer'
import { BASE_SITE_URL, BREAKPOINT, HeaderItems } from './Items'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { HeaderStyles, HeaderComposition } from '../../app/stylesheets/Header'

type HeaderProps = ComponentVariants<typeof HeaderStyles> & {
  styles?: StylesOf<HeaderComposition>
}

const HeaderComponent = (props: HeaderProps) => {
  const { responsiveVariants, styles, variants } = props

  const menu = useModal()
  const isMobile = useMediaQueryDown(BREAKPOINT)

  const variantStyles = useDefaultComponentStyle<'u:Header', typeof HeaderStyles>('u:Header', {
    responsiveVariants,
    styles,
    variants,
  })

  const headerStyles = React.useMemo(() => ({
    'wrapper': concatStyles([
      variantStyles?.wrapper,
      menu?.visible && variantStyles?.['wrapper:open'],
    ]),
    'wrapperFloating': concatStyles([
      variantStyles?.wrapperFloating,
      menu?.visible && variantStyles?.['wrapperFloating:open'],
    ]),
  }), [menu?.visible])

  return <>
    <Navigation.Container>
      <CenterWrapper
        styles={{
          innerWrapper: headerStyles.wrapper,
          wrapper: headerStyles.wrapperFloating,
        }}
      >
        <Link to={BASE_SITE_URL} css={variantStyles.logoWrapper}>
          <Logo
            debugName='header logo'
            style={variantStyles.logo}
            breakpoint={BREAKPOINT}
            mobile={false}
          />
        </Link>

        {
          isMobile ? (
            <DrawerMenu
              variantStyles={variantStyles}
              {...menu}
            />
          ) : (
            <View variants={['alignCenter']}>
              <HeaderItems variantStyles={variantStyles} />
            </View>
          )
        }
      </CenterWrapper>
      <UseAppBanner/>
    </Navigation.Container>

    <AuthModals />
    <SuccessModal />
    <Onboarding />
    <ProfileRedirectModal />
    <AuthWall />

  </>
}

export const Header = React.memo(HeaderComponent, () => true)
