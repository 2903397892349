import { Theme } from '@/app'
import { View, Text, HTMLRender, Image } from '@/components'
import { PropsOf, useI18N } from '@codeleap/common'
import { ViewProps } from '@codeleap/web'

type OrganisationDetailsProps = {
  title?: string
  text: string
  image?: PropsOf<typeof Image>['source']
  wrapperless?: boolean
} & Partial<ViewProps<'div'>>

export const OrganisationDetails = (props: OrganisationDetailsProps) => {
  const { t } = useI18N()

  if (!props?.text?.trim?.()) return null

  const { text, wrapperless = false } = props

  if (wrapperless) {
    return (
      <HTMLRender variants={['fullHeight']} html={text} />
    )
  }

  return (
    <View style={Theme.effects.light} variants={['border-radius:large', 'padding:4', 'column', 'gap:2']}>
      <Text variants={['h3', 'color:neutral9']}>{t('organisations.informationTitle')}</Text>

      <HTMLRender html={text} />
    </View>
  )
}
