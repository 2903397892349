import { queryClient } from '../queryClient'
import { Profile } from './views'

export const QueryKeys = {
  me: queryClient.queryKey<Profile>(['me']),
  options: queryClient.queryKey(['options']),
  profanityWords: queryClient.queryKey<string[]>(['profanityWords']),
  create: queryClient.queryKey(['createProfile']),
  update: queryClient.queryKey(['updateProfile']),
}
