import { Settings, GATSBY_DEV_ENV } from '../app/Settings'
import { LoggerAnalytics, useEffect, useRef } from '@codeleap/common'

import { getFirebase } from './firebaseApp'
import { QueryKeys } from './api/session/queryKeys'
import { Chapter, Module, Organisation, OverallScoreRank, Profile } from '@/types'
import { IS_SSR } from '../app/constants'
import { AnalyticsBrowser } from '@segment/analytics-next'

const enabled = Settings.EnableAnalytics && !IS_SSR

type User = Profile & {
  badges?: string
  organisationRank?: OverallScoreRank
  score?: Organisation['score']
  utm?: string
}

export const analytics = AnalyticsBrowser.load({
  writeKey: GATSBY_DEV_ENV || Settings.Environment.IsDev ? Settings.ApiCredentials.Segment.DEV : Settings.ApiCredentials.Segment.PROD,
})
const server = (GATSBY_DEV_ENV || Settings.Environment.IsDev) ? 'development' : 'production'

if (analytics) {
  const originalTrack = analytics.track
  analytics.track = (event, ...args) => {
    event += '_w'
    args[0] = {
      ...(args?.[0] || {}),
      server,
    }
    return originalTrack.apply(this, [event, ...args])
  }
  const originalIdentify = analytics.identify
  analytics.identify = ({ user, isLoggedIn }: { user: User; isLoggedIn: boolean }) => {

    return originalIdentify.apply(this, [user?.id, {
      id: user?.id,
      email: user?.email,
      address: {
        country: user?.country,
      },
      company: {
        name: user?.organization?.name,
        id: user?.organization?.id,
        employee_count: user?.organization?.max_employees,
        industry: user?.organization?.category?.label,
      },
      badges: user?.badges,
      gender: user?.gender,
      username: user?.display_name,
      name: user?.name,
      advertising_source: user?.inbound_source,
      climate_priority: user?.climate_priority,
      date_of_birth: user?.birthday,
      function: user?.department,
      seniority: user?.seniority,
      logged_in: isLoggedIn,
      server,
      climate_empowerment_score: user?.organisationRank?.label,
      climate_empowerment_number: user?.score?.overall,
      head_score: user?.score?.categories[1]?.total,
      heart_score: user?.score?.categories[2]?.total,
      hands_Score: user?.score?.categories[3]?.total,
      initial_utm: user?.utm,
    }])
  }
}

export const AppAnalytics = new LoggerAnalytics.Analytics({
  init: async () => {
    if (!enabled) return
    const fb = await getFirebase()

    const analytics = fb.analytics()

    analytics.setAnalyticsCollectionEnabled(true)

    analytics.logEvent('page_view', {})

  },
  onEvent: async (eventArgs) => {
    if (!enabled) return
    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.logEvent('app_action', eventArgs)

  },
  prepareData: () => {
    const me = QueryKeys.me.getData()

    getFirebase().then(fb => {
      fb.analytics().setUserId(me?.id ?? null, { global: true })
    })

    if (!enabled || !me) return

    const extraData = {
      user: me.id,
    }

    return extraData
  },
  onInteraction: async (eventArgs) => {
    if (!enabled) return
    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.logEvent('user_interaction', eventArgs)

  },
}, Settings)

function viewOrganisation(organisation: Organisation = null) {
  if (!organisation) return false
  AppAnalytics.event('view_organisation', {
    organisation_id: organisation.id,
    organisation_name: organisation.name,
  })
  return true
}

function pressContribute() {
  AppAnalytics.event('press_contribute', {})
  return true
}

function viewChapter(chapter: Module = null) {
  if (!chapter) return false
  AppAnalytics.event('view_collection', {
    chapter_id: chapter.id,
    chapter_name: chapter.title,
  })
  return true
}

function viewLesson(lesson: Chapter = null) {
  if (!lesson) return false
  AppAnalytics.event('view_lesson', {
    lesson_id: lesson.id,
    lesson_name: lesson.title,
  })
  return true
}
export type SharePayload = {
  type: 'organisation' | 'review' | 'successStory' | 'collection' | 'learnProgress'
  id: string | number
  name?: string
}

function share(payload: SharePayload) {
  if (!payload?.name) return false
  const eventType = `share_${payload.type}`
  AppAnalytics.event(eventType, {
    [`${payload.type}_id`]: payload.id,
    [`${payload.type}`]: payload.name,
  })
  return true
}

const analyticsEvents = {
  viewOrganisation,
  pressContribute,
  viewChapter,
  viewLesson,
  share,
} as const

type AnalyticsEvent = keyof typeof analyticsEvents

function useAnalyticsEvent<K extends AnalyticsEvent>(name: K, args: Parameters<typeof analyticsEvents[K]>) {
  const sent = useRef(false)
  useEffect(() => {
    if (sent.current) return

    const e = analyticsEvents[name]

    sent.current = e(...args)
  }, [name, ...args])
}

export const AnalyticsEvents = {
  ...analyticsEvents,
  use: useAnalyticsEvent,
}

