import { Alert } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { useI18N } from '@codeleap/common'

export const NoReviewQuestionsModal = () => {
  const visible = useAppSelector(state => state?.AppStatus.modals?.noReviewQuestions)
  const { t } = useI18N()

  return (
    <Alert
      visible={visible}
      type='info'
      title={t('pulseQuestion.noReview.title')}
      body={t('pulseQuestion.noReview.body')}
      toggle={() => AppStatus.setModal('noReviewQuestions')}
      debugName={'pulseQuestion:noMore'}
    />
  )
}
