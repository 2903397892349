import { api } from '@/app'
import { PaginationResponse, QueryManager, TypeGuards } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { Chapter, Page } from '@/types'

const BASE_URL = 'learn/pages/'

export const pagesManager = new QueryManager({
  itemType: {} as Page,
  name: 'pages',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Page>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Page>(`${BASE_URL}${id}/`)

    return response.data
  },
})

type UsePages = {
  chapterId: Chapter['id']
}

export const usePages = ({ chapterId }: UsePages) => {
  const pages = pagesManager.use({
    filter: {
      chapter: chapterId,
    }
  })

  let items = pages?.items

  if (TypeGuards.isArray(items)) items?.unshift?.({ id: null } as any)

  return {
    pagesQuery: pages,
    pages: pages?.items
  }
}
