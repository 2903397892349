import React, { useCallback, useState } from 'react'
import { variantProvider, AppForms, I18N, Settings, AppImages } from '@/app'
import {
  Text,
  Image as ImageComponent,
  View,
  TextInput,
  Button,
  WorkingAt,
  Icon,
  Touchable,
  ActionIcon,
  Modal,
  Select,
  ImageProps,
  Logo,
  CropPicker,
  Link,
} from '@/components'
import { APIClient } from '@/services'
import { useFileInput } from '@codeleap/web'
import { useForm, PropsOf } from '@codeleap/common'
import { AppStatus, useAppSelector } from '@/redux'
import { useIsMobile, useRemount, useHandleOpen, existsYouTubeId } from '@/utils'
import { analytics } from '@/services'

type PictureProps = {
  image?: ImageProps['source']
} & PropsOf<typeof Touchable>

const INTERNAL_SPACING = {
  default: 4,
  small: 2,
}

const ModalHeader = (props) => {

  const isMobile = useIsMobile()

  return (
    <View
      variants={['column', `padding:${INTERNAL_SPACING.default}`]}
      responsiveVariants={{ small: [`padding:${INTERNAL_SPACING.small}`] }}
    >
      <View
        variants={['alignCenter', 'fullWidth', 'justifySpaceBetween']}
        responsiveVariants={{ small: ['marginBottom:2'] }}
      >
        {isMobile ? (
          <Logo
            variants={['small']}
            debugName='createStory:logo'
            image={AppImages.Logo}
          />
        ) : null}
        {!isMobile ? <WorkingAt /> : null}
        <ActionIcon
          debugName='Close Auth modal'
          name={'x'}
          variants={['minimal', 'neutral5', 'iconSize:3']}
          onPress={props.toggle}
        />
      </View>
      {isMobile ? <WorkingAt /> : null}
      <View
        variants={['column']}
        responsiveVariants={{ small: ['marginTop:1.5'] }}
      >
        <Text variants={['h1']} text={'Add a success story'} />
      </View>
    </View>
  )
}

const IMAGE_HEIGHT = 320

const Picture = (props: PictureProps) => {
  const { image, ...touchableProps } = props

  return (
    <Touchable
      {...touchableProps}
      debugName={'Create Story Picture'}
      variants={[
        'relative',
        'bg:primary1',
        'border-radius:medium',
        'marginBottom:2',
        'fullWidth',
      ]}
      css={styles.picture}
    >
      {!!image && (
        <ImageComponent
          source={image}
          objectFit='contain'
          variants={['fullWidth', 'fullHeight', 'border-radius:medium']}
        />
      )}
      {!image && (
        <View
          css={styles.picture}
          variants={[
            'alignCenter',
            'justifyCenter',
            'fullWidth',
            'fullHeight',
            'gap:2',
            'column',
          ]}
        >
          <Icon
            debugName='createStory:plus'
            name={'image-plus'}
            variants={['iconSize:4', 'primary']}
          />
          <Text text={I18N.t('act.createStory.picture')} />
        </View>
      )}
    </Touchable>
  )
}

const CreateStory = () => {

  const status = useAppSelector(state => state?.AppStatus?.status)
  const visible = useAppSelector(state => state?.AppStatus?.modals?.createStory)
  const form = useForm(AppForms.createStory, {})

  const stories = APIClient.Stories.storiesManager.useCreate()
  const [categories, setCategories] = useState([])

  const [file, setFile] = React.useState<File>(null)

  const remountSelect = useRemount()

  const loadOptions = async (inputValue: string) => {
    const options = await APIClient.Stories.getstoryCategory()

    const filtered = options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()),
    )

    return filtered
  }

  const fileInput = useFileInput()

  const resetForm = () => {
    form.reset(['values', 'errors'])
    setCategories([])
    setFile(null)
    remountSelect.trigger()
  }

  const handleCreateStory = useCallback(async () => {
    AppStatus.set('loading')
    try {
      categories.map(category => analytics.track('story_add_category', { category }))

      if (form?.values?.video?.length >= 1) {
        const existsVideo = await existsYouTubeId(form.values.video)

        if (!existsVideo) {
          alert(I18N.t('form.validations.videoNotExists'))
          throw new Error('Invalid video url')
        }
      }

      await stories.create({ ...form.values, categories, file })
      analytics.track('story_added')
      resetForm()
      AppStatus.setModal('createStory')
      AppStatus.setModal('congratulationsStories')
    } catch (err) {
      logger.error('Error creating story', err, 'Stories')
    }
    AppStatus.set('idle')
  }, [form.values])

  const onPickImage = async () => {
    const files = await fileInput.openFilePicker()

    if (!files.length) return
    const [f] = files
    form.setFieldValue('file', files)
    setFile(f.file)
  }

  return (
    <Modal
      debugName={'Create story modal'}
      visible={visible && !(status === 'loading' || status === 'done')}
      toggle={() => {
        AppStatus.setModal('createStory')
        resetForm()
      }}
      title={null}
      closable={true}
      scroll={true}
      withScrollContainer
      renderHeader={ModalHeader}
      variants={['centered']}
      styles={{
        wrapper: styles.modalWrapper,
        body: styles.body,
        box: styles.box,
      }}
      responsiveVariants={{ ['small']: ['fullscreen', 'topRadiusLarge'] }}
    >
      <CropPicker ref={fileInput.ref} />
      <Picture
        debugName={'Create Story Picture'}
        onPress={onPickImage}
        image={
          form?.values?.file?.length > 0 ? form?.values?.file[0]?.preview : null
        }
      />
      <TextInput {...form.register('headline')} />
      <TextInput
        autoCapitalize='none'
        autoCorrect={false}
        {...form.register('url')}
      />
      <TextInput
        autoCapitalize='none'
        autoCorrect={false}
        {...form.register('video')}
      />
      {remountSelect.mounted && (
        <Select
          multiple
          loadOptionsOnMount={true}
          debugName='Story Category Options'
          loadOptions={loadOptions}
          searchable
          {...form.register('categories')}
          value={categories}
          onValueChange={(val) => setCategories(val)}
        />
      )}

      <TextInput {...form.register('overview')} />
      <TextInput {...form.register('positive_outcomes')} />
      <TextInput {...form.register('learned')} />
      <TextInput {...form.register('advice')} />
      <Button
        debugName={'Create Story Button'}
        onPress={handleCreateStory}
        text={I18N.t('act.createStory.submitButton')}
        disabled={!form.isValid}
        css={styles.submitButton}
      />

      <Text style={{ display: 'inline-block' }} variants={['p3', 'gap:0.5']}>
        {I18N.t('storiesCommunityGuidelines')}{' '}
        <Link
          variants={[
            'p2',
            'bold',
            'color:primary3',
            'cursor:pointer',
            'noUnderline',
          ]}
          style={{ display: 'inline-block' }}
          text={I18N.t('pulseQuestion.communityGuidelines')}
          to={Settings?.ContactINFO.CommunityGuidelines}
          openNewTab
          tabIndex={0}
        />
      </Text>
    </Modal>
  )
}

export default CreateStory

const MODAL_WIDTH = 700

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    picture: {
      height: IMAGE_HEIGHT,
      width: '100%',
    },
    wrapper: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      width: MODAL_WIDTH,
    },
    body: {
      padding: theme.spacing.value(INTERNAL_SPACING.default),
      paddingTop: 0,
      marginTop: 0,
      maxWidth: MODAL_WIDTH,
      width: '100vw',

      [theme.media.down('small')]: {
        padding: theme.spacing.value(INTERNAL_SPACING.small),
        height: '100svh',
        maxHeight: '100svh',
        flex: 1,
      },
    },
    box: {
      padding: theme.spacing.value(0),
      maxHeight: '85vh',
      minHeight: '85vh',
    },
    modalWrapper: {
      zIndex: 10,
    },
    submitButton: {
      minHeight: theme.values.itemHeight.default,
      marginBottom: theme.spacing.value(2),
    },
  }),
  true,
)
