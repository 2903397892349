import { useLocation } from '@reach/router'
import { Button, Link, View } from '@/components'
import { Achievement } from '@/types'
import { LinkedIn, achievementShareUrl } from '@/utils'
import { Settings } from '@/app'
import { analytics } from '@/services'

export const LinkedInActions = ({ achievement }: { achievement: Achievement }) => {

  const currAchievement = achievement?.levels?.list?.[achievement?.levels?.current_level_index]
  const certificateURL = LinkedIn.createCertification(
    achievement?.name,
    currAchievement?.name,
  )
  const achievementUrl = achievementShareUrl(currAchievement)

  const shareUrl = Settings.Social.LinkedinShareUrl + encodeURIComponent(achievementUrl)

  const share = () => {
    analytics?.track('badge_share')
  }

  const addBadge = () => {
    analytics?.track('badge_add_linkedin')
  }

  return <View variants={['row', 'fullWidth', 'gap:2']} responsiveVariants={{ small: ['column'] }}>
    <Link
      to={shareUrl}
      openNewTab
      variants={['fullWidth', 'noUnderline']}
    >
      <Button
        debugName='achievement:linkedin'
        text={'Share'}
        icon={'linkedin-outline'}
        variants={['linkedIn', 'large']}
        onClick={share}
      />
    </Link>

    <Button
      // @ts-ignore
      component={'a'}
      target='_blank'
      onClick={addBadge}
      debugName='achievement:linkedin'
      text={'Add to certifications'}
      icon={'linkedin-outline'}
      variants={['linkedIn', 'large', 'noUnderline']}
      href={certificateURL}
    />
  </View>
}
