import { useState } from 'react'
import { useFirebase } from './useFirebase'
import { useIsomorphicEffect } from '@codeleap/common'

export const useFirebaseUser = (timeout = 3000) => {
  const firebase = useFirebase()
  const [timedOut, setTimedOut] = useState(false)

  const [firebaseUser, setFirebaseUser] = useState(() => {
    if (!firebase) {
      return null
    }

    return firebase.auth().currentUser
  })

  useIsomorphicEffect(() => {
    if (!firebase) return

    let timeoutId = setTimeout(() => {
      setTimedOut(true)
      timeoutId = null
    }, timeout)

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setFirebaseUser(user)
      if (timeoutId && !!user) {
        clearTimeout(timeoutId)
      }
    })

    const onUnload = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      unsubscribe()
    }

    return onUnload
  }, [!!firebase, timeout])

  return {
    firebaseUser,
    isLoading: !timedOut && !firebaseUser,
    timedOut,
  }
}
