import { IconComposition, IconPresets } from '@codeleap/web'
import { variantProvider } from '..'

const createIconStyle = variantProvider.createVariantFactory<IconComposition>()

export const AppIconStyles = {
  ...IconPresets,
  default: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.icon,
    },
  })),
  white: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
  })),

  primary: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,

    },
  })),
  negative: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,

    },
  })),
  positive: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.positive1,

    },
  })),

  huge: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(8),
    },
  })),
  large: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(6),
    },
  })),
  largeish: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(4),
    },
  })),
  medium: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(3),
    },
  })),
  small: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(2.5),
    },
  })),
  smaller: createIconStyle((theme) => ({
    icon: {
      ...theme.sized(2),
    },
  })),
  'iconSize:3': createIconStyle((theme) => ({
    icon: {
      height: theme.values.iconSize[3],
      width: theme.values.iconSize[3],
    },
  })),
  'iconSize:4': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[4],
      height: theme.values.iconSize[4],
    },
  })),

  'neutral5': createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral5,
    },
  })),
  originalColor: createIconStyle((theme) => ({
    icon: {
      color: 'auto',
    },
  })),
  'iconSize:1': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  'iconSize:2': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  'iconSize:5': createIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
  })),
  neutral10: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  'icon:neutral10': createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  neutral1: createIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
  })),
}
