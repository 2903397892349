import { variantProvider } from '@/app'
import { ActionIcon } from '@/components'
import { useAnimatedStyle } from '@/utils'
import { AnyFunction, onUpdate, useState } from '@codeleap/common'
import { motion } from 'framer-motion'

type LessonViewNextProps = {
  goToNext: AnyFunction
  visible?: boolean
}

const SHOW_NEXT_TIMEOUT = 6000

export const LessonViewNext = (props: LessonViewNextProps) => {
  const {
    goToNext,
    visible: _visible = true
  } = props

  const [visible, setVisible] = useState(false)

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: visible ? 1 : 0,
      transition: {
        duration: visible ? 0.4 : 0.1,
      }
    }
  }, [visible])

  onUpdate(() => {
    if (_visible) {
      setTimeout(() => {
        setVisible(true)
      }, SHOW_NEXT_TIMEOUT)
    } else {
      setVisible(false)
    }
  }, [_visible])

  return (
    <motion.div {...animatedStyle} css={[styles.wrapper, { display: _visible ? 'block' : 'none', pointerEvents: _visible ? 'auto' : 'none' }]}>
      <ActionIcon
        debugName='cancel lesson view'
        name='chevron-right'
        variants={['icon:lessonView', 'bg-neutral1']}
        onPress={goToNext}
      />
    </motion.div>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    position: 'absolute',
    bottom: theme.spacing.value(8),
    zIndex: 20,
    cursor: 'pointer',
    ...theme.effects.heavy,
    borderRadius: theme.borderRadius.rounded,

    [theme.media.down('huge')]: {
      right: theme.spacing.value(18),
    },
    [theme.media.down('xxlarge')]: {
      right: theme.spacing.value(16),
    },
    [theme.media.down('large')]: {
      right: theme.spacing.value(12),
    },
    [theme.media.down('largeish')]: {
      right: theme.spacing.value(8),
    },
    [theme.media.down('mid')]: {
      right: theme.spacing.value(4),
    },
    [theme.media.down('small')]: {
      right: theme.spacing.value(2),
      bottom: theme.spacing.value(2),
    },
  },
}), true)
