import { View, Icon, Text } from '@/components'
import { Organisation } from '@/types'
import { useI18N } from '@codeleap/common'

type ReplyDisclaimerProps = {
  style?: React.CSSProperties
  organisationName: Organisation['name']
}

export const ReplyDisclaimer = (props: ReplyDisclaimerProps) => {

  const { organisationName, ...rest } = props

  const { t } = useI18N()

  return (
    <View variants={['border-radius:tiny', 'padding:1', 'bg:positive1', 'gap:0.5']} {...rest}>
      <Icon
        debugName='reply icon'
        name='reply'
        variants={['primary', 'smaller']}
      />
      <Text text={t('reviewModal.replied', { organisationName })} variants={['p3', 'color:neutral9']} />
    </View>
  )
}
