import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

const IMAGE_HEIGHT = 124

export const ForgotAfterModal = () => {
  return (
    <AuthModal
      name='forgotAfter' 
      image={AppImages.CheckEmail}
      imageHeight={IMAGE_HEIGHT}
      small
    >
      <AuthForms.ForgotPasswordAfterForm />
    </AuthModal>
  )
}
