import React from 'react'
import { Button } from '@/components'
import { AnyFunction, IconPlaceholder } from '@codeleap/common'

type LearnOptionProps = {
  selected: boolean
  onPress: AnyFunction
  text: string
  spacingTop?: boolean
  isCorrect?: boolean
  isNeutral?: boolean
  extraSpacing?: boolean
}

export const LearnOption = (props: LearnOptionProps) => {
  const {
    selected,
    onPress,
    text,
    spacingTop = false,
    isCorrect = true,
    isNeutral = false,
    extraSpacing = false,
  } = props

  const getOptionType = () => {
    if (isNeutral) return 'option:neutral'
    return isCorrect ? 'option:selected' : 'option:selectedWrong'
  }

  return (
    <Button
      variants={['option', selected && getOptionType(), spacingTop && 'marginTop:1', extraSpacing && 'marginTop:2', 'fullWidth']}
      debugName='learn option button'
      onPress={onPress}
      text={text}
      rightIcon={selected ? (isNeutral ? 'check' : isCorrect ? 'check' : 'x') : null as IconPlaceholder}
    />
  )
}
