import React from 'react'
import { AchievementsStore } from './Store'
import { Modal, View, Text } from '@/components'
import { useAppI18N, variantProvider } from '@/app'
import { LevelTag } from './LevelTag'
import { LevelRow } from './LevelRow'
import { LevelBadge } from './HexagonBadge'
import { ModalHeaderActions } from './ModalHeaderActions'
import { LinkedInActions } from './LinkedInActions'
import { shareAchievement } from '@/utils'

export const AchievementDetail = () => {
  const { achievement, toggleAchievementModal, visibleAchievementModal } = AchievementsStore(store => store)

  const currentLevel = achievement?.levels?.list?.[achievement?.levels?.current_level_index]

  const { t } = useAppI18N()

  const hideProgress = (achievement?.levels?.list ?? [])?.length <= 2

  const close = () => {
    toggleAchievementModal(null)
    
    setTimeout(() => {
      AchievementsStore.setState({ achievement: null })
    }, 250)
  }

  return (
    <Modal
      variants={['noPadding']}
      responsiveVariants={{ small: ['fullscreen'] }}
      visible={visibleAchievementModal}
      toggle={close}
      styles={{
        box: styles.box,
      }}
      renderHeader={() => (
        <View css={[styles.header, { backgroundColor: currentLevel?.bg_color }]}>
          <ModalHeaderActions onShare={() => shareAchievement(achievement)} onClose={close} />
          <LevelBadge level={currentLevel} variant={'small'} />
          <LevelTag level={currentLevel} />
        </View>
      )}
    >
      <View variants={['column', 'padding:4', 'center', 'fullWidth']} responsiveVariants={{ small: ['padding:2', 'paddingVertical:4'] }}>
        <Text variants={['h1']} text={achievement?.name} />
        <Text variants={['p1', 'color:neutral6']} text={currentLevel?.description} />

        <View 
          variants={['separator', 'backgroundColor:neutral3', 'marginTop:4']}
          responsiveVariants={{ small: ['marginTop:2'] }}
          style={{ height: 1 }} 
        />
      </View>

      {hideProgress ? null : (
        <View variants={['fullWidth', 'column', 'gap:2']}>
          <Text 
            variants={['p2', 'color:neutral5', 'paddingHorizontal:4']}
            responsiveVariants={{ small: ['paddingHorizontal:2'] }}
            text={t('achievements.levelProgress')} 
          />

          {achievement?.levels?.list?.map((level, index) => {
            if (level?.is_none) return null

            return <LevelRow key={index + '-level-row'} level={level} />
          })}
        </View>
      )}

      <View variants={['padding:4', hideProgress && 'paddingTop:0']} responsiveVariants={{ small: ['padding:2', 'paddingTop:4'] }}>
        <LinkedInActions achievement={achievement} />
      </View>
    </Modal>
  )
}

const WIDTH = 650

const styles = variantProvider.createComponentStyle(theme => ({
  box: {
    maxWidth: WIDTH,
    width: '100vw',
    minHeight: WIDTH / 2,
  },
  header: {
    ...theme.presets.fullWidth,
    ...theme.presets.column,
    ...theme.presets.center,
    ...theme.presets.relative,
    ...theme.spacing.gap(2),
    ...theme.spacing.padding(4),

    [theme.media.down('small')]: {
      ...theme.spacing.paddingTop(10),
    }
  }
}), true)
