import React from 'react'
import { AnyFunction, useBooleanToggle, useI18N } from '@codeleap/common'
import { View, Button, Modal } from '@/components'
import { I18NDictionary } from '@/app/i18n'
// import { Link, useI18next } from 'gatsby-plugin-react-i18next'

const languageOptions = Object.entries(I18NDictionary).map(([key, value]) => ({
  label: value.__LABEL,
  value: key === 'en-GB' ? null : key,
}))

type LanguageSelectorProps = {
  path?: string
  onPress?: AnyFunction
}

export const LanguageSelector = ({ path = '/', onPress }: LanguageSelectorProps) => {
  const [visible, toggle] = useBooleanToggle(false)
  const { locale, t, setLocale } = useI18N()
  // const { originalPath } = useI18next()

  return <>
    <View variants={['column', 'gap:2']}>
      <Button
        text={t('languageSelector', { language: locale })}
        onPress={() => {
          onPress?.()
          toggle()
        }}
        debugName='open language selector modal'
        variants={['outline']}
        rightIcon='languages'
      />
    </View>

    <Modal
      toggle={() => toggle(false)}
      debugName='Language selector'
      variants={['centered']}
      visible={visible}
      title='Language'
      description='Select a language'
      withScrollContainer
      css={{
        zIndex: 9999,
      }}
    >
      {/* <View variants={['gap:1', 'column']} style={{ minWidth: 300 }}>
        {languageOptions.map((item) => {
          const selected = (item.value ?? 'en-GB') === locale

          return (
            <Button
              component={Link}
              debugName='language selector'
              key={item.label}
              variants={['link', selected && 'link:outline']}
              to={originalPath + ('')}
              language={item.value ?? 'en-GB'}
              onClick={() => {
                toggle(false)
                setLocale(item?.value ?? 'en-GB')
              }}
            >
              {item.label}
            </Button>
          )
        })}
      </View> */}
    </Modal>
  </>
}
