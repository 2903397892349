import { React, useAppI18N } from '@/app'
import { APIClient } from '@/services'
import { LearnOption, Text } from '@/components'
import { TypeGuards } from '@codeleap/common'
import { SORT_BY } from '@/services/api/stories'

type SortByProps = {
  sortBy?: APIClient.Stories.StoryFilters['sort_by']
  setSortBy?: (value: APIClient.Stories.StoryFilters['sort_by']) => void
  header?: React.ReactNode
}

export const SortStoriesBy = (props: SortByProps) => {
  const { sortBy, setSortBy } = props

  const { t } = useAppI18N()

  const onPress = (filter: keyof typeof SORT_BY) => {
    setSortBy(APIClient.Stories.SORT_BY[filter])
  }

  const defaultHeader = <Text variants={['p2', 'color:neutral5', 'marginBottom:1']} text={t('learn.stories.sort_by')} />

  return <>
    {TypeGuards.isUndefined(props.header) ? defaultHeader : props.header}
    <LearnOption
      text={t('learn.stories.likes')}
      onPress={() => onPress('likes')}
      selected={sortBy === APIClient.Stories.SORT_BY.likes}
    />
    <LearnOption
      spacingTop
      text={t('learn.stories.date')}
      onPress={() => onPress('created_datetime')}
      selected={sortBy === APIClient.Stories.SORT_BY.created_datetime}
    />
  </>
}
