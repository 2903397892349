import { React, Theme, variantProvider } from '@/app'
import { Button, Image, View, Text, Touchable } from '@/components'
import { AnyFunction, assignTextStyle } from '@codeleap/common'
import { concatStyles } from '@/utils'

export type AnswerButtonProps = {
  image?: string
  text?: string
  selected?: boolean
  value?: number
  onPress: AnyFunction
}

export const AnswerButton = ({ image, text, selected, onPress }: AnswerButtonProps) => {
  const _styles = React.useMemo(() => ({
    wrapper: concatStyles([styles.wrapper, image && styles.imageBtnWrapper, selected && styles.selectedWrapper]),
    text: concatStyles([styles.text, selected && styles.textSelected, image && styles.imageText]),
    rightIcon: concatStyles([styles.rightIcon, selected && styles.iconSelected]),
  }), [selected])

  if (image) {
    return (
      <Touchable
        debugName='Pulse question answer button'
        onPress={onPress}
        style={_styles.wrapper}
      >
        <Image
          source={image}
          style={styles.image}
          objectFit='cover'
        />
        <View variants={['paddingVertical:2', 'center', 'flex', 'fullWidth', 'paddingHorizontal:1']}>
          <Text variants={['h5', 'textCenter', 'color:neutral9']} text={text} />
        </View>
      </Touchable>
    )
  }

  return (
    <Button
      styles={_styles}
      rightIcon='check'
      debugName='Pulse question answer button'
      onPress={onPress}
      text={text}
    />
  )
}

const IMAGE_OPTION_WIDTH = 160
const OPTION_SIZE = `calc((100% / 4) - ${Theme.spacing.value(2)}px)`

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    overflow: 'hidden',
    backgroundColor: theme.colors.neutral2,
    borderRadius: theme.borderRadius.small,
    ...theme.border.neutral2(2),
    height: 'auto',
    minHeight: theme.values.itemHeight.default,
    ...theme.presets.justifySpaceBetween,

    ':hover': {
      backgroundColor: theme.colors.neutral2,
      filter: 'brightness(95%)'
    }
  },
  selectedWrapper: {
    backgroundColor: theme.colors.positive1,
    ...theme.border.primary3(2),

    ':hover': {
      backgroundColor: theme.colors.positive1,
      filter: 'brightness(95%)'
    }
  },
  text: {
    ...assignTextStyle('p1')(theme).text,
    color: theme.colors.neutral6,
  },
  textSelected: {
    fontWeight: '700',
  },
  imageText: {
    ...assignTextStyle('h5')(theme).text,
  },
  imageBtnWrapper: {
    ...theme.presets.column,
    minHeight: OPTION_SIZE,
    minWidth: '100%',
    maxWidth: '100%',
    flex: 1,
  },
  image: {
    width: '100%',
    aspectRatio: IMAGE_OPTION_WIDTH / (IMAGE_OPTION_WIDTH * 0.5),
    minHeight: (IMAGE_OPTION_WIDTH * 0.5),
  },
  rightIcon: {
    color: 'transparent',
    height: theme.values.iconSize[2],
    width: theme.values.iconSize[2],
  },
  iconSelected: {
    color: theme.colors.neutral6,
  },
}), true)
