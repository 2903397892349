import { AppForms, variantProvider } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { useForm, useI18N } from '@codeleap/common'
import { Text, View, Select, Button, OrganisationSelect } from '@/components'
import { useKeydown } from '@/utils'
import { navigate } from 'gatsby'
import { analytics } from '@/services'

type AboutJobFormProps = {
  isModal?: boolean
}

export const AboutJobForm = ({ isModal = true }: AboutJobFormProps) => {
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.aboutJob)
  const { options } = APIClient.Session.useSession()
  const { t } = useI18N()

  const { editProfile } = APIClient.Session.useEdit()
  const form = useForm(AppForms.aboutJob, {})

  const onSave = async () => {
    await editProfile(form.values)

    analytics.track('work_info_start', {
      org_name: form.values.organization,
      function: form.values.department,
      seniority: form.values.seniority,
    })

    if (isModal) {
      AppStatus.transitionModals([
        'aboutJob',
        'onboarding',
      ])
    } else {
      AppStatus.setModal(['profileRedirect', false])
      navigate('/')
      AppStatus.setModal('onboarding')
    }
  }

  useKeydown(() => {
    const isInvalidSubmit = isModal && !isFocused || !form.isValid
    if (isInvalidSubmit) return
    onSave?.()
  }, [isFocused, isModal, form?.values], 'enter')

  return (
    <View variants={['flex', 'column']} >
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('AboutJob.title')}
      />
      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={t('AboutJob.sub')}
      />

      <View variants={['column', 'gap:3', 'center']}>
        <View variants={['column', 'gap:1', 'fullWidth']}>
          <OrganisationSelect
            {...form.register('organization')}
            variants={['fullWidth']}
          />
          <Select
            {...form.register('department')}
            options={options?.department}
            variants={['fullWidth']}
          />
          <Select
            {...form.register('seniority')}
            options={options?.seniority}
            variants={['fullWidth']}
            style={{ width: '50%' }}
          />
        </View>

        <View variants={['row', 'justifySpaceBetween']} style={styles.footer}>
          <Button
            text={t('AboutJob.save')}
            disabled={!form.isValid}
            debugName={'Save Button'}
            variants={['pill', 'large', 'neutral9', 'fullWidth', !form.isValid && 'outline']}
            onPress={onSave}
          />
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  footer: {
    width: '50%',

    [theme.media.down('small')]: {
      ...theme.presets.fullWidth,
    },
  },
}), true)
