import React from 'react'
import { AppImages, variantProvider } from '@/app'
import { View, Text, Modal, Image, ActionIcon } from '@/components'
import { AnyFunction } from '@codeleap/common'
import { ActionIconProps, ModalProps, ViewProps } from '@codeleap/web'
import { concatStyles, useIsMobile } from '@/utils'

type GradientModalProps = {
  visible: boolean
  toggle: AnyFunction
  children: React.ReactNode
  title?: string
  renderDesktopHeader?: () => React.ReactElement
  onSharePress?: AnyFunction
  shareIconProps?: Partial<ActionIconProps>
  closeIconProps?: Partial<ActionIconProps>
  bodyStyle?: React.CSSProperties
  boxStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  desktopHeaderWrapperProps?: Partial<ViewProps<'div'>>
} & Partial<ModalProps>

const BREAKPOINT = 'small'

const Header = (props) => {
  const { children, isMobile, style } = props
  return (
    <View css={[styles.subHeader, style]}>
      <View
        variants={['fullWidth']}
        responsiveVariants={{ [BREAKPOINT]: ['fullWidth', 'paddingHorizontal:2', 'paddingTop:2', 'paddingBottom:1'] }}
      >
        {children}
      </View>

      {isMobile && (
        <Image
          css={styles['subHeaderBackground:mobile']}
          source={AppImages.AppTopBg}
          objectFit='cover' />
      )}
    </View>
  )
}

const MobileHeaderContent = (props) => {
  const {
    visible,
    toggle,
    onSharePress,
    shareIconProps = {},
    closeIconProps = {},
    title,
  } = props

  return (
    <View variants={['fullWidth']}>
      {onSharePress && (
        <View variants={['fullWidth', 'justifyStart']}>
          <ActionIcon
            name='share'
            variants={['iconSize:3', 'minimal:neutral1']}
            debugName={'ReviewCardModal share icon'}
            onPress={onSharePress}
            {...shareIconProps}
          />
        </View>
      )}

      {title && (<View variants={['justifyCenter', 'alignCenter']}>
        <Text variants={['h5', 'color:neutral1', 'noWrap']}>
          {title}
        </Text>
      </View>
      )}

      <View variants={['fullWidth', 'justifyEnd']}>
        <ActionIcon
          name='x'
          variants={['iconSize:3', 'minimal:neutral1']}
          debugName={'ReviewCardModal x icon'}
          onPress={() => toggle(!visible)}
          {...closeIconProps}
        />
      </View>
    </View>
  )
}

const DesktopHeaderContent = (props) => {
  const {
    visible,
    toggle,
    title,
    renderDesktopHeader: RenderDesktopHeader,
    onSharePress,
    shareIconProps = {},
    closeIconProps = {},
    desktopHeaderWrapperProps = {},
  } = props

  return (
    <View variants={['alignCenter']} style={styles.desktopContentWrapper} {...desktopHeaderWrapperProps}>
      {RenderDesktopHeader && (
        <View variants={['fullWidth']}>
          {RenderDesktopHeader && <RenderDesktopHeader />}
        </View>
      )}
      {title && <Text variants={['h1', 'color:neutral9', 'fullWidth']}>{title}</Text>}
      {onSharePress && (
        <ActionIcon
          name='share'
          variants={['iconSize:3', 'minimal', 'justifyStart']}
          debugName={'ReviewCardModal desktopheader share icon'}
          onPress={onSharePress}
          {...shareIconProps}
        />
      )}
      <ActionIcon
        name='x'
        variants={['iconSize:3', 'minimal', 'justifyEnd']}
        debugName={'ReviewCardModal x icon'}
        onPress={() => toggle(!visible)}
        {...closeIconProps}
      />
    </View>
  )
}

export const GradientModal = (props: GradientModalProps) => {
  const {
    visible,
    toggle,
    renderDesktopHeader: RenderDesktopHeader,
    children,
    onSharePress,
    title,
    bodyStyle = {},
    boxStyle = {},
    headerStyle = {},
    ...rest
  } = props

  const isMobile = useIsMobile()

  const HeaderContent = isMobile ? MobileHeaderContent : DesktopHeaderContent

  const variantStyles = React.useMemo(() => ({
    body: concatStyles([styles.body, bodyStyle]),
    box: concatStyles([styles.box, boxStyle]),
    header: headerStyle,
  }), [])

  return (
    <Modal
      toggle={() => toggle(!visible)}
      visible={visible}
      debugName={'GradientModal'}
      // @ts-ignore
      variants={[isMobile ? 'fullscreen' : 'centered']}
      styles={{
        body: variantStyles.body,
        box: variantStyles.box,
      }}
      renderHeader={() => (
        <Header visible={visible} toggle={toggle} isMobile={isMobile} style={variantStyles.header}>
          <HeaderContent {...props} />
        </Header>
      )}
      {...rest}
    >
      {children}
    </Modal>
  )
}

const MAX_WIDTH = 700

const styles = variantProvider.createComponentStyle((theme) => ({
  body: {
    [theme.media.down('small')]: {
      flex: 1,
      paddingTop: theme.spacing.value(2),
      paddingLeft: theme.spacing.value(2),
      paddingRight: theme.spacing.value(2),
      marginTop: theme.spacing.value(1),
      background: theme.colors.neutral1,
      overflow: 'scroll',
      borderRadius: 32,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  box: {
    ...theme.spacing.padding(4),
    width: '100vw',
    maxWidth: MAX_WIDTH,

    overflow: 'auto',
    maxHeight: `calc(100dvh - ${theme.spacing.value(14)}px)`,

    [theme.media.down('small')]: {
      padding: theme.spacing.value(0),
      paddingTop: theme.spacing.value(1),
      height: '100svh',
      maxHeight: '100svh',
    },
  },
  subHeader: {
    marginBottom: theme.spacing.value(3),
    ...theme.presets.relative,
    ...theme.presets.fullWidth,

    [theme.media.down('small')]: {
      marginBottom: theme.spacing.value(0),
    },
  },
  'subHeaderBackground:mobile': {
    height: '20vh',
    width: '100%',
    top: theme.spacing.value(0),
    position: 'absolute',
    zIndex: -100,
    marginTop: -theme.spacing.value(2),

    [theme.media.down('small')]: {
      width: '100vw',
    },
  },
  desktopContentWrapper: {
    ...theme.presets.justifySpaceBetween,
    ...theme.spacing.gap(2),
    ...theme.presets.fullWidth,
  },
}), true)
