import { assignTextStyle, includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'
import 'react-circular-progressbar/dist/styles.css'

export type ProgressCircleComposition = 'wrapper' | 'innerWrapper' | 'line' | 'circle' | 'text' | 'icon' | 'text' | 'image' | 'label' | '__props'

const createProgressCircleStyle =
  variantProvider.createVariantFactory<ProgressCircleComposition>()

const defaultStyles = includePresets(style => {
  return createProgressCircleStyle(theme => ({
    wrapper: style,
  }))
})

export const ProgressCircleStyles = {
  ...defaultStyles,
  default: createProgressCircleStyle((theme) => {
    return {
      wrapper: {
      },
      circle: {
        size: 160,
      },
      line: {
        borderColor: theme.colors.primary3,
        backgroundColor: theme.colors.neutral3,
      },
      icon: {
        size: theme.values.iconSize[4],
        color: theme.colors.neutral9,
      },
      image: {
        height: theme.values.iconSize[4],
        width: theme.values.iconSize[4],
      },
      innerWrapper: {
        ...theme.presets.column,
        ...theme.presets.alignCenter,
      },
      text: {
        ...assignTextStyle('p2')(theme).text,
        color: theme.colors.neutral9,
      },
      label: {
        display: 'none',
      },
      __props: {
        rotation: 0,
      },
    }
  }),
  small: createProgressCircleStyle((theme) => {
    return {
      wrapper: {
      },
      circle: {
        size: 64,
      },
      text: {
        display: 'none',
      },
      icon: {
        size: theme.values.iconSize[2],
      },
      image: {
        height: theme.values.iconSize[4],
        width: theme.values.iconSize[4],
      },
      label: {
        display: 'flex',
      },
    }
  }),
  tiny: createProgressCircleStyle((theme) => {
    return {
      icon: {
        size: theme.values.iconSize[1],
      },
      image: {
        height: theme.values.iconSize[1],
        width: theme.values.iconSize[1],
      },
      circle: {
        size: 32,
      },
      text: {
        display: 'none',
      },
    }
  }),
  'noLabel': createProgressCircleStyle((theme) => {
    return {
      label: {
        display: 'none',
      },
    }
  }),
  'largeImage': createProgressCircleStyle((theme) => {
    return {
      image: {
        height: 66,
        width: 73,
      },
    }
  }),
  'large': createProgressCircleStyle((theme) => {
    return {
      circle: {
        size: 150,
      },
    }
  }),
}
