export const NOTIFICATION_DATA_TYPES = {
  SOME_EVENT: 'some_event',
  ANOTHER_EVENT: 'another_event',
} as const

export type NotificationDataType = typeof NOTIFICATION_DATA_TYPES

  type NotificationData = {

      type: NotificationDataType['SOME_EVENT']
      data: {
        id_of_something: string
      }

  } | {

      type: NotificationDataType['ANOTHER_EVENT']
      data: {
        argument: string
      }

  }

export const STATUS_TYPES = {
  Sent: 'Sent',
  Received: 'Received',
  Read: 'Read',
}

  type NotificationStatus = (typeof STATUS_TYPES)[keyof typeof STATUS_TYPES]

export type Notification = {
      id: number
      created_datetime: string
      title: string
      body:string
      status: NotificationStatus
  } & NotificationData

