import { Theme, variantProvider } from '@/app'
import { View, SegmentedControl, Text } from '@/components'
import { useI18N } from '@codeleap/common'
import { useState } from 'react'
import { Reviews, SuccessStories } from './'
import { useSearchParams } from '@codeleap/web'
import { APIClient } from '@/services'
import { analytics } from '@/services'

export const EmployeeReviews = ({ organisation }) => {
  const { t } = useI18N()

  const segmentedControlOptions = [
    {
      label: t('organisations.reviewsTabTitle'),
      debugName: 'Reviews',
      value: 'reviews',
    },
    {
      label: t('organisations.successStoriesTabTitle'),
      debugName: 'Success stories',
      value: 'stories',
    },
  ]

  const BREAKPOINT = 'small'
  const [params, setParams] = useSearchParams({
    content: '',
    storyTitle: '',
    tab: segmentedControlOptions?.[0]?.value,
    sortStoriesBy: APIClient.Stories.SORT_BY.likes,
    reviewId: null,
  })
  const [tab, setTab] = useState(params.tab)

  const onSelectTab = (tab) => {
    setTab(tab)
    setParams({ ...params, tab })
  }

  const isInReviewsTab = tab === 'reviews'

  const searchProps = {
    params,
    setParams: to => setParams({ ...params, ...to }),
  }

  return (
    <View
      style={[Theme.effects.light, styles.wrapper]}
      variants={['column', 'gap:2', 'bg:neutral1', 'border-radius:large', 'padding:4', 'paddingBottom:0']}
      responsiveVariants={{ [BREAKPOINT]: ['column', 'gap:2', 'bg:neutral1', 'border-radius:large', 'padding:2'] }}
    >
      <Text variants={['h2', 'color:neutral9']}>{t('organisations.reviewsTitle')}</Text>
      <View variants={['fullWidth']}>
        <SegmentedControl variants={['fullWidth', 'noWrap']} touchableProps={{ tabIndex: 0 }} options={segmentedControlOptions} value={tab} onValueChange={onSelectTab} />
      </View>
      {isInReviewsTab ? (
        <Reviews organisation={organisation} {...searchProps} />
      ) : (
        <SuccessStories organisation={organisation} {...searchProps} />
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: '100%',
    flex: 1,
  },

}), true)
