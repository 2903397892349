import { React } from '@/app'
import { Alert } from '@/components'
import { useModal } from '@/utils'
import { useCallback, useI18N } from '@codeleap/common'

type PasswordChangeAlertProps = {
  visible: boolean
  toggle: () => void
  handlePasswordChange: () => void
}

type CheckPasswordChangeEmailAlertProps = Omit<PasswordChangeAlertProps, 'handlePasswordChange'>

const ConfirmPasswordChangeAlert = (props: PasswordChangeAlertProps) => {
  const { visible, toggle, handlePasswordChange } = props

  const { t } = useI18N()

  return (
    <Alert
      visible={visible}
      title={t('profile.changePasswordModal.confirmRequest.title')}
      body={t('profile.changePasswordModal.confirmRequest.body')}
      type={'destructive'}
      onAction={handlePasswordChange}
      toggle={toggle}
      variants={['profileAlert']}
      debugName={'Profile edit - Change password alert modal'}
      actionButtonProps={{
        text: t('proceed'),
        debugName: 'Profile edit change password alert modal - confirm change button on press',
      }}
      dismissButtonProps={{
        text: t('cancel'),
        debugName: 'Profile edit change password alert modal - close modal on press',
        variants: ['flex', 'outline', 'large', 'pill'],
      }}
    />
  )
}

const CheckPasswordChangeEmailAlert = (props: CheckPasswordChangeEmailAlertProps) => {
  const { visible, toggle } = props

  const { t } = useI18N()

  return (
    <Alert
      visible={visible}
      title={t('profile.changePasswordModal.checkEmail.title')}
      body={t('profile.changePasswordModal.checkEmail.subtitle')}
      type={'info'}
      variants={['profileAlert']}
      toggle={toggle}
      debugName={'Profile edit - Check password change email alert'}
      dismissButtonProps={{
        text: t('done'),
        debugName: 'Profile edit check password change email alert modal - done on press',
        variants: ['flex', 'outline', 'large', 'pill'],
      }}
    />
  )
}

export const PasswordChangeAlert = ({ visible, toggle, handlePasswordChange }: PasswordChangeAlertProps) => {
  const modal = useModal()

  const onConfirmPasswordChange = useCallback(() => {
    modal.toggle(true)
    handlePasswordChange()
  }, [])

  if (!modal.visible) {
    return (
      <ConfirmPasswordChangeAlert 
       visible={visible}
       toggle={toggle}
       handlePasswordChange={onConfirmPasswordChange} 
      />
    )
  } else {
    return (
      <CheckPasswordChangeEmailAlert 
        visible={modal.visible} 
        toggle={modal.toggle}
      />
    )
  }
}
