import { QuizChoice, QuizQuestion } from '@/types'
import { create } from 'zustand'

type TLearnStore = {
  choices: Record<QuizQuestion['id'], QuizChoice>
  setChoice: (questionId: QuizQuestion['id'], choice: QuizChoice) => void
  clear: () => void
}

export const LearnStore = create<TLearnStore>((set) => ({
  choices: {},
  setChoice: (questionId, choice) => set(state => {
    const choices = state.choices

    choices[questionId] = choice

    return {
      choices
    }
  }),
  clear: () => set(state => ({
    choices: {}
  }))
}))
