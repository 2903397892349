import { CropPickerComposition, CropPickerPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createCropPickerVariant = variantProvider.createVariantFactory<CropPickerComposition>()

const defaultStyles = CropPickerPresets

export const AppCropPickerStyles = {
  ...defaultStyles,
  default: createCropPickerVariant(theme => ({
    modalInnerWrapper: {
      ...theme.presets.center,
    },
    modalBox: {
      padding: theme.spacing.value(4),
      width: 'calc(80vw - 72px)',
      maxWidth: 900,
      // [theme.media.down('large')]: {
      //   maxWidth: 800,
      // },
      [theme.media.down('medium')]: {
        maxWidth: 450,
      },
      [theme.media.down('small')]: {
        padding: theme.spacing.value(2),
      },
    },
    previewSize: {
      maxHeight: '70vh',
      [theme.media.down('medium')]: {
        maxHeight: 'unset',
        maxWidth: '80vh',
      },
    },
    confirmButtonWrapper: {
      ...theme.presets.fullWidth,
    },
    cropPreview: {
      borderRadius: theme.borderRadius?.medium,
    },
  })),
}

