import { useState } from 'react'

export const useRemount = () => {
  const [mounted, setMounted] = useState(true)

  return {
    trigger: () => {
      setMounted((curr) => !curr)

      setTimeout(() => {
        setMounted((curr) => !curr)
      })
    },
    mounted,
  }
}
