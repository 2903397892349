import React from 'react'
import { View, Text, ProgressBar, Image, CardBase } from '@/components'
import { variantProvider } from '@/app'
import { Achievement } from '@/types'
import { calcPercentage } from '@/utils'
import { arePropsEqual } from '@codeleap/common'
import { AchievementsStore } from './Store'

type AchievementCardProps = {
  achievement: Achievement
}

const AchievementCardComponent = (props: AchievementCardProps) => {
  const { achievement } = props

  const toggleAchievementModal = AchievementsStore(store => (() => store.toggleAchievementModal(achievement)))

  const currentLevelIdx = achievement?.levels?.current_level_index
  const levels = achievement?.levels?.list ?? []
  const currentLevel = levels?.[currentLevelIdx]
  const nextLevel = levels?.[currentLevelIdx + 1]

  const progress = !nextLevel ? 100 : calcPercentage({ partial: currentLevel?.achievement_progress, total: nextLevel?.progression_required })

  const hideProgressBar = levels?.length <= 2

  return (
    <CardBase
      variants={['elevated', 'card:achievement']}
      gap={2}
      debugName='achievement:card'
      onPress={currentLevel?.name?.toLowerCase() == 'none' ? null : toggleAchievementModal}
    >
      <View css={[styles.wrapperImage, { background: currentLevel?.bg_color_light }]}>
        <Image style={styles.image} source={currentLevel?.image} objectFit='contain' />
        <Text variants={['p4', 'color:neutral6']} text={currentLevel?.name} />
      </View>

      <View variants={['column', 'flex', 'gap:1', 'center', 'alignStart']}>
        {!!achievement?.name && <Text variants={['h5', 'color:neutral6']} text={achievement?.name} />}
        {!!currentLevel?.description && <Text variants={['p4', 'color:neutral5']} text={currentLevel?.description} />}
        {!hideProgressBar && (
          <ProgressBar
            progress={progress}
            text={currentLevel?.progress_descriptor}
            variants={['achievement']}
          />
        )}
        {!!currentLevel?.next_level_descriptor && <Text variants={['p5', 'color:neutral5']} text={currentLevel?.next_level_descriptor} />}
      </View>
    </CardBase>
  )
}

export const AchievementCard = React.memo(AchievementCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, { check: ['achievement'] })
})

const IMAGE_SIZE = 40

const styles = variantProvider.createComponentStyle(theme => ({
  wrapperImage: {
    borderRadius: theme.borderRadius.small,
    minWidth: IMAGE_SIZE * 2.3,
    ...theme.presets.column,
    ...theme.spacing.padding(2),
    ...theme.spacing.gap(0.5),
    ...theme.presets.center,
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
  },
}), true)
