import React from 'react'
import { I18N, variantProvider } from '@/app'
import { CardBase, CardBaseProps } from './CardBase'
import { Text, View, ImageProps, OrganisationCategoryInfo } from '@/components'
import { Organisation } from '@/types/organisation'
import { OrganisationLogo } from '../Organisations/Logo'
import { arePropsEqual } from '@codeleap/common'

type OrganisationCardProps = Partial<CardBaseProps> & {
  organisation: Organisation
  imageProps?: Partial<ImageProps>
  asLink?: boolean
  href?: string
}

export const OrganisationCardComponent = (props: OrganisationCardProps) => {
  const { organisation, imageProps, asLink = false, href, ...cardProps } = props

  return (
    <CardBase
      styles={{ wrapper: styles.wrapper, innerWrapper: styles.innerWrapper }}
      debugName={`OrganisationCard:${organisation?.id}`}
      gap={0}
      variants={['card:elevated']}
      tabIndex={0}
      aria-label={I18N.t('accessibility.organisationCard', { orgName: organisation?.name })}
      component={asLink ? 'a' : undefined}
      href={href}
      {...cardProps}
    >
      <OrganisationLogo
        logo={organisation?.logo}
        style={styles.image}
        objectFit={'cover'}
        alt={`${organisation?.name} logo`}
      />

      <View
        variants={['flex', 'column', 'gap:0.5', 'justifyStart', 'fullWidth', 'padding:2']}
        responsiveVariants={{ small: ['padding:0'] }}
      >
        <Text
          variants={['h4', 'ellipsis']}
          responsiveVariants={{ small: ['h5'] }}
          style={styles.name}
          text={organisation?.name}
          component='h3'
        />
        <OrganisationCategoryInfo organisation={organisation} />
      </View>
    </CardBase>
  )
}

export const OrganisationCard = React.memo(OrganisationCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['organisation'],
  })
})

const HEIGHT = 270

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    width: '100%',
    ...theme.spacing.padding(0),

    minHeight: HEIGHT,
    maxHeight: HEIGHT,

    [theme.media.down('small')]: {
      maxWidth: '100%',
      minWidth: '100%',
      minHeight: 'auto',
      maxHeight: 'auto',
      flex: 1,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifyStart,
      ...theme.spacing.padding(2),
    },
  },
  innerWrapper: {
    width: '100%',

    [theme.media.down('small')]: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifyStart,
      ...theme.spacing.gap(1.5),
    },
  },
  name: {
    [theme.media.down('small')]: {
      maxWidth: 'calc(100% - 40px - 16px)',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: 160,

    [theme.media.up('small')]: {
      borderTopRightRadius: theme.borderRadius.small,
      borderTopLeftRadius: theme.borderRadius.small,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },

    [theme.media.down('small')]: {
      maxWidth: 40,
      minWidth: 40,
      maxHeight: 40,
      ...theme.presets.full,
    },
  },
}), true)
