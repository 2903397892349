import { assignTextStyle, includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'

export type ProgressBarComposition = 'wrapper' | 'indicator'| 'progress' | 'text'

const createProgressBarStyle = variantProvider.createVariantFactory<ProgressBarComposition>()

const ProgressBarPresets = includePresets((style) => createProgressBarStyle(() => ({ wrapper: style })))

const BAR_WIDTH_MEDIUM = 170
const BAR_WIDTH_SMALL = 90

const PROGRESS_HEIGHT = 8

export const ProgressBarStyles = {
  ...ProgressBarPresets,
  default: createProgressBarStyle((theme) => {
    return {
      wrapper: {
        ...theme.presets.row,
        ...theme.presets.alignCenter,
        flex: 1,
        ...theme.spacing.gap(1),
      },

      progress: {
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.colors.neutral3,
        borderRadius: theme.borderRadius.rounded,
        height: PROGRESS_HEIGHT,
        transform: 'translateZ(0)',
        width: 60,
      },
      indicator: {
        backgroundColor: theme.colors.primary3,
        ...theme.presets.fullWidth,
        ...theme.presets.fullHeight,
        transition: 'transform 600ms cubic-bezier(0.65, 0, 0.35, 1)',
        transform: `translateX(-${100 - 5})`,

      },
      text: {
        ...assignTextStyle('h5'),
        color: theme.colors.neutral5,
      },
    }
  }),
  medium: createProgressBarStyle((theme) => {
    return {
      progress: {
        width: BAR_WIDTH_MEDIUM,
        minWidth: BAR_WIDTH_MEDIUM,
        maxWidth: BAR_WIDTH_MEDIUM,
      },
      text: {
        ...assignTextStyle('p4'),
      },
    }
  }),
  small: createProgressBarStyle((theme) => {
    return {
      progress: {
        width: BAR_WIDTH_SMALL,
        minWidth: BAR_WIDTH_SMALL,
        maxWidth: BAR_WIDTH_SMALL,
      },
      text: {
        ...assignTextStyle('p4'),
      },
    }
  }),
  'achievement': createProgressBarStyle((theme) => {
    return {
      wrapper: { 
        ...theme.presets.fullWidth,
        maxHeight: PROGRESS_HEIGHT * 1.2,
      },
      progress: {
        ...theme.presets.fullWidth,
      },
      text: {
        ...assignTextStyle('p4')(theme).text,
      },
    }
  }),
}

