import { View, Text, Icon } from '@/components'
import { Reply } from '@/types'

type ReplyPreviewProps = {
  replies: Reply[]
  style?: React.CSSProperties
}

export const ReplyPreview = (props: ReplyPreviewProps) => {
  const { replies, ...rest } = props

  const replierOrganisation = `${replies[0].organization_name} Team`
  const replyTxt = replies[0].response

  return (
    <View variants={['row', 'gap:0.5', 'backgroundColor:positive1', 'paddingHorizontal:1', 'paddingVertical:0.75', 'border-radius:tiny']} {...rest}>
      <View>
        <Icon
          debugName='reply icon'
          name='reply'
          variants={['primary', 'smaller']}
        />
      </View>

      <Text
        variants={['p2', 'noWrap']}
        text={replierOrganisation}
      />
      <Text
        variants={['p3', 'ellipsis']}
        text={replyTxt}
      />
    </View>
  )
}
