import { formatDateLocalized } from '@/app'
import { CardBase, Text, View, OrganisationLogo } from '@/components'
import { Reply } from '@/types'
import { TypeGuards, useI18N } from '@codeleap/common'

type CardReplyProps = {
  replies: Reply[]
  style?: React.CSSProperties
}

export const CardReply = (props: CardReplyProps) => {

  const { replies, ...rest } = props

  const { t } = useI18N()

  const replyTxt = replies[0]?.response
  const hasLogo = TypeGuards.isString(replies[0]?.logo)
  const replyLogo = replies[0]?.logo
  const replyTitle = replies[0]?.replier_name ? `${replies[0]?.replier_name} at ${replies[0]?.organization_name}` : replies[0]?.organization_name

  const formattedReplyDate = formatDateLocalized(new Date(replies[0].created_datetime), 'learn.stories.date_format')

  return (
    <CardBase
      debugName='Reply card'
      variants={['card:review', 'marginTop:4', 'padding:4']}
      innerWrapperProps={{ variants: ['fullWidth'] }}
      {...rest}
    >
      <Text variants={['h4', 'color:neutral7', 'marginBottom:2']} text={t('reviewModal.replyCard.title')} />
      <View variants={['row', 'alignCenter', 'flex', 'marginBottom:1']}>
        {hasLogo && (<OrganisationLogo logo={replyLogo} size='tiny' variants={['marginRight:1']} />)}
        <View variants={['column', 'flex']}>
          <Text variants={['p2', 'color:neutral7']} text={replyTitle} />
          <Text variants={['p3', 'color:neutral6']} text={replies[0]?.replier_role} />
        </View>
        <Text variants={['p4', 'alignSelfStart']} text={formattedReplyDate} />
      </View>
      <Text variants={['p1', 'color:neutral6']} text={replyTxt} />
    </CardBase>
  )
}
