import { CodeleapQueryClient, ReactQuery } from '@codeleap/common'
import { getFirebase } from '../firebaseApp'

const reactQueryClient = new ReactQuery.QueryClient()
const queryClient = new CodeleapQueryClient(reactQueryClient)

if (!(typeof window === 'undefined')) {
  // getFirebase().then(fb => {
  //   reactQueryClient.setDefaultOptions({
  //     queries: {
  //       enabled: !!fb.auth().currentUser?.uid,
  //     },
  //   })
  //   fb.auth().onAuthStateChanged((user) => {

  //     reactQueryClient.setDefaultOptions({
  //       queries: {
  //         enabled: !!user?.uid,
  //       },
  //     })
  //   })
  // })
  // const localStoragePersister = createSyncStoragePersister({
  //   storage: window.localStorage,
  // })

  // persistQueryClient({
  //   queryClient: reactQueryClient,
  //   persister: localStoragePersister,

  // })

}

export { queryClient }
