import { variantProvider } from '../theme'
import { assignTextStyle, includePresets } from '@codeleap/common'

export type TagComposition = 'wrapper' | 'innerWrapper' | 'text'

const createTagStyle = variantProvider.createVariantFactory<TagComposition>()

export const TagPresets = includePresets((style) => createTagStyle(() => ({ wrapper: style })))

const defaultStyles = TagPresets

export const TagStyles = {
  ...defaultStyles,
  default: createTagStyle((theme) => {
    return {
      wrapper: {
        ...theme.spacing.padding(1),
        borderRadius: theme.borderRadius.tiny,
        backgroundColor: theme.colors.neutral2,
        ...theme.presets.alignSelfStart,
        ...theme.presets.row,
      },
      'wrapper:pressable': {
        '&:hover': {
          backgroundColor: theme.colors.neutral3,
        },
      },
      'wrapper:selected': {
        backgroundColor: theme.colors.primary1,
      },
      innerWrapper: {
        ...theme.presets.centerRow,
        ...theme.spacing.gap(0.5),
      },
      text: {
        ...assignTextStyle('p3')(theme).text,
      },
    }
  }),
  small: createTagStyle((theme) => {
    return {
      wrapper: {
        ...theme.spacing.paddingVertical(0.5),
        ...theme.spacing.paddingHorizontal(1),

      },
      text: {
        ...assignTextStyle('p4')(theme).text,
      },
    }
  }),
  tiny: createTagStyle((theme) => {
    return {
      wrapper: {
        height: theme.values.itemHeight.tiny,
      },
    }
  }),
  'text:neutral1': createTagStyle((theme) => {
    return {
      text: {
        color: theme.colors.neutral1,
      },
    }
  }),
  smallish: createTagStyle((theme) => {
    return {
      wrapper: {
        ...theme.spacing.paddingVertical(0.5),
        ...theme.spacing.paddingHorizontal(1),

      },
      text: {
        ...assignTextStyle('p4')(theme).text,
      },
    }
  }),
}
