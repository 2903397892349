import { AnyFunction, useRef } from '@codeleap/common'

const DEFAULT_DEBOUNCE_TIMEOUT = 200

export const usePress = (handler: AnyFunction, debounce: number = DEFAULT_DEBOUNCE_TIMEOUT) => {
  const timeoutRef = useRef(null)

  const clearTimeoutRef = () => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = null
  }

  const onInvalidatePress = () => {
    if (timeoutRef.current !== null) {
      clearTimeoutRef()
    }
  }

  const onPress = () => {
    if (timeoutRef.current === null) {
      timeoutRef.current = setTimeout(() => {
        handler()
        clearTimeoutRef()
      }, debounce)
    }
  }

  return {
    onPress,
    onInvalidatePress,
  }
}
