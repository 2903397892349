import { verifyAchievementProgress } from '../../components/Achievements/Store'
import { api, React, useAppI18N } from '@/app'
import { PaginationResponse, QueryManager, ReactQuery, TypeGuards, useState, waitFor } from '@codeleap/common'
import { queryClient } from './queryClient'
import { ProfileOptions, useProfileOptions } from './session'
import { Organisation, Story, StoryCategory } from '@/types'
import { getListQueryParams, useFlatlistProps } from '@/utils'
import { analytics } from '../analytics'

const BASE_URL = 'story/'

export async function getstoryCategory() {
  const response = await api.get<StoryCategory[]>(`${BASE_URL + 'options/'}`)

  return response.data
}

export const SORT_BY = {
  likes: 'likes',
  created_datetime: 'created_datetime',
} as const

export type StoriesSortBy = typeof SORT_BY[keyof typeof SORT_BY]

const queryKeys = {
  storyCategory: ['story-category'],
}

export type StoryFilters = {
  search?: string
  organisation?: Organisation['id']
  categories?: (StoryCategory['value'])[]
  sort_by?: StoriesSortBy
  author_department?: string[]
}

export const storiesManager = new QueryManager({
  itemType: {} as Story,
  name: 'stories',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: StoryFilters) => {

    const response = await api.get<PaginationResponse<Story>>(BASE_URL, {
      params: {
        limit,
        offset,
        organization: filters?.organisation,
        ...filters,
        categories: getListQueryParams(filters?.categories),
        author_department: getListQueryParams(filters?.author_department),
      },
    })

    return response.data
  },
  createItem: async (data) => {

    const { file, ...rest } = data

    const requestData = {
      data: {
        ...rest,
      },
      file: null,
    }

    if (!!file && !TypeGuards.isString(file)) {
      requestData.file = file
    } else {
      requestData.file = 'null'
    }

    const response = await api.post(BASE_URL, requestData, { multipart: true })

    verifyAchievementProgress(response?.data?.achievement_progress)

    return response.data
  },
  updateItem: async (data) => {
    await waitFor(3000)
    const response = await api.patch<Story>(`${BASE_URL}/${data.id}/`, data)
    if (data.title.includes('Error')) {
      throw new Error('Error updating post')
    }
    return response.data
  },
  deleteItem: async (item) => {

    if (storiesManager.meta.throwErrorForDelete) {
      throw new Error('Error deleting post')
    }

    await api.delete(`${BASE_URL}/${item.id}/`)

    return item
  },
  retrieveItem: async (id) => {
    const response = TypeGuards.isNil(id) ? null : await api.get<Story>(`${BASE_URL}${id}/`)

    return response?.data
  },
  initialMeta: {
    optimisticLike: true,
    throwErrorForDelete: false,
    optimisticFavorite: true,
  },
  actions: {
    async toggleLike(manager, storyId: Story['id']) {

      const story = await manager.getItem(storyId, {
        fetchOnNotFoud: false,
        forceRefetch: false,
      })

      const isLiked = story?.likes?.is_liked

      const updatedStory: Story = {
        ...story,
        likes: {
          is_liked: !story.likes?.is_liked,
          count: isLiked ? story?.likes?.count - 1 : story?.likes?.count + 1,
        },
      }

      manager.setItem(updatedStory)

      try {
        const response = await api.post(`${BASE_URL}${storyId}/toggle_like/`, {})

        verifyAchievementProgress(response?.data?.achievement_progress)

      } catch (err) {
        logger.log({ err })
      }

      await manager.refreshItem(storyId)
      analytics?.track?.('story_like')

    },
  },
})

export const useStoryCategory = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.storyCategory,
    queryFn: () => {
      return getstoryCategory()
    },
    onSuccess: (data) => {
      storiesManager.updateItems(data)
    },
  })

  return {
    query,
    storyCategory: query.data,
  }
}
type UseStoriesParams = {
  organisationId?: Organisation['id']
}

export const useStories = (params: UseStoriesParams = {}) => {
  const { t } = useAppI18N()

  const [search, setSearch] = useState('')

  const [sort, setSort] = useState<StoryFilters['sort_by']>(SORT_BY.created_datetime)

  const [categories, setCategories] = useState<StoryCategory[]>([])

  const [authorDepartment, setAuthorDepartment] = useState<ProfileOptions['department'][number]['value'][]>([])

  const stories = storiesManager.use({
    filter: {
      search,
      sort_by: sort,
      categories: categories?.map?.((category) => category?.value),
      author_department: authorDepartment.map((department) => department?.id),
      organisation: params.organisationId,
    },
  })

  const profileOptions = useProfileOptions()

  const departments = profileOptions.data?.department || []

  const storiesListProps = useFlatlistProps(stories, {
    noMoreItemsText: t('learn.stories.noMoreStories'),
  })

  const handleToggleLike = (storyId) => {
    stories?.actions?.toggleLike(storyId)
  }

  const storyCategoryQuery = useStoryCategory()

  const storyCategories = React.useMemo(() => {
    return storyCategoryQuery?.storyCategory?.map(i => ({
      id: i.value,
      tag: i.label,
      created_datetime: null,
    }))
  }, [storyCategoryQuery.query.dataUpdatedAt])

  return {
    stories,
    storiesListProps,
    handleToggleLike,
    search,
    setSearch,
    sort,
    setSort,
    categories,
    setCategories,
    setAuthorDepartment,
    authorDepartment,
    departments,
    storyCategories,
  }
}

export type UseStoriesReturn = ReturnType<typeof useStories>
