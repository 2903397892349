import { variantProvider } from '@/app'
import { Link, Text, Button, View, SocialButton, Image } from '@/components'
import { AnyFunction, arePropsEqual, useI18N } from '@codeleap/common'
import React from 'react'

type RedirectLinkProps = {
  to: string
  text: string
  textAction: string
}

export const RedirectLink = (props: RedirectLinkProps) => {
  const { to, text, textAction } = props

  return (
    <Button
      // @ts-ignore
      component={Link}
      to={to}
      variants={['color:neutral1', 'alignSelfCenter', 'gap:0.5', 'noUnderline', 'fullWidth', 'large', 'border-radius:rounded']}
    >
      {text} <Text component='span' variants={['textCenter', 'color:primary3']} text={textAction} />
    </Button>
  )
}

type RedirectModalProps = {
  text: string
  textAction: string
  onPress: AnyFunction
}

export const RedirectModal = (props: RedirectModalProps) => {
  const { onPress, text, textAction } = props
  return (
    <Button
      debugName='RedirectModal:button'
      variants={['pill', 'large', 'row', 'alignCenter', 'justifyCenter', 'marginTop:2', 'gap:1', 'neutral2', 'alignSelfStart', 'paddingHorizontal:2', 'fullWidth']}
      onPress={onPress}
      css={styles.button}
    >
      <Text variants={['textCenter']} text={text} style={styles.buttonText} />
      <Text
        text={textAction}
        onPress={onPress}
        variants={['color:primary3', 'cursor:pointer']}
        style={styles.buttonText}
      />
    </Button>
  )
}

export const SocialProviders = ({ onPress }) => {
  const { t } = useI18N()

  return (
    <View variants={['column', 'gap:2']}>
      <Text
        text={t('Login.social')}
        variants={['p3', 'alignSelfCenter']}
      />

      <View variants={['row', 'gap:2', 'alignCenter', 'justifyCenter', 'alignSelfCenter']}>
        <SocialButton platform={'google'} handleSocial={onPress} />
        <SocialButton platform={'facebook'} handleSocial={onPress} />
        <SocialButton platform={'apple'} handleSocial={onPress} />
        <SocialButton platform={'linkedIn'} handleSocial={onPress} />
      </View>
    </View>
  )
}

const HeaderComponent = ({ img, centered = true, imageStyle = {} }) => {
  return (
    <View
      variants={['fullWidth', 'center', 'backgroundColor:primary1', !centered && 'alignEnd']}
      style={styles.imageWrapper}
    >
      <Image
        source={img}
        objectFit='contain'
        css={[centered ? styles.imageCentered : styles.image, imageStyle]}
      />
    </View>
  )
}

const Header = React.memo(HeaderComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['img'],
  })
})

export const AuthFormComponents = {
  RedirectLink,
  RedirectModal,
  SocialProviders,
  Header,
}

const IMAGE_HEIGHT = 250

const styles = variantProvider.createComponentStyle((theme) => ({
  button: {
    backgroundColor: theme.colors.neutral2,

    '&:hover': {
      backgroundColor: theme.colors.neutral3,
    },
  },
  buttonText: {
    fontWeight: '500',
  },
  imageWrapper: {
    height: IMAGE_HEIGHT,
    maxHeight: IMAGE_HEIGHT,

    [theme.media.down('small')]: {
      height: IMAGE_HEIGHT * 0.8,
      maxHeight: IMAGE_HEIGHT * 8,
    },
  },
  imageCentered: {
    background: theme.colors.primary1,
    height: '60%',
  },
  image: {
    background: theme.colors.primary1,
    height: '90%',
  },
}), true)
