import { AppImages, variantProvider } from '@/app'
import { Image } from '@/components'
import { PropsOf, TypeGuards } from '@codeleap/common'

type OrganisationLogoProps = Partial<PropsOf<typeof Image>> & {
  logo?: string
  size?: 'large' | 'medium' | 'small' | 'tiny'
}

export const OrganisationLogo = (props: OrganisationLogoProps) => {
  const { logo, size, ...imageProps } = props

  const _size = size ?? 'medium'

  const hasOrgLogo = TypeGuards.isString(logo)

  const imageSource = logo || AppImages.organisationPlaceholder

  return <Image
    source={imageSource}
    objectFit={hasOrgLogo ? 'contain' : 'cover'}
    {...imageProps}
    css={[
      orgLogoStyles.default,
      orgLogoStyles[_size],
      imageProps.style,
    ]}
  />
}

export const orgLogoStyles = variantProvider.createComponentStyle(theme => ({
  large: {
    height: 112,
    width: 112,
    borderRadius: theme.borderRadius.small,

    [theme.media.down('small')]: {
      borderRadius: theme.borderRadius.medium,
    }
  },
  medium: {
    height: 52,
    width: 52,
    borderRadius: theme.borderRadius.tiny,
  },
  small: {
    height: 48,
    width: 48,
    borderRadius: theme.borderRadius.tiny,
  },
  tiny: {
    height: 32,
    width: 32,
    borderRadius: theme.borderRadius.tiny,
  },
  default: {
    // borderRadius: theme.borderRadius.medium,
  },
  placeholder: {
    ...theme.effects.heavy,
    backgroundColor: theme.colors.neutral1,
  },
}), true)
