import { api, React } from '@/app'
import { onUpdate, PaginationResponse, QueryManager, TypeGuards, useI18N, useState } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { LessonCategory, Module } from '@/types'
import { getListQueryParams, useFlatlistProps } from '@/utils'
import { useSession } from '../session'

const BASE_URL = 'learn/'

export const modulesManager = new QueryManager({
  itemType: {} as Module,
  name: 'modules',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {

    const response = await api.get<PaginationResponse<Module>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
        tags: getListQueryParams(filters?.tags),
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Module>(`${BASE_URL}${id}/`)

    return response.data
  },
})
type UseModulesParams = {
  sources?: string | string[]
}

export const useModules = (params?: UseModulesParams) => {
  const { sources = null } = params

  const { t } = useI18N()

  const moduleStatus = [
    {
      label: t('learn.learnScreen.filterStatus.all'),
      value: null,
      debugName: 'select filter all lessons',
    },
    {
      label: t('learn.learnScreen.filterStatus.progress'),
      value: false,
      debugName: 'select filter in progress lessons',
    },
    {
      label: t('learn.learnScreen.filterStatus.done'),
      value: true,
      debugName: 'select filter done lessons',
    },
  ]

  const [status, setStatus] = useState(moduleStatus?.[0]?.value)
  const [search, setSearch] = useState('')
  const [tags, setTags] = useState<LessonCategory[]>([])

  const { isLoggedIn } = useSession()

  const modules = modulesManager.use({
    filter: {
      search: search?.length >= 1 ? search : null,
      tags: tags?.map(_tag => _tag?.id),
      sources: sources && TypeGuards.isArray(sources) ? sources.join(',') : sources,
    },
  })

  const listProps = useFlatlistProps(modules, {
    noMoreItemsText: t('learn.noMoreCollections'),
  })

  const data = React.useMemo(() => {
    if (TypeGuards.isNil(status)) return modules?.items

    if (status === false) {
      return modules?.items?.filter(module => module?.done === status && module?.progress > 0)
    }

    return modules?.items?.filter(module => module?.done === status)
  }, [status, modules?.items])

  onUpdate(() => {
    if (isLoggedIn) {
      modules?.refresh()
    }
  }, [isLoggedIn])

  return {
    modulesListProps: {
      ...listProps,
      data,
    },
    moduleStatus,
    tags,
    setTags,
    search,
    setSearch,
    status,
    setStatus,
    data,
  }
}

export type UseModulesReturn = ReturnType<typeof useModules>
