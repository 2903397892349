import { I18N } from '@/app'
import { Button, Text, View } from '@/components'
import { arePropsEqual } from '@codeleap/common'
import React from 'react'

export function Component({ onLeave, onNext }) {
  return (
    <View variants={['center', 'column']}>
      <Text variants={['h4']} text={I18N.t('learn.components.closeLesson.title')} />
      <Text variants={['p1', 'textCenter', 'marginVertical:2']} text={I18N.t('learn.components.closeLesson.body')} />
      <View variants={['fullWidth', 'row', 'center']}>
        <Button
          onPress={onNext}
          debugName='lesson leave - cancel'
          text={I18N.t("cancel")}
          variants={['flex']}
        />
        <Button
          onPress={onLeave}
          debugName='lesson leave - close lesson'
          text={I18N.t('learn.components.closeLesson.close')}
          variants={['outline', 'marginLeft:2', 'flex']}
        />
      </View>
    </View>
  )
}

export const LessonLeave = React.memo(Component, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['onNext'],
  })
})
