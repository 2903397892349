import React from 'react'

type LessonContext = {
  inSwipe: React.MutableRefObject<boolean>
}

export const LessonContext = React.createContext({} as LessonContext)

type ProviderProps = React.PropsWithChildren<{}>

export const LessonContextProvider = (props: ProviderProps) => {
  const { children } = props
  const inSwipe = React.useRef(false)

  return <LessonContext.Provider
    value={{ inSwipe }}
  >
    {children}
  </LessonContext.Provider>
}

export const useLessonContext = () => React.useContext(LessonContext)
