import React from 'react'
import { ComponentVariants, getNestedStylesByKey, PropsOf, StylesOf, TypeGuards, useCallback, useDefaultComponentStyle } from '@codeleap/common'
import { ButtonProps, ModalProps } from '@codeleap/web'
import { Modal, Text, View, Button } from '@/components'
import { AlertPresets, AlertComposition } from '@/app/stylesheets/Alert'

type CommonProps = {
  title: string
  body?: string
} & Partial<DismissButtonProps>

type AlertType = 'info' | 'error' | 'warn' | 'destructive'

type AlertProps = CommonProps
  & Omit<PropsOf<typeof Modal>, 'styles' | 'variants'> 
  & ActionButtonProps
  & ComponentVariants<typeof AlertPresets> & {
    type: AlertType
    orientation?: 'row' | 'column'
    options?: ButtonProps[]
    styles?: StylesOf<AlertComposition>
    closeOnDismiss?: boolean
    closeOnAction?: boolean
  }

type ActionButtonProps = {
  onAction?: () => void
  actionButtonProps?: Partial<ButtonProps>
}

type DismissButtonProps = {
  onDismiss?: () => void
  dismissButtonProps?: Partial<ButtonProps>
  info?: boolean
}

function verifyType(type: AlertType) {
  return {
    isInfo: type === 'info',
    isWarn: type === 'warn',
    isError: type === 'error',
    isDestructive: type === 'destructive',
  }
}

function ActionButton({ onAction, actionButtonProps, isDestructive }: ActionButtonProps & { isDestructive: boolean }) {
  const initialText = isDestructive ? 'Delete' : 'Accept'
  return (
    <Button
      text={initialText}
      debugName={`${initialText} button from alert modal`}
      variants={['flex', isDestructive && 'destructive', 'large', 'pill']}
      {...actionButtonProps}
      onPress={onAction}
    />
  )
}

function DismissButton({ onDismiss, dismissButtonProps, info }: DismissButtonProps) {
  return (
    <Button
      text='Dismiss'
      debugName='Dismiss button from alert modal'
      variants={[!info && 'outline', 'flex', 'large', 'pill']}
      {...dismissButtonProps}
      onPress={onDismiss}
    />
  )
}

export const Alert = (props: AlertProps) => {
  const {
    body,
    children,
    variants = [],
    styles,
    options = [],
    orientation = 'row',
    responsiveVariants = {},
    closeOnDismiss = true,
    closeOnAction = true,
    type,
    onAction,
    actionButtonProps,
    dismissButtonProps,
    onDismiss,
    ...modalProps
  } = props

  const { isWarn, isDestructive } = React.useMemo(() => verifyType(type), [])

  const variantStyles = useDefaultComponentStyle<'u:Alert', typeof AlertPresets>('u:Alert', {
    variants,
    responsiveVariants,
    styles,
  })

  const modalStyles = React.useMemo(() => {
    return getNestedStylesByKey('modal', variantStyles)
  }, [variantStyles])

  const isRow = orientation === 'row'

  const showActionButton = isWarn || isDestructive

  const handleDismiss = () => {
    if (TypeGuards.isFunction(onDismiss)) onDismiss?.()

    if (closeOnDismiss) modalProps?.toggle?.()
  }

  const handleAction = () => {
    if (TypeGuards.isFunction(onAction)) onAction?.()

    if (closeOnAction) modalProps?.toggle?.()
  }

  const ActionComponent = useCallback(() => {
    return (
      <View variants={['flex']}>
        <ActionButton onAction={handleAction} actionButtonProps={actionButtonProps} isDestructive={isDestructive} />
      </View>
    )
  }, [handleAction])

  return (
    <Modal showClose={false} {...modalProps} styles={modalStyles} variants={['centered', type, ...variants]}>
      {body ? <Text text={body} css={variantStyles.body} /> : null}

      {children}

      <View
        variants={[orientation]}
        css={variantStyles.actions}
      >
        {showActionButton && !isRow ? <ActionComponent /> : null}

        <View variants={['flex']}>
          <DismissButton info={type === 'info'} onDismiss={handleDismiss} dismissButtonProps={dismissButtonProps} />
        </View>

        {options.length > 0 && options.map((button) => (
          <Button {...button} key={button.text} css={variantStyles.option} />
        ))}

        {showActionButton && isRow ? <ActionComponent /> : null}
      </View>
    </Modal>
  )
}
