import React, { useRef, useState } from 'react'
import { Settings, variantProvider } from '@/app'
import { Text, Image, View, StoryAvatar, Tag, Modal, Link, ActionIcon, GradientModal, ReplyDisclaimer, CardReply, PlayerYoutube } from '@/components'
import { ModalProps } from '@codeleap/web'
import { ensureHttps, shareStory, transformSnakeCaseToTitleCase, useIsMobile } from '@/utils'
import { Story } from '@/types'
import { APIClient } from '@/services'
import { AppStatus, shareStoryId, useAppSelector } from '@/redux'
import { arePropsEqual, onUpdate, PropsOf, TypeGuards } from '@codeleap/common'

type LikeProps = {
  onToggleLike: () => void
  likesCount: number
  isLiked: boolean
} & PropsOf<typeof View>

const LIKE_DEBOUNCE_TIME = 1000

export const Like = ({ isLiked, likesCount, onToggleLike, ...props }: LikeProps) => {
  const [liked, setLiked] = useState(isLiked)

  const { profile } = APIClient.Session.useSession()

  const changeTimer = useRef(null)

  let shift = 0

  if (liked && !isLiked) shift = 1
  if (!liked && isLiked) shift = -1

  onUpdate(() => setLiked(isLiked), [isLiked])

  const onPress = () => {
    if (changeTimer.current) clearTimeout(changeTimer.current)
    const newState = !liked
    const initialState = liked

    setLiked(!profile ? false : newState)

    changeTimer.current = setTimeout(() => {
      if (newState === initialState) return
      onToggleLike()
    }, LIKE_DEBOUNCE_TIME)
  }

  const _likesCount = likesCount + shift

  return (

    <View {...props}>
      <ActionIcon
        debugName='story:like'
        onPress={onPress}
        icon={`${liked ? 'thumbs-up-fill' : 'thumbs-up'}`}
        variants={['normalize', 'primary3', 'iconSize:3']}
      />
      <Text variants={['bold']} text={String(_likesCount)} />
    </View>

  )
}

const ModalHeader = ({ story, toggle }) => {
  return (
    <View variants={['alignCenter', 'justifySpaceBetween', 'marginBottom:4']} style={styles.header}>
      <StoryAvatar showOrganisation={true} story={story} variants={['alignCenter', 'row']} />
      <ActionIcon
        debugName='story:close'
        icon={'x'}
        variants={['normalize', 'neutral10', 'alignSelfStart']}
        onPress={toggle}
      />
    </View>
  )
}

const StoryContentComponent = ({ story }: { story: Story }) => {
  const stories = APIClient.Stories.useStories()

  const onToggleLike = () => stories.stories.actions.toggleLike(story?.id)

  const likes = story?.likes
  const likesCount = likes?.count
  const isLiked = likes?.is_liked

  const overview = story?.overview
  const headline = story?.headline
  const picture = story?.picture
  const storyCategories = story?.categories
  const storyUrl = story?.url

  const hasPicture = TypeGuards.isString(picture)

  const _storyUrl = ensureHttps(storyUrl)

  const isReplied = story?.replies?.length > 0
  const organisationName = story?.replies?.[0]?.organization_name

  const likeProps: LikeProps = {
    isLiked,
    likesCount,
    onToggleLike: onToggleLike,
  }

  return (
    <>
      <View
        variants={['column', 'gap:2', 'fullWidth', 'padding:4', 'paddingTop:0', isReplied ? 'paddingBottom:0' : null]}
        responsiveVariants={{ 'small': ['padding:2', 'paddingBottom:2'] }}
      >
        <View down={'small'}>
          <StoryAvatar story={story} variants={['alignCenter', 'row', 'margin:0']} />
        </View>

        {!!story?.video ? <PlayerYoutube.Preview video={story?.video} /> : null}

        {hasPicture ? (
          <Image
            source={picture}
            style={styles.image}
            objectFit={'contain'}
            variants={['marginBottom:2']}
          />
        ) : null}

        {isReplied && <ReplyDisclaimer organisationName={organisationName} />}

        <View variants={['row', 'justifySpaceBetween', 'fullWidth', 'alignCenter']}>
          <View variants={['column', 'gap:1', 'fullWidth']}>
            <View variants={['alignCenter', 'fullWidth', 'justifySpaceBetween']}>
              <Text
                variants={['extraBold', 'h1', 'wordBreak-word', 'marginTop:-1.25']}
                responsiveVariants={{ mid: ['extraBold', 'h2'] }}
                text={`${headline}`}
              />
              <View up={'small'} variants={['row', 'alignCenter', 'alignSelfStart', 'gap:2', 'marginLeft:2']}>
                <Like {...likeProps} variants={['row', 'alignCenter', 'gap:1']} />
                <ActionIcon
                  debugName='story:share'
                  variants={['normalize', 'primary3', 'iconSize:3']}
                  icon={'share'}
                  onPress={() => shareStory(story)}
                />
              </View>
            </View>
            <Text variants={['p1', 'wordBreak-word']} text={`${overview}`} />
          </View>

        </View>

        {story?.questions?.some(question => question?.answer) ? (
          <View variants={['column', 'gap:2']}>
            {story?.questions?.map((question, i) => question?.answer ? (
              <View key={i + 'story-answer'} variants={['column']}>
                <Text variants={['color:neutral9', 'bold', 'h4', 'marginBottom:1']} text={question.question} />
                <Text text={question.answer} variants={['color:neutral6', 'wordBreak-word']} />
              </View>
            ) : null)}
          </View>
        ) : null}

        {storyCategories?.length > 0 ? (
          <View variants={['gap:1.5', 'wrap', 'fullWidth']}>
            {storyCategories?.map((item) => (
              <Tag
                debugName={`Tag ${item}`}
                key={item + 'story-category'}
                variants={['bg:neutral3', 'color:neutral10', 'border-radius:tiny', 'textCenter', 'justifyCenter', 'smallish']}
                text={transformSnakeCaseToTitleCase(String(item))}
              />
            ))}
          </View>
        ) : null}

        {storyUrl ? <Link text={storyUrl} variants={['color:primary3', 'cursor:pointer', 'noUnderline']} to={_storyUrl} openNewTab /> : null}

        <View down={'small'}>
          <Like onToggleLike={onToggleLike} {...likeProps} variants={['row', 'alignCenter', 'gap:1']} />
        </View>

      </View>
      {isReplied && (
        <CardReply replies={story.replies} />
      )}
    </>
  )
}

export const StoryContent = StoryContentComponent

const StoryDetails = (props: Partial<ModalProps>) => {
  const isOpen = useAppSelector(store => store.AppStatus.modals.storyDetail)
  const storyId = shareStoryId(store => store?.id)

  const toggle = TypeGuards.isFunction(props?.toggle) ? props?.toggle : () => AppStatus.setModal('storyDetail')

  const { data } = APIClient.Stories.storiesManager.useItem({ id: storyId })

  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      // @ts-ignore
      <GradientModal
        toggle={toggle}
        visible={isOpen}
        debugName={'ReviewCardModal'}
        onSharePress={() => shareStory(data)}
        bodyStyle={styles.gradientBody}
        {...props}
      >
        <StoryContent story={data} />
      </GradientModal>
    )
  }

  return (
    <Modal
      debugName={'Story Detail Modal'}
      visible={isOpen}
      toggle={toggle}
      showClose={true}
      withScrollContainer
      closable={true}
      closeIconName={'thumbs-up'}
      // @ts-ignore
      variants={['centered']}
      styles={{
        'box': styles.box,
        'body': styles.body,
      }}
      renderHeader={() => <ModalHeader toggle={toggle} story={data} />}
      {...props}
    >
      <StoryContent story={data} />
    </Modal>
  )
}

export default StoryDetails

const MODAL_WIDTH = 700

const styles = variantProvider.createComponentStyle((theme) => ({
  image: {
    ...theme.presets.fullWidth,
    height: 'auto',
    width: '100%',
    borderRadius: theme.borderRadius.medium,
    aspectRatio: Settings.Images.Stories.aspect,
  },
  box: {
    width: '100vw',
    maxWidth: MODAL_WIDTH,
    padding: theme.spacing.value(0),
  },
  body: {
    maxHeight: '70svh',
    overflowY: 'auto',

    padding: theme.spacing.value(0),
    paddingTop: theme.spacing.value(0),
  },
  header: {
    paddingTop: theme.spacing.value(4),
    paddingLeft: theme.spacing.value(4),
    paddingRight: theme.spacing.value(4),
  },
  gradientHeader: {
    ...theme.spacing.padding(4),
  },
  gradientBody: {
    [theme.media.down('small')]: {
      flex: 1,
      paddingTop: theme.spacing.value(2),
      paddingLeft: theme.spacing.value(0),
      paddingRight: theme.spacing.value(0),
      marginTop: theme.spacing.value(1),
      background: theme.colors.neutral1,
      overflow: 'scroll',
      borderRadius: 32,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}), true)
