import { React, variantProvider } from '@/app'
import { View } from '@/components'
import { arePropsEqual, useRef } from '@codeleap/common'
import Confetti from 'react-confetti'

const ConfettiAnimationComponent = ({ visible, width }) => {
  const completedRef = useRef(false)

  if (!!completedRef.current || !visible) return null

  return (
    <View style={styles.wrapper}>
      <Confetti
        width={width}
        height={width * 2}
        numberOfPieces={100}
        initialVelocityY={5}
        recycle={false}
        tweenDuration={100}
        onConfettiComplete={() => {
          completedRef.current = true
        }}
      />
    </View>
  )
}

export const ConfettiAnimation = React.memo(ConfettiAnimationComponent, (previous, next) => {
  return arePropsEqual(previous, next, { check: ['visible'] })
})

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.absolute,
    top: theme.spacing.value(0),
    left: theme.spacing.value(0),
    right: theme.spacing.value(0),
    zIndex: 12,
  }
}), true)
