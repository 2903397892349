import { React, Settings, AppImages, Theme } from '@/app'
import { View, CenterWrapper, Footer, Header, Image } from '@/components'
import { CenterWrapperProps } from './CenterWrapper'
import SEO, { SEOProps } from './SEO'
import {
  ComponentVariants,
  ImageComposition,
  StylesOf,
  useDefaultComponentStyle,
  useIsomorphicEffect,
} from '@codeleap/common'
import { useUpdateLocale } from '@/utils'
import { ReactElement } from 'react'
import { PageStyles } from '@/app/stylesheets'
import { PageComposition } from '@/app/stylesheets/Page'
import { useMediaQuery } from '@codeleap/web'

export type PageProps = {
  showHeader?: boolean
  showSubHeader?: boolean
  showHeaderImage?: boolean
  subHeader?: ReactElement
  showFooter?: boolean
  showBackgroundImage?: boolean
  backgroundImageSource?: string
  backgroundImageStyle?: StylesOf<ImageComposition>
  pageTitle?: string
  appendNameToPageTitle?: boolean
  centerContent?: boolean
  SEOProps?: Partial<SEOProps>
  className?: string
  styles?: StylesOf<PageComposition>
} & Partial<Omit<CenterWrapperProps, 'variants' | 'styles'>> &
  ComponentVariants<typeof PageStyles>

const defaultProps: Partial<PageProps> = {
  showHeader: true,
  showHeaderImage: true,
  showSubHeader: false,
  showFooter: false,
  appendNameToPageTitle: false,
  centerContent: true,
  showBackgroundImage: false,
  backgroundImageSource: null,
  SEOProps: {},
}

const BREAKPOINT = 'mid'

const SubHeader = ({ showSubHeader, children, variantStyles, showHeaderImage }) => {
  const visible = showSubHeader ? 'visible' : 'hidden'

  return (
    <View
      css={[variantStyles.subHeader, variantStyles[`subHeader:${visible}`]]}
    >
      {children}

      {showHeaderImage ? (
        <Image
          css={variantStyles.subHeaderBackground}
          source={AppImages.GradientBackground}
          alt='Gradient Background'
        />
      ) : null}
    </View>
  )
}

export const Page: React.FC<PageProps> = (props) => {
  const allProps = {
    ...defaultProps,
    ...props,
  }
  const {
    showHeader,
    showSubHeader,
    showFooter = false,
    subHeader,
    showHeaderImage,
    pageTitle,
    appendNameToPageTitle,
    centerContent,
    showBackgroundImage,
    backgroundImageSource,
    backgroundImageStyle,
    SEOProps,
    className,
    children,
    responsiveVariants,
    variants,
    styles,
    ...centerWrapperProps
  } = allProps

  useIsomorphicEffect(() => {
    document.ontouchmove = function (e) { // NOTE: This stops safari from fucking up the scroll
      e.preventDefault()
    }
    document.ontouchstart = function (e) {
      e.preventDefault()
    }
    document.ontouchmove = function (e) {
      e.preventDefault()
    }
  }, [])

  const variantStyles = useDefaultComponentStyle<'u:Page', typeof PageStyles>(
    'u:Page',
    {
      responsiveVariants,
      variants,
      styles,
    },
  )

  const appendedTitle = appendNameToPageTitle ? ` | ${Settings.AppName}` : ''
  const SEOTitle = pageTitle && `${pageTitle} ${appendedTitle}`

  const imageStyles = backgroundImageStyle ?? variantStyles.backgroundImage

  const ContentWrapper = showBackgroundImage ? View : React.Fragment
  const InnerWrapper = centerContent ? CenterWrapper : View

  const contentWrapperProps = showBackgroundImage && {
    variants: ['column', 'fullWidth', 'absolute'],
  }

  const innerWrapperProps = centerContent && {
    ...centerWrapperProps,
    styles: centerWrapperProps?.styles,
  }

  // useUpdateLocale()

  const contentProps = showBackgroundImage ? contentWrapperProps : {}

  const innerProps = centerContent
    ? {
      variants: ['column'],
      ...innerWrapperProps,
    }
    : {}

  return (
    <View
      variants={['column']}
      style={variantStyles.wrapper}
      className={className}
    >
      <SEO title={SEOTitle} {...SEOProps} />

      {showBackgroundImage && (
        <Image
          source={backgroundImageSource}
          objectFit='cover'
          style={imageStyles}
        />
      )}

      <ContentWrapper {...contentProps}>
        {showHeader && <Header />}

        <SubHeader showHeaderImage={showHeaderImage} showSubHeader={showSubHeader} variantStyles={variantStyles}>
          <InnerWrapper style={variantStyles.subHeaderChildren}>
            {subHeader}
          </InnerWrapper>
        </SubHeader>

        <InnerWrapper
          {...innerProps}
          css={[
            variantStyles.innerWrapper,
            showSubHeader && variantStyles.topBorders,
          ]}
        >
          {children}
        </InnerWrapper>
      </ContentWrapper>

      {showFooter ? <Footer /> : null}
    </View>
  )
}
