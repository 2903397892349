import { useAppI18N, variantProvider } from '@/app'
import { Text, Button, Image, View, Modal } from '..'
import { CategoryData } from '@/types'
import { APIClient } from '@/services'
import { useMemo } from 'react'

export const CategoryExplainer = (props: { visible: boolean; toggle: () => void; category: CategoryData }) => {
  const { toggle, visible, category } = props
  const { t } = useAppI18N()

  const { categories, query } = APIClient.Organisations.useCategories()

  const currentId = category?.category ? `${category?.category}_${category?.id}` : category?.id

  const imageArr = useMemo(() => {
    return Object.values(categories ?? {}).reduce((acc, category) => {
      const categoryImage = {
        id: category.id,
        image: category.web_icon,
      }

      const subcategoryImages = category.sub_categories.map(subcategory => ({
        id: `${subcategory.category}_${subcategory.id}`,
        image: subcategory.web_icon,
      }))

      return [
        ...acc,
        categoryImage,
        ...subcategoryImages,
      ]
    }, [])
  }, [query.dataUpdatedAt])

  return (
    <Modal
      id={'category_explainer'}
      debugName='Cateogry explainer'
      toggle={toggle}
      visible={visible}
      styles={{ box: styles.box }}
      debugName='Category explainer modal'
      showClose={false}
      variants={['centered']}
    >
      <View variants={['center', 'column', 'gap:2']}>
        <Text variants={['h4', 'center']} text={category?.name} />

        {
          imageArr.map(image => {
            const isCurrent = image.id === currentId

            return (
              <Image
                key={image.id}
                source={image.image}
                variants={['color:neutral9']}
                style={{
                  ...styles.image,
                  display: isCurrent ? 'auto' : 'none',

                }}
              />
            )

          })
        }

        <Text variants={['h5', 'center', 'textCenter', 'color:neutral6']} text={category?.description} />
        <Button variants={['fullWidth']} text={t('dismiss')} onPress={toggle} debugName={'Dismiss Modal'} />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  box: {
    maxWidth: 480,
  },
  image: {
    width: theme.values.iconSize[5],
    height: theme.values.iconSize[5],
    filter: 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(693%) hue-rotate(0deg) brightness(97%) contrast(100%)',
  },
}), true)
