import { Theme } from '@/app'
import { AchievementLevel } from '@/types'
import { Image, View } from '@/components'
import { useIsMobile } from '@/utils'

type HexagonProps = {
  strokeColor: string
  backgroundColor: string
  size: number
  children?: React.ReactNode
} & React.SVGProps<SVGSVGElement>

const hexagonPath = 'M110.59 10.5096L193.179 58.1928C197.305 60.5746 199.846 64.9763 199.846 69.7398V165.106C199.846 169.87 197.305 174.271 193.179 176.653L110.59 224.336C106.464 226.718 101.382 226.718 97.2564 224.336L14.6667 176.653C10.5413 174.271 8 169.87 8 165.106V69.7398C8 64.9763 10.5413 60.5746 14.6667 58.1928L97.2564 10.5096C101.382 8.12787 106.464 8.12787 110.59 10.5096Z'

const svgBoxWidth = 208
const svgBoxHeight = 235

const svgBoxRatio = svgBoxWidth / svgBoxHeight

export const Hexagon = (props: HexagonProps) => {
  const {
    strokeColor,
    backgroundColor,
    children,
    size = 100,
    ...svgProps
  } = props

  return <View variants={['relative']}>
    <svg
      width={size}
      height={size / svgBoxRatio}
      viewBox={`0 0 ${svgBoxWidth} ${svgBoxHeight}`}
      fill='none'
      {...svgProps}
    >
      <path
        strokeWidth={16}
        d={hexagonPath}
        stroke={strokeColor}
        fill={backgroundColor}
      />
    </svg>

    {children}
  </View>
}

type LevelBadgeProps = {
  level: AchievementLevel
  variant?: 'small' | 'large' | 'hidden'
}

export const LevelBadge = (props: LevelBadgeProps) => {
  const { level, variant = 'large' } = props

  const isMobile = useIsMobile()

  const isLarge = variant === 'large'

  const { imageSize, size, opacity } = LevelBadge.sizes[variant + (isMobile ? 'Mobile' : '')]

  return <Hexagon
    strokeColor={Theme.colors.light.neutral1}
    backgroundColor={level?.bg_color_light}
    size={size}
    style={{
      opacity,
      marginLeft: isLarge ? Theme.spacing.value(2) : 0,
      marginRight: isLarge ? Theme.spacing.value(2) : 0,
      zIndex: 1,
    }}
  >
    <View variants={['absolute', 'center']} style={{ opacity, zIndex: 100, top: 0, left: 0, right: 0, bottom: 0 }}>
      <Image source={level?.image} style={{ width: imageSize, height: imageSize }} />
    </View>
  </Hexagon>
}

LevelBadge.sizes = {
  hidden: {
    imageSize: 112,
    size: 240,
    opacity: 0,
  },
  small: {
    imageSize: 112,
    size: 240,
    opacity: 1,
  },
  smallMobile: {
    imageSize: 64,
    size: 160,
    opacity: 1,
  },
  large: {
    imageSize: 150,
    size: 320,
    opacity: 1,
  },
  largeMobile: {
    imageSize: 112,
    size: 240,
    opacity: 1,
  },
}
