import React from 'react'
import { Button, Text, View } from '@/components'
import { AppStatus } from '@/redux'
import { useI18N } from '@codeleap/common'
import { navigate } from 'gatsby'

type ForgotPasswordFormProps = {
  isModal?: boolean
}

export const ForgotPasswordAfterForm = ({ isModal = true }: ForgotPasswordFormProps) => {
  const { t } = useI18N()

  const toLogin = () => {
    if (isModal) {
      AppStatus.transitionModals(['forgotAfter', 'login'])
    } else {
      navigate('/auth/login/')
    }
  }

  const onTryResendEmail = () => {
    if (isModal) {
      AppStatus.transitionModals(['forgotAfter', 'forgotBefore'])
    } else {
      navigate('/auth/forgot/')
    }
  }

  return (
    <View variants={['flex', 'column']}>
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('ForgotPassword.title2')}
      />

      <View variants={['column', 'marginTop:-3', 'marginBottom:4']}>
        <Text variants={['p1', 'textCenter']} text={t('ForgotPassword.sub1')} />&nbsp;
        <Text variants={['p1', 'textCenter']} text={t('ForgotPassword.sub2')} />&nbsp;
        <Text variants={['p1', 'textCenter']} text={t('ForgotPassword.sub3')} />&nbsp;
      </View>

      <View variants={['column', 'gap:2', 'marginTop:auto', 'alignSelfCenter', 'fullWidth']}>
        <Button onPress={onTryResendEmail} aria-label={`${t('ForgotPassword.txt1')} ${t('ForgotPassword.txt2')}`} debugName={'Resend email'} variants={['outline', 'pill', 'large', 'row', 'gap:0.5', 'alignCenter', 'justifyCenter', 'paddingHorizontal:2']}>
          <Text text={t('ForgotPassword.txt1')} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          <Text text={t('ForgotPassword.txt2')} variants={['medium', 'color:primary3', 'underline']} />
        </Button>
      </View>
    </View>
  )
}

