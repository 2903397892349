import React from 'react'
import { ActionIcon, Button, Modal, SearchInput, View, Text } from '@/components'
import { useI18N, useState } from '@codeleap/common'
import { useModal } from '@/utils'
import { Theme, variantProvider } from '@/app'
import { analytics } from '@/services'

type SearchLessonsProps = {
  onValueChange: (value: string) => void
  value: string
  section: string
}

export const SearchLearn = (props: SearchLessonsProps) => {
  const {
    onValueChange,
    value,
    section,
  } = props

  const _modal = useModal()
  const [search, setSearch] = useState('')

  const { t } = useI18N()

  const onApplySearch = () => {
    if (isLessonsSection && search.trim()) {
      analytics.track('lesson_search', { terms: search })
    }
    onValueChange(search)
    _modal.toggle(false)
  }

  const onClear = () => {
    setSearch('')
    onValueChange('')
    _modal.toggle(false)
  }

  const isLessonsSection = section === 'lessons'

  return (
    <>
      <ActionIcon
        debugName='toggle modal search lessons'
        icon='search'
        onPress={_modal.toggle}
        variants={['learn:filter', !!value && 'bg-primary1', 'marginRight:1']}
        tabIndex={0}
      />

      <Modal
        {..._modal}
        debugName={`Modal search lessons`}
        variants={['centered']}
        showClose={false}
        scroll={false}
        styles={{
          'box': styles.box,
          'header': styles.header,
          'body': styles.body,
        }}
      >
        <View variants={['row', 'center', 'justifySpaceBetween', 'fullWidth', 'marginBottom:-0.5']}>
          <View />

          <Text variants={['h2', 'color:neutral10']} text={t('learn.components.searchModal.title')} />

          <ActionIcon
            debugName='toggle modal search lessons'
            icon='x'
            onPress={_modal.toggle}
            variants={['minimal', 'normalize', 'neutral4', 'iconSize:4']}
          />
        </View>

        <SearchInput
          variants={['noError', 'fullWidth', 'borderRadius:small']}
          placeholder={isLessonsSection ? t('learn.components.searchModal.placeholderLessons') : t('learn.components.searchModal.placeholderStories')}
          onSearchChange={() => null}
          onValueChange={setSearch}
          value={search}
          debounce={null}
          debugName='learn:search'
          clearable={false}
        />

        <View
          variants={['row', 'center', 'justifySpaceBetween', 'gap:2', 'fullWidth']}
        >
          <Button
            variants={['flex', 'outline', 'border-radius:rounded']}
            text={t('learn.components.searchModal.buttonCancel')}
            debugName={`Modal search cancel`}
            onPress={onClear}
          />

          <Button
            variants={['flex', 'border-radius:rounded']}
            text={t('learn.components.searchModal.buttonSubmit')}
            debugName={`Modal search apply`}
            onPress={onApplySearch}
          />
        </View>
      </Modal>
    </>
  )
}

const MODAL_WIDTH = 500

const styles = variantProvider.createComponentStyle(theme => ({
  box: {
    width: `calc(100vw - ${theme.spacing.value(2)}px)`,
    maxWidth: MODAL_WIDTH,
    padding: theme.spacing.value(4),

    [theme.media.down('small')]: {
      padding: theme.spacing.value(2),
    },
  },
  body: {
    ...theme.presets.center,
    gap: theme.spacing.value(4),

    [theme.media.down('small')]: {
      gap: theme.spacing.value(2),
    },
  },
  header: {
    margin: theme.spacing.value(0),
  },
}), true)
