import { Theme, variantProvider, Settings, AppImages, assignTextStyle } from '@/app'
import { View, Logo, Text, Link, Icon } from '@/components'
import { useI18N } from '@codeleap/common'
import { useMediaQuery } from '@codeleap/web'
import { format } from 'date-fns'
import React from 'react'
import { CenterWrapper } from './CenterWrapper'

export const BASE_SITE_URL = 'https://hurd.world/'

const linksCol1 = [
  { text: 'Home', to: BASE_SITE_URL },
  { text: 'Organisations', to: 'organisations/' },
  { text: 'Act', to: 'act/' },
  { text: 'Learn', to: 'learn/' },
  { text: 'For Employers', to: BASE_SITE_URL + 'for-employers/' },
  { text: 'Blog', to: BASE_SITE_URL + 'blog/' },
].map((p) => ({ ...p, to: `${p.to}` }))

const linksCol2 = [
  { text: 'About', to: Settings.ContactINFO.About },
  { text: 'Community Guidelines', to: Settings.ContactINFO.CommunityGuidelines },
  { text: 'Cookies', to: Settings.ContactINFO.Cookies },
  { text: 'Help', to: Settings.ContactINFO.Help },
  { text: 'Privacy Policy', to: Settings.ContactINFO.PrivacyPolicy },
  { text: 'References', to: Settings.ContactINFO.References },
  { text: 'Terms of Use', to: Settings.ContactINFO.TermsOfUse },
].map((p) => ({ ...p, to: `${p.to}` }))

const socialMediaLinks = [
  {
    url: Settings.Social.FaceURL,
    icon: 'facebook-fill',
    size: 22,
  },
  {
    url: Settings.Social.LinkedinURL,
    icon: 'linkedin-fill',
    size: 22,
  },
  {
    url: Settings.Social.XUrl,
    icon: 'twitter-bird',
    size: 22,
  },
  {
    url: Settings.Social.InstagramURL,
    icon: 'instagram',
    size: 22,
    css: { stroke: Theme.colors.light.neutral1, fill: 'none' },
  },
]

function LinkCol({ col, title }) {
  const { t } = useI18N()
  const links = col.map(({ to, text }, index) => {
    const isExternal = to?.startsWith('http')

    return (
      <Link
        key={index}
        to={isExternal ? to : '/' + to}
        text={t(`links.${text}`)}
        css={styles.footerNavText}
        variants={['noUnderline', 'textCenter', 'capitalize']}
      />
    )
  })

  return (
    <View
      variants={['column', 'center']}
      responsiveVariants={{
        mid: ['alignCenter'],
      }}
    >
      <Text variants={['h3', 'color:neutral1', 'textCenter', 'regular', 'marginBottom:2', 'capitalize']} text={title} />
      <View
        variants={['column', 'center']}
        responsiveVariants={{
          mid: ['alignCenter'],
        }}
        component='nav'
      >
        {links}

      </View>
    </View>
  )
}

const SectionNavigate = () => {
  const { t } = useI18N()

  return (
    <View variants={['gap:5', 'justifyCenter', 'paddingRight:35']} responsiveVariants={{
      large: ['paddingRight:25'],
      largeish: ['paddingRight:22'],
      mid: ['column', 'gap:3', 'paddingRight:0'],
    }}>
      {/* <LinkCol title={t('footer.navigate.pages')} col={linksCol1} /> */}
      <LinkCol title={t('footer.navigate.terms')} col={linksCol2} />
    </View>
  )
}

const SectionDisclaimers = () => {
  const { t } = useI18N()
  return (
    <View css={styles.disclaimersWrapper}>
      <Text css={styles.disclaimerText}>{t('footer.disclaimer.visit')}</Text>
      <Text css={styles.disclaimerText}>
        {t('footer.disclaimer.registered')} {t('footer.disclaimer.england')}&nbsp;&&nbsp;{t('footer.disclaimer.wales')}
        {t('footer.disclaimer.company')}&nbsp;{t('footer.disclaimer.number')}&nbsp;{t('footer.disclaimer.companyNumber')}
      </Text>
      <Text variants={['inline']} css={styles.disclaimerText}>
        {t('footer.disclaimer.uses')}&thinsp;
        <Link css={styles.disclaimerText} to={Settings.ContactINFO.PrivacyPolicy}>{t('footer.disclaimer.thirdParty')}
        </Link>
        {t('footer.disclaimer.agree')}&thinsp;
        <Link css={styles.disclaimerText} to={Settings.ContactINFO.TermsOfUse}>
          {t('footer.disclaimer.terms')}
        </Link>
        .
      </Text>
    </View>
  )
}

function SectionContact() {
  const year = format(new Date(), 'yyyy')
  const { t } = useI18N()

  return (
    <View
      variants={['column', 'fullHeight', 'alignEnd', 'justifyCenter']}
      responsiveVariants={{
        mid: ['alignCenter'],
      }}
    >
      <Link
        text={Settings.ContactINFO.ContactEMAIL}
        to={`mailto:${Settings.ContactINFO.ContactEMAIL}`}
        css={styles.contactText}
      />
      {/* <Link
        text={Settings.ContactINFO.ContactPHONE}
        to={`tel:${Settings.ContactINFO.ContactPHONE}`}
        css={styles.contactText}
      /> */}
      <Text css={styles.contactText} text={t('footer.contact.year', { year })} />
      {/* <Text css={styles.contactText} >
        {t('footer.contact.made')}&#x1F49A;{t('footer.contact.london')}
      </Text> */}
    </View>
  )
}

const SectionSocialMedia = () => (
  <View variants={['gap:1.4']}>
    {socialMediaLinks.map((media, i) => (
      <Link key={i + media.icon} to={media.url}>
        <View variants={['bg:positive2', 'padding:1.5', 'border-radius:rounded', 'center']}>
          <Icon
            // @ts-ignore
            debugName='footer media'
            name={media.icon}
            size={media.size}
            color={Theme.colors.light.neutral1}
            css={{ fill: Theme.colors.light.neutral1, ...media?.css }}
          />
        </View>
      </Link>
    ))}
  </View>
)

const FooterComponent = () => {
  const isMobile = useMediaQuery(Theme.media.down('mid'))

  return (
    <CenterWrapper component='footer' variants={['bg:neutral10', 'paddingVertical:10', 'justifyEnd']} responsiveVariants={{
      mid: ['justifyCenter'],
    }}>
      <View variants={['alignCenter', isMobile && 'column']}>
        {/* <Logo
          style={styles.logoImage}
          responsive={false}
          switchServerOnPress
          image={AppImages.Logo}
          debugName='logo'
          imageProps={{
            objectFit: 'contain'
          }}
        /> */}

        <SectionNavigate />

        {/* <View variants={['separator', 'bg:neutral7']} /> */}

        <View variants={['column', 'center', 'justifySpaceBetween', 'gap:2.79']}>
          <SectionContact />
          <SectionSocialMedia />
          {/* <SectionDisclaimers /> */}
        </View>
      </View>

    </CenterWrapper>
  )
}

export const Footer = React.memo(FooterComponent, () => true)

const minHeight = 420

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    backgroundColor: theme.colors.primary3,
    width: '100%',
    minHeight: minHeight,
    ...theme.spacing.paddingHorizontal(10),
    ...theme.spacing.paddingVertical(5),

    [Theme.media.down('mid')]: {
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(5),
    },
  },
  disclaimerText: {
    ...assignTextStyle('p3')(theme).text,
    color: theme.colors.neutral4,

    [Theme.media.down('mid')]: {
      alignSelf: 'center',
      ...theme.presets.textCenter,
    },
  },
  footerNavText: {
    ...assignTextStyle('h5')(theme).text,
    color: theme.colors.neutral1,
    textDecoration: 'none',
    fontWeight: '400',
  },
  logoImage: {
    marginBottom: theme.spacing.value(5),
    alignSelf: 'flex-start',

    [Theme.media.down('mid')]: {
      alignSelf: 'center',
    },
  },
  contactText: {
    ...assignTextStyle('p1')(theme).text,
    color: theme.colors.neutral1,
    fontWeight: '400',
    textDecoration: 'none',
  },
  disclaimersWrapper: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
  },
}), true)
