import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'

export type LogoComposition = 'wrapper' | 'image'

const createLogoStyle = variantProvider.createVariantFactory<LogoComposition>()

const presets = includePresets((s) => createLogoStyle(() => ({ wrapper: s })))

const LOGO_SPLASH_SIZE = 230
const SMALL_LOGO_WIDTH = 60
const LOGO_DEFAULT_SIZE = 170

export const LogoStyles = {
  ...presets,
  default: createLogoStyle(() => ({
    wrapper: {
      userSelect: 'none',
      width: LOGO_DEFAULT_SIZE,
      height: 58,
      marginTop: 1,
    },
    image: {
      userSelect: 'none',
      width: LOGO_DEFAULT_SIZE,
      height: 58,
    },
  })),
  splash: createLogoStyle(() => ({
    wrapper: {
      width: LOGO_SPLASH_SIZE,
      cursor: 'default',
    },
    image: {
      width: LOGO_SPLASH_SIZE,
      objectFit: 'contain',
      cursor: 'default',
    },
  })),
  small: createLogoStyle(() => ({
    image: {
      width: SMALL_LOGO_WIDTH,
      height: 'auto',
    },
    wrapper: {
      width: SMALL_LOGO_WIDTH,
      height: 'auto',
    },
  })),
}
