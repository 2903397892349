import { variantProvider } from '@/app'
import { Text, View, MyOrganisationCard, ActionIcon, FindOrganisationsExplainerModal } from '@/components'
import { useModal } from '@/utils'
import { useI18N } from '@codeleap/common'

const BREAKPOINT = 'mid'

export const OrganisationSubHeader = () => {
  const { t } = useI18N()
  const { visible, toggle } = useModal()

  return (
    <View
      variants={['row', 'fullWidth', 'alignCenter', 'justifySpaceBetween', 'flex']}
      responsiveVariants={{ [BREAKPOINT]: ['column', 'gap:2', 'alignStart', 'justifyCenter', 'marginHorizontal:-2', 'paddingHorizontal:2', 'fullWidth'] }}
    >
      <View variants={['column', 'gap:2', 'fullWidth']} responsiveVariants={{ [BREAKPOINT]: ['gap:0'] }}>
        <View variants={['fullWidth', 'justifySpaceBetween', 'alignCenter']}>
          <Text
            variants={['h0', 'color:neutral1', 'noWrap']}
            responsiveVariants={{ [BREAKPOINT]: ['h1', 'color:neutral1'] }}
            text={t('organisations.findTitle')}
            style={styles.title}
          />
          <ActionIcon
            icon='info'
            variants={['minimal', 'color:neutral10', 'iconSize:3']}
            debugName={'Info'}
            styles={{
              touchableWrapper: styles.touchableWrapper,
              icon: styles.icon,
            }}
            onPress={toggle}
          />
        </View>
        <View style={styles.textWrapper}>
          <Text
            variants={['p1', 'color:neutral1', 'wrap']}
            style={styles.subtitle}
            text={t(`organisations.findSubtitle`)}
          />
        </View>
      </View>

      <MyOrganisationCard />
      <FindOrganisationsExplainerModal visible={visible} toggle={toggle} />
    </View>
  )
}

const MAX_WIDTH = 415

export const styles = variantProvider.createComponentStyle((theme) => ({
  textWrapper: {
    maxWidth: MAX_WIDTH,
  },
  title: {
    [theme.media.down(BREAKPOINT)]: {
      letterSpacing: '-1.5px',
    },
  },
  subtitle: {
    display: 'flex',
    [theme.media.down(BREAKPOINT)]: {
      display: 'none',
    },
  },
  touchableWrapper: {
    backgroundColor: theme.colors.transparent,

    display: 'none',

    [theme.media.down(BREAKPOINT)]: {
      display: 'flex',
    },
  },
  icon: {
    color: theme.colors.neutral1,
  },
}), true)
