import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

const IMAGE_HEIGHT = 124

export const ForgotBeforeModal = () => {
  return (
    <AuthModal
      name='forgotBefore'
      image={AppImages.ResetPassword}
      imageHeight={IMAGE_HEIGHT}
      small
    >
      <AuthForms.ForgotPasswordBeforeForm />
    </AuthModal>
  )
}
