import { I18N, Theme, variantProvider } from '@/app'
import { CardBase, CardBaseProps } from './CardBase'
import { Text, View, ImageProps, OrganisationCategoryInfo } from '@/components'
import { APIClient } from '@/services'
import { OrganisationLogo, orgLogoStyles } from '../Organisations/Logo'
import { navigate } from 'gatsby'
import { analytics } from '@/services'

type MyOrganisationCardProps = Partial<CardBaseProps> & {
  imageProps?: Partial<ImageProps>
}

export const MyOrganisationCard = (props: MyOrganisationCardProps) => {
  const { imageProps, ...cardBaseProps } = props

  const { profile } = APIClient.Session.useEdit()
  const organisation = profile?.organization

  const onPress = () => {
    analytics.track('org_view', { org_name: organisation.name })
    navigate(`/organisations/${organisation?.id}`)
  }

  if (!profile?.id || !profile?.organization) return null

  return (
    <CardBase
      style={Theme.effects.light}
      styles={{ wrapper: styles.wrapper, innerWrapper: styles.innerWrapper }}
      variants={['border-radius:small']}
      debugName='MyOrganisationCard'
      gap={2}
      onPress={!organisation?.is_unknown && onPress}
      tabIndex={0}
      aria-label={I18N.t('accessibility.myOrganisation', { orgName: organisation?.name })}
      {...cardBaseProps}
    >
      <OrganisationLogo logo={organisation?.logo} size='small' {...imageProps} />

      <View variants={['flex', 'column', 'justifyStart', 'fullWidth']}>
        <Text
          variants={['h3', 'ellipsis']}
          css={styles.name}
          text={organisation?.name}
        />
        <View variants={['gap:0.5']}>
          <OrganisationCategoryInfo organisation={organisation} />
        </View>
      </View>
    </CardBase>
  )
}

const CARD_WIDTH = 320

const styles = variantProvider.createComponentStyle(theme => {
  const width = CARD_WIDTH - theme.spacing.value(4)
  const maxNameWidth = `calc(${width}px - ${orgLogoStyles.small?.width}px - ${theme.spacing.value(2)}px)`

  return {
    wrapper: {
      maxWidth: CARD_WIDTH,
      minWidth: CARD_WIDTH,

      [theme.media.down('small')]: {
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
    innerWrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
    },
    name: {
      width: maxNameWidth,
      maxWidth: maxNameWidth,
      lineBreak: 'loose',
      whiteSpace: 'unset',
    },
  }
}, true)
