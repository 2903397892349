import { AuthForms } from '@/components'
import { AppImages, React } from '@/app'
import { AuthModal } from './AuthModal'

const IMAGE_HEIGHT = 156

export const VerifyEmailBeforeModal = () => {
  return (
    <AuthModal
      image={AppImages.MailBox}
      name='verifyEmailBefore'
      imageHeight={IMAGE_HEIGHT}
      small
      centered={false}
      closable={false}
      center
    >
      <AuthForms.VerifyEmailBeforeForm />
    </AuthModal>
  )
}
