import { AppImages, React, useAppI18N, variantProvider } from '@/app'
import { View, Text, Image } from '@/components'

type LearnEmptyPlaceholderProps = {
  list: 'modules' | 'chapters' | 'stories'
}

const LearnEmptyPlaceholderCP = ({ list }: LearnEmptyPlaceholderProps) => {
  const { t } = useAppI18N()

  const listName = list === 'chapters' ? 'chapter' : 'learn'

  let title = t(`learn.${listName}Screen.notFoundTitle`)
  let description = t(`learn.${listName}Screen.notFoundDescription`)

  if (list === 'stories') {
    title = t('organisations.storiesPlaceholder.title')
    description = t('organisations.storiesPlaceholder.description')
  }

  return (
    <View style={styles.wrapper}>
      <Image source={AppImages.emptyBox} style={styles.image}/>
      
      <Text variants={['h4', 'textCenter']} text={title}/>
      <Text variants={['p1', 'marginTop:1', 'textCenter']} text={description}/>
    </View>
  )
}

export const LearnEmptyPlaceholder = React.memo(LearnEmptyPlaceholderCP, () => true)

const IMAGE_WIDTH = 150
const WIDTH = 400

const styles = variantProvider.createComponentStyle((theme) => ({
  image: {
    width: '50%',
    maxWidth: IMAGE_WIDTH,
    ...theme.spacing.marginBottom(4),
  },
  wrapper: {
    ...theme.presets.flex,
    ...theme.presets.center,
    ...theme.presets.column,
    ...theme.spacing.paddingHorizontal(2),
    ...theme.spacing.marginTop(0),
    ...theme.presets.alignCenter,
    maxWidth: WIDTH,
  }
}), true)
