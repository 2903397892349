import { PaginationResponse, QueryManager, useEffect, useIsomorphicEffect } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { Achievement } from '@/types'
import { analytics } from '..'
import { QueryKeys } from './session'

const BASE_URL = 'achievements/'
const getBadgesProperty = (items: Achievement[]) => {
  const badges = items?.filter(item => item?.levels?.current_level_index > 0)
    .map(({ name, levels }) => `${name} ${levels?.list[levels?.current_level_index].name}`).join(', ')

  return badges
}
export const achievementsManager = new QueryManager({
  itemType: {} as Achievement,
  name: 'achievements',
  queryClient: queryClient.client,
  listItems: async (limit, offset, filters = {}) => {
    const response = await api.get<PaginationResponse<Achievement>>(BASE_URL, {
      params: {
        limit,
        offset,
      },
    })

    const uid = QueryKeys.me.getData()?.id

    if (!!uid) {

      const property = getBadgesProperty(response.data.results)
      if (!!property) {
        analytics.identify?.(uid, { badges: property })
      }

    }

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Achievement>(`${BASE_URL}${id}/`)

    return response.data
  },
  actions: {
    async deleteHistory(manager) {
      const response = await api.delete(`${BASE_URL}history/delete_all`)

      manager?.refresh()

      return response.data
    },
    async getProgress(manager, metric) {
      const response = await api.get(`${BASE_URL}get_progress/`, {
        params: {
          progression_metric: metric,
        },
      })

      return response.data
    },
  },
})

export const prefetchAchievements = () => {
  queryClient.client.prefetchQuery(achievementsManager.queryKeys.list, () => achievementsManager.options.listItems(0, 40))
}

export const useAchievements = () => {
  const achievements = achievementsManager.use()

  return {
    loading: achievements?.list?.query?.isLoading || achievements?.list?.query?.isFetching,
    items: (achievements.list.items ?? []),
  }
}
