import { AppStatus } from '@/redux'
import { navigate } from 'gatsby'
import { getFirebase } from '../../firebaseApp'
import { QueryKeys } from './queryKeys'
import { ERRORS, isEmailAvailable, Profile } from './views'
import { analytics } from '@/services'

export const codeLoginErrors = {
  ['auth/wrong-password']: 'Email or password is incorrect',
}

export function useLogin() {
  const login = async (data:Partial<Profile>) => {
    AppStatus.set('loading')

    try {
      const { email, password } = data

      const emailAvailable = await isEmailAvailable(email)

      if (emailAvailable) {
        AppStatus.set('idle')
        alert('Could not find an account matching the specified email address')
        return false
      }

      const firebase = await getFirebase()
      await firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim())

      await QueryKeys.me.refresh()
      const user = QueryKeys.me.getData()

      analytics.track('login', {
        email: user.email,
        organisation: user?.organization || null,
        seniority: user?.seniority || null,
        function: user?.department || null,
      })
      AppStatus.set('done')
      return true

    } catch (e) {
      AppStatus.set('idle')

      const errorMessage = codeLoginErrors?.[e?.code]

      if (!!errorMessage) {
        alert(errorMessage)
      }

      logger.error('Error on login', e, 'Auth')
      return false
    }
  }

  return login
}
