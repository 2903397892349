import React from 'react'
import { Modal, Pager, ActionIcon, Button, View } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { useIsMobile, usePager } from '@/utils'
import { variantProvider } from '@/app'
import { useOnboardingPages } from './pages'
import { Page } from './Page'

export const Onboarding = () => {
  const status = useAppSelector(store => store.AppStatus.status)
  const isOpen = useAppSelector(store => store.AppStatus.modals.onboarding)

  const isMobile = useIsMobile()

  const pages = useOnboardingPages()

  const pager = usePager<typeof pages[0]>({
    pages,
    onFinish: () => AppStatus.transitionModals(['onboarding', 'success']),
  })

  return (
    <Modal
      showClose={false}
      variants={[isMobile && 'fullscreen', 'centered']}
      styles={{ box: styles.modalBox, body: styles.modalBody }}
      visible={isOpen && !(status === 'loading' || status === 'done')}
    >
      <ActionIcon
        debugName={`onboarding:closeButton`}
        name={'x'}
        variants={['minimal', 'primary3', 'alignSelfEnd', 'absolute', 'iconSize:3']}
        style={styles.close}
        onPress={() => AppStatus.setModal('onboarding')}
      />

      <Pager
        debugName='pager:onboarding'
        disableSwipe
        dots
        dotsDisabled
        ref={pager.ref}
        page={pager.page}
        onChange={() => null}
        styles={{
          'wrapper': styles.pager,
        }}
        css={[styles.pagerWrapper]}
        footer={
          <View
            variants={['fullWidth', 'center', 'paddingHorizontal:4', 'paddingTop:4', 'marginBottom:4']}
            responsiveVariants={{
              small: ['paddingHorizontal:2', 'marginBottom:2', 'paddingTop:2'],
            }}
          >
            <Button
              variants={['pill', 'padding:1', 'fullWidth']}
              debugName='onboardingPager:button'
              text={pager?.currentPage?.button}
              onPress={pager.next}
            />
          </View>
        }
      >
        {pages.map((page, index) => {
          return <Page key={'onboardingPager-' + page.title + index} {...page} />
        })}
      </Pager>
    </Modal>
  )
}

const MODAL_WIDTH = 700

const styles = variantProvider.createComponentStyle((theme) => ({
  modalBox: {
    ...theme.spacing.padding(0),
    // @ts-ignore
    borderRadius: theme.borderRadius.large,
    width: MODAL_WIDTH,
    maxWidth: `calc(100vw - ${theme.spacing.value(4)}px)`,

    [theme.media.down('small')]: {
      position: 'fixed',
      overflowY: 'auto',
      ...theme.presets.fullWidth,
      height: '100%',
      borderRadius: 0,
      maxWidth: `100vw`,
    },
  },
  modalBody: {
    [theme.media.down('small')]: {
      height: '100%',
      maxHeight: 'none',
    },
  },
  close: {
    ...theme.presets.absolute,
    top: theme.spacing.value(4),
    right: theme.spacing.value(4),
    zIndex: 1,
  },
  pager: {
    paddingBottom: theme.spacing.value(4),

    [theme.media.down('small')]: {
      ...theme.presets.justifySpaceBetween,
      height: '100svh',
      position: 'fixed',
      overflowY: 'auto',
      paddingBottom: theme.spacing.value(2),
    },
  },
  pagerWrapper: {
    [theme.media.down('small')]: {
      height: '100svh',
      position: 'fixed',
      overflowY: 'auto',
    },
  },
}), true)
