import { logger } from '@/app'
import { AnyFunction, onUpdate } from '@codeleap/common'
import { Notification, STATUS_TYPES, NotificationDataType } from './types'


export const notificationManager:any = null
// export const notificationManager = new NotificationManager(logger)

export function useNotificationsEffect(
  // TODO - add types
  cb: (...args: any) => AnyFunction | void,
  deps = [],
) {
  onUpdate(() => {
    let onUnmount = null
    const unsubscribe = notificationManager.onStateChange((...args) => {
      onUnmount = cb(...args)
    })

    return () => {
      unsubscribe()
      onUnmount?.()
    }
  }, deps)
}

// TODO - add types
export function parseFirebaseNotification(message: any) {
  // TODO - parse the message
  return null

}

