import React from 'react'
import { ActionIcon, Drawer } from '@/components'
import { AnyFunction, StylesOf } from '@codeleap/common'
import { HeaderItems } from './Items'
import { HeaderComposition } from '../../app/stylesheets/Header'

type DrawerMenuProps = {
  variantStyles?: StylesOf<HeaderComposition>
  visible: boolean
  toggle: AnyFunction
}

export const DrawerMenu = (props: DrawerMenuProps) => {
  const {
    variantStyles,
    visible,
    toggle,
  } = props

  return <>
    <Drawer
      styles={{
        wrapper: variantStyles.drawerWrapper,
        box: variantStyles.drawerBox,
        overlay: variantStyles.drawerOverlay,
      }}
      // @ts-ignore
      css={[variantStyles.drawerBox]}
      open={visible}
      position='top'
      toggle={toggle}
      variants={['h:auto']}
      darkenBackground={false}
      scrollLocked={false}
      debugName={'header:drawer'}
    >
      <HeaderItems variantStyles={variantStyles} />
    </Drawer>

    <ActionIcon
      icon={visible ? 'x' : 'menu'}
      variants={['marginLeft:auto', 'minimal', 'neutral10', 'iconSize:4']}
      onPress={toggle}
      debugName={'Header Menu Icon'}
    />
  </>
}
