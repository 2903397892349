import { React, variantProvider } from '@/app'
import { CenterWrapper, Text, HTMLRender } from '@/components'
import { TypeGuards } from '@codeleap/common'

type LessonViewContentProps = {
  title?: string
  description?: string
  children?: React.ReactNode
  padding?: boolean
  text?: string
  visible?: boolean
  assignContentStyles?: boolean
  renderOverlay: JSX.Element
  autoWidth: boolean
}

export const LessonViewContent = (props: LessonViewContentProps) => {
  const {
    title = null,
    description = null,
    padding = true,
    children,
    text = null,
    visible,
    assignContentStyles = false,
    renderOverlay = null,
    autoWidth = false,
  } = props

  return (
    <CenterWrapper styles={{ 'wrapper': styles.wrapper, 'innerWrapper': styles.centerInner }}>
      <div
        css={[
          styles.innerWrapper, 
          { pointerEvents: visible ? 'auto' : 'none' }, 
          padding && styles.padding,
          assignContentStyles && styles.content,
          autoWidth && styles.autoWidth
        ]}
      >
        {TypeGuards.isString(title) ? (
          <Text variants={['h2']} text={title} />
        ) : null}

        {TypeGuards.isString(description) ? (
          <Text variants={['p1', 'marginTop:2']} text={description} />
        ) : null}

        {children}

        {!TypeGuards.isNil(text) ? (
          <HTMLRender html={text} />
        ) : null}
      </div>

      {renderOverlay}
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  centerInner: {
    ...theme.presets.center,
  },
  innerWrapper: {
    width: `100%`,
    height: 'auto',
    backgroundColor: theme.colors.transparent,
    ...theme.presets.column,
    ...theme.presets.alignStart,
    ...theme.presets.justifyStart,
    position: 'relative',
    marginTop: theme.spacing.value(12),
    marginBottom: theme.spacing.value(6),
    zIndex: 2,
  },
  content: {
    backgroundColor: theme.colors.neutral1,
    borderRadius: theme.borderRadius.large,
  },
  wrapper: {
    minHeight: '100svh',
    height: 'auto',
    ...theme.presets.row,
    ...theme.presets.center,
    ...theme.presets.absolute,
    right: 0,
    left: 0,
  },
  padding: {
    ...theme.spacing.padding(4),

    [theme.media.down('small')]: {
      ...theme.spacing.padding(2),
    }
  },
  autoWidth: {
    width: 'auto',
  }
}), true)
