import { getFirebase } from '@/services/firebaseApp'
import { useIsomorphicEffect } from '@codeleap/common'
import { useCallback, useEffect } from 'react'

export const useTokenSignin = (signinToken?: string) => {

  const signInWithToken = useCallback(async (token?: string) => {
    try {
      const firebase = await getFirebase()

      const user = await firebase.auth().signInWithCustomToken(token ?? signinToken)

      logger.info('User signed in with custom token',
        user,
        'useTokenSignin',
      )
    } catch (err) {
      logger.error('Error signing in with custom token', err, 'useTokenSignin')
    }
  }, [signinToken])

  useIsomorphicEffect(() => {
    if (!signinToken) return

    signInWithToken()
  }, [signinToken, signInWithToken])

  return {
    signInWithToken,
  }
}
