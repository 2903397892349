import { api } from '@/app'
import { AppStatus } from '@/redux'
import { getFlatListProps } from '../../utils/data'

import { DeepPartial, usePagination, UsePaginationTypes, waitFor } from '@codeleap/common'

export type Post = {
    id: number
    username: string
    created_datetime: string
    title: string
    content: string
}

const BASE_URL = 'careers/'

export async function list(params?: UsePaginationTypes.PaginationParams) {
  await waitFor(2000)
  const response = await api.get<UsePaginationTypes.PaginationReturn<Post>>(BASE_URL, {
    params,
  })

  return response.data
}

export async function create(newPost: Pick<Post, 'content'|'username'|'title'>) {
  const response = await api.post(BASE_URL, newPost)

  return response.data
}

export async function update(post: Pick<Post, 'content'|'title'|'id'>) {
  const response = await api.patch<Post>(`${BASE_URL}/${post.id}/`, post)

  return response.data
}

export async function remove(post: Post) {
  const response = await api.delete(`${BASE_URL}/${post.id}/`)

  return post
}

export async function retrieve(postId: Post['id']) {

  const response = await api.get<Post>(`${BASE_URL}/${postId}/`)

  return response.data
}

type PaginatedPosts = UsePaginationTypes.PaginationReturn<Post>

const LIMIT = 20

type UsePostsOptions = UsePaginationTypes.UsePaginationParams<PaginatedPosts>

type UsePostsParams = {
  id: Post['id']
  options?: DeepPartial<UsePostsOptions>
}

export function usePosts(params?:UsePostsParams) {
  const pagination = usePagination<
    Post
  >('posts', {
    onList: list,
    onCreate: create,
    onRemove: remove,
    onUpdate: update,
    onRetrieve: retrieve,
    beforeMutate: () => AppStatus.set('loading'),
    afterMutate: (operation, result) => AppStatus.set(result.status === 'error' ? 'idle' : 'done'),
    keyExtractor: post => post.id,
    where: [params?.id],

    limit: LIMIT,
    overrides: {
      list: (_default) => ({
        ..._default,
        getNextPageParam: (page, pages) => {
          return {
            limit: LIMIT,
            offset: pages.reduce((acc, p) => p.results.length + acc, 0),
          }

        },
        refetchOnMount: !params?.id,
      }),

    },
  })

  return {
    ...pagination,
    flatListProps: getFlatListProps(pagination),
  }
}
