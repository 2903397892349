import { useAppI18N } from '@/app'
import { Button, Modal, Text, View } from '@/components'
import { AnyFunction } from '@codeleap/common'
import { ModalProps } from '@codeleap/web'

type FindOrganisationsExplainerModalProps = Partial<ModalProps> & {
  visible: boolean
  toggle: AnyFunction
}

export const FindOrganisationsExplainerModal = (props: FindOrganisationsExplainerModalProps) => {
  const { toggle, visible, ...modalProps } = props
  const { t } = useAppI18N()

  return (
    <Modal
      visible={visible}
      showClose={false}
      toggle={toggle}
      debugName={'Find Organisations Explainer Modal'}
      {...modalProps}
      variants={['centered']}
    >
      <View variants={['gap:2', 'column']}>
        <Text variants={['h4', 'color:neutral9', 'textCenter']} text={t(`organisations.findTitle`)} />
        <Text
          variants={['center', 'textCenter']}
          text={t(`organisations.findSubtitle`)}
        />
        <Button text={t('modals.orgInfo.confirmButton')} onPress={toggle} debugName={'Dismiss Modal'} />
      </View>
    </Modal>
  )
}
