import React from 'react'
import { APIClient } from '@/services'
import { assignTextStyle, useI18N } from '@codeleap/common'
import { useNavigation } from '../Navigation'
import { View, Link, Button } from '@/components'
import { navigate } from 'gatsby'
import { variantProvider } from '@/app'
import { useMediaQueryDown, usePageContext } from '@/utils'

export const BASE_SITE_URL = 'https://hurd.world/'

const navItems = [
  {
    name: 'Home',
    url: BASE_SITE_URL,
  },
  {
    name: 'Organisations',
    url: 'organisations/',
  },
  {
    name: 'Act',
    url: 'act/',
  },
  {
    name: 'Learn',
    url: 'learn/',
  },
  {
    name: 'For Employers',
    url: BASE_SITE_URL + 'for-employers/',
  },
  {
    name: 'Blog',
    url: BASE_SITE_URL + 'blog/',
  },
]

export const BREAKPOINT = 'largeish'

export const HeaderItems = ({ variantStyles }) => {
  const { isLoggedIn } = APIClient.Session.useSession()
  const { matchPath } = useNavigation()
  const { t } = useI18N()
  const isTablet = useMediaQueryDown('mid')

  const renderItem = React.useCallback((item) => {
    const isSelected = matchPath(item?.url)
    const isExternal = item?.url?.startsWith('http')

    return (
      <View key={item?.url + 'header'} variants={['column', 'relative', 'alignStart']}>
        <Link
          tabIndex={0}
          text={t(`links.${item?.name}`)}
          to={isExternal ? item?.url : '/' + item?.url}
          variants={['noUnderline']}
          css={[variantStyles.navItem, isSelected ? variantStyles['navItem:selected'] : {}]}
        />
      </View>
    )
  }, [])

  const goToMyProfile = () => {
    const path = isLoggedIn ? (isTablet ? '/profile/menu/' : '/profile/edit/') : '/auth/signup/'

    navigate(path)
  }

  const ctx = usePageContext()

  return (
    <View
      variants={['gap:4', 'center']}
      responsiveVariants={{ [BREAKPOINT]: ['column', 'gap:2', 'alignCenter', 'paddingBottom:2'] }}
    >
      <View variants={['fullWidth']} responsiveVariants={{ [BREAKPOINT]: ['column'] }} component='nav'>
        {navItems.map(renderItem)}
      </View>

      <Button
        text={ctx?.myProfileButton ?? t('header.myProfile')}
        css={variantStyles.navButton}
        variants={['circle', 'large']}
        styles={{
          text: styles.text,
          wrapper: styles.wrapper,
        }}
        responsiveVariants={{ [BREAKPOINT]: ['large'] }}
        onPress={goToMyProfile}
        debugName='header signIn'
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  text: {
    ...assignTextStyle('h4')(theme).text,
    fontWeight: '400',
    letterSpacing: 0,
  },
  wrapper: {
    '&:hover': {
      backgroundColor: theme.colors.neutral10,
    },
  },
}), true)
