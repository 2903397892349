import { Pager, View, ActionIcon, Button, Modal, Image, TextInput, CategoryExplainer } from '@/components'
import { APIClient } from '@/services'
import { onUpdate, TypeGuards, useRef, useState, useValidate } from '@codeleap/common'
import { I18N, React, Theme, variantProvider } from '@/app'
import { AppStatus, Pulse, useAppSelector } from '@/redux'
import { PulsePage } from './Page'
import { useIsMobile, useModal, verifyProfanity, useHandleOpen } from '@/utils'
import { CategoryData } from '@/types'
import { analytics } from '@/services'

const validatePulseComment = (value: string) => {

  const MIN_CHARACTERS = 6

  let valid = true

  const { isProfanity, profanities } = verifyProfanity(value)

  if (isProfanity) {
    return {
      valid: false,
      message: I18N.t('form.validations.profanity', { words: profanities.join(', ') }),
    }
  }

  if (value?.trim()?.length < MIN_CHARACTERS && value?.trim()?.length > 0) valid = false

  return {
    valid,
    message: I18N.t('form.validations.min', {
      min: MIN_CHARACTERS,
    }),
  }
}

export const ReviewOrganisation = () => {
  const status = useAppSelector(state => state?.AppStatus?.status)

  const {
    questions,
    currentQuestion,
    skip,
    answer,
    answers,
    setAnswer,
    page,
    isLast,
    isFirst,
    available,
    modalOpen,
    onBack,
    from,
    setPage,
    onAnswer,
    clearAnswers,
  } = APIClient.PulseQuestions.usePulseQuestions()
  const categoryModal = useModal()
  const [category, setCategory] = useState<CategoryData>(null)
  const pagerRef = useRef(null)
  const footerRef = useRef(null)
  const [footerHeight, setFooterHeight] = useState(null)
  // const [questionsAnswered, setQuestionsAnswered] = useState<{ id: number; comment: string }[]>([])
  // const [answersComment, setAnswersComment] = useState<{comment?:string; id:number}[]>([])
  const questionsAnswered = useRef<{ id: number; comment: string }[]>([])
  const answersComment = useRef<{comment?:string; id:number}[]>([])

  onUpdate(() => {
    if (footerRef.current && !footerHeight) {
      const _height = footerRef.current.clientHeight
      setFooterHeight(_height)
    }
  }, [footerRef.current])

  const onSkip = () => {
    analytics.track('review_skip')
    skip()
    if (isLast) {
      Pulse.toggleModal()
      AppStatus.set('loading')

      setTimeout(() => {
        AppStatus.set('idle')
        analytics.track('review_complete')
        Pulse.toggleSuccess(true)
      }, 3000)
    }
  }

  const onNext = async () => {
    if (!questionsAnswered.current.some(question => question.id === currentQuestion.id)) {
      analytics.track?.('review_add', {
        source_from: from,
        question_type: currentQuestion.type,
      })

      const data = {
        id: currentQuestion.id,
        comment: answer.comment,
      }

      questionsAnswered.current = [...questionsAnswered.current, data]
    }

    const commentExists = answersComment.current?.find(c => c.id === currentQuestion.id)?.comment !== answer.comment
    if (answer.comment && commentExists) {
      analytics.track?.('question_answer')
      answersComment.current = [...answersComment.current, { id: currentQuestion.id, comment: answer.comment }]
    }

    if (isLast) {
      analytics.track('review_complete')
      Pulse.toggleModal()
      AppStatus.set('loading')
    }

    await onAnswer()
  }

  const onClose = () => {
    Pulse.toggleModal()
    setAnswer(null)
    questionsAnswered.current = []
    answersComment.current = []
    const WAIT_ANSWERS_RESET = 250

    setTimeout(() => {
      clearAnswers()
    }, WAIT_ANSWERS_RESET)
  }

  const onCategoryPress = (currCategory: CategoryData) => {
    setCategory(currCategory)
    categoryModal.toggle(true)
  }

  const isMobile = useIsMobile()

  const hasAnswer = (TypeGuards.isNumber(answer?.value) || TypeGuards.isNumber(answer?.value?.[0])) && !!answers?.[page]

  const commentValidation = useValidate(answer?.comment ?? '', validatePulseComment)

  const isCommentValid = validatePulseComment(answer?.comment ?? '').valid

  const renderIndicator = React.useCallback((_, i) => {
    const _color = page === i ? Theme.colors.light.neutral9 : Theme.colors.light.neutral3

    return <View key={'indicator-' + i} css={[styles.indicator, { background: _color }]} />
  }, [page])

  const Footer = <>
    <View style={styles.footerWrapper} ref={footerRef}>
      <View
        variants={['row', 'justifySpaceBetween']}
        responsiveVariants={{ small: ['gap:2', 'column'] }}
      >
        <Button
          debugName='Back button on Pulse questions'
          text={isMobile ? I18N.t(isLast ? 'submit' : 'next') : I18N.t('back')}
          variants={[!isMobile && 'outline', 'large', 'pill', 'paddingHorizontal:8']}
          onPress={isMobile ? onNext : onBack}
          disabled={isMobile ? (!hasAnswer || !isCommentValid) : isFirst}
        />

        <View
          variants={['row', 'center', 'gap:2']}
          responsiveVariants={{ small: ['rowReverse', 'fullWidth'] }}
        >
          <Button
            debugName='Skip button on Pulse questions'
            text={I18N.t('skip')}
            variants={['outline', 'large', 'pill', 'paddingHorizontal:8']}
            responsiveVariants={{ small: ['fullWidth'] }}
            disabled={!currentQuestion?.skippable}
            onPress={onSkip}
          />

          <Button
            debugName='Next button on Pulse questions'
            text={!isMobile ? I18N.t(isLast ? 'submit' : 'next') : I18N.t('back')}
            variants={[isMobile && 'outline', 'large', 'pill', 'paddingHorizontal:8']}
            onPress={!isMobile ? onNext : onBack}
            disabled={!isMobile ? (!hasAnswer || !isCommentValid) : isFirst}
          />
        </View>
      </View>

      <View variants={['fullWidth', 'row', 'gap:1', 'center']}>
        {questions?.map(renderIndicator)}
      </View>
    </View>
  </>

  if (!available) return null

  return (
    <>
      <Modal
        debugName='Pulse questions modal'
        showClose={false}
        variants={[!isMobile && 'centered']}
        responsiveVariants={{ small: ['fullscreen'] }}
        styles={{ box: styles.modalBox, body: styles.modalBody, wrapper: styles.modal }}
        visible={modalOpen && !(status === 'loading' || status === 'done')}
        toggle={() => onClose()}

      >
        <ActionIcon
          onPress={onClose}
          icon='x'
          variants={['normalize', 'minimal', 'absolute', 'iconSize:4']}
          style={styles.close}
          debugName='Close button on Pulse questions'
        />

        {TypeGuards.isString(currentQuestion?.web_image) ? (
          <Image
            source={currentQuestion?.web_image}
            style={styles.image}

          />
        ) : <View variants={['paddingVertical:2.5']}></View>}

        <Pager
          page={page}
          onChange={() => null}
          setPage={setPage}
          disableSwipe
          dots={false}
          dotsDisabled
          ref={pagerRef}
        >
          {questions?.map((question, pageIdx) => {
            return (
              <PulsePage
                key={question?.id + pageIdx + '-page'}
                setAnswer={setAnswer}
                footerHeight={footerHeight}
                onCategoryPress={onCategoryPress}
                question={question}
                answers={answers}
                visible={page === pageIdx}
                idx={pageIdx}
                commentInput={
                  <TextInput
                    debugName='feedback on pulse questions'
                    placeholder={I18N.t('pulseQuestion.placeholder.feedback')}
                    value={answers?.[pageIdx]?.comment ?? ''}
                    onChangeText={comment => setAnswer({ comment: comment }, pageIdx)}
                    onBlur={commentValidation.onInputBlurred}
                    onFocus={commentValidation.onInputFocused}
                    _error={commentValidation?.showError ? commentValidation.message : ''}
                  />
                }
              />
            )
          })}
        </Pager>

        {Footer}
      </Modal>
      <CategoryExplainer
        visible={categoryModal.visible}
        toggle={() => {
          categoryModal.toggle()
        }}
        category={category}
      />
    </>
  )
}

const MODAL_WIDTH = 720
const IMAGE_HEIGHT = 200

const styles = variantProvider.createComponentStyle(theme => ({
  modal: {
    zIndex: 3,

    [theme.media.down('small')]: {
      height: '100svh',
      maxHeight: '100svh',
    },
  },
  modalBox: {
    ...theme.spacing.padding(0),
    // @ts-ignore
    borderRadius: theme.borderRadius.large,
    width: MODAL_WIDTH,
    height: 'none',
    minHeight: 'none',
    maxHeight: 'none',
    maxWidth: `calc(100vw - ${theme.spacing.value(4)}px)`,

    [theme.media.down('small')]: {
      position: 'fixed',
      overflowY: 'auto',
      ...theme.presets.fullWidth,
      borderRadius: 0,
      maxWidth: `100vw`,

      height: '100svh',
      maxHeight: '100svh',
    },
  },
  modalBody: {
    height: 'none',
    minHeight: 'none',
    maxHeight: 'none',

    [theme.media.down('small')]: {
      height: '100svh',
      maxHeight: '100svh',
    },
  },
  close: {
    zIndex: 2,
    top: theme.spacing.value(4),
    right: theme.spacing.value(4),

    [theme.media.down('small')]: {
      top: theme.spacing.value(2),
      right: theme.spacing.value(2),
    },
  },
  image: {
    width: '100%',

    [theme.media.down('small')]: {
      display: 'none',
    },
  },
  pager: {
    ...theme.spacing.paddingVertical(4),

    [theme.media.down('small')]: {
      height: '100%',
      ...theme.spacing.paddingVertical(2),
    },
  },
  pagerWrapper: {
    flex: 1,
    fullHeight: '100%',
  },
  footer: {
    [theme.media.down('small')]: {
      ...theme.spacing.paddingBottom(2),
    },
  },
  footerWrapper: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.spacing.padding(4),
    ...theme.spacing.paddingTop(0),
    ...theme.spacing.gap(2),
    backgroundColor: theme.colors.neutral1,

    [theme.media.down('small')]: {
      ...theme.presets.column,
      ...theme.spacing.padding(2),
      ...theme.spacing.paddingTop(0),
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  indicator: {
    ...theme.sized(1),
    borderRadius: theme.borderRadius.rounded,
  },
}), true)
