import React from 'react'
import { AppForms } from '@/app'
import { AnyFunction, TypeGuards, useForm, useI18N } from '@codeleap/common'
import { Button, Text, TextInput, View } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { navigate } from 'gatsby'
import { useKeydown } from '@/utils'
import { analytics } from '@/services'

type ForgotPasswordFormProps = {
  isModal?: boolean
  onRequest?: AnyFunction
}

export const ForgotPasswordBeforeForm = ({ isModal = true, onRequest }: ForgotPasswordFormProps) => {
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.forgotBefore)
  const form = useForm(AppForms.forgotPassword, {})
  const { t } = useI18N()

  const { requestPasswordReset } = APIClient.Session.useSession()

  const onRequestReset = async () => {
    await requestPasswordReset(form.values.email)
    analytics.track('forgot_password')
    if (TypeGuards.isFunction(onRequest)) onRequest()

    if (isModal) {
      AppStatus.transitionModals(['forgotBefore', 'forgotAfter'])
    }
  }

  const onLogin = async () => {
    if (isModal) {
      AppStatus.transitionModals(['forgotBefore', 'login'])
    } else {
      navigate('/auth/login/')
    }
  }

  useKeydown(() => {
    const isInvalidSubmit = isModal && !isFocused || !form.isValid
    if (isInvalidSubmit) return
    onRequestReset?.()
  }, [isFocused, isModal, form?.values], 'enter')

  return (
    <View variants={['flex', 'column']} >
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('ForgotPassword.title1')}
      />
      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={t('ForgotPassword.subtitle')}
      />

      <TextInput
        {...form.register('email')}
        leftIcon={{ name: 'mail' }}
      />

      <View variants={['column', 'gap:2', 'marginTop:auto']}>
        <Button
          text={t('submit')}
          debugName='Request password reset'
          disabled={!form.isValid}
          onPress={onRequestReset}
          variants={['pill', 'large', 'neutral9', 'fullWidth', !form.isValid && 'outline']}
          aria-label={t('submit')}
          aria-invalid={!form.isValid}
        />
      </View>
    </View>
  )
}

