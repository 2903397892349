import { shadeColor } from '@codeleap/common'
import { ActionIconComposition, ActionIconPresets } from '@codeleap/web'
import { variantProvider } from '..'

const createActionIconStyle =
  variantProvider.createVariantFactory<ActionIconComposition>()

export const AppActionIconStyles = {
  ...ActionIconPresets,
  default: createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
      color: theme.colors.neutral1,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    touchableWrapper: {
      width: theme.values.itemHeight.small,
      height: theme.values.itemHeight.small,
      borderRadius: theme.borderRadius.small,
      ...theme.presets.center,
      backgroundColor: theme.colors.primary3,
    },
    'touchableWrapper:pressable': {
      cursor: 'pointer',
    },
    'touchableWrapper:disabled': {
      cursor: 'not-allowed',
      backgroundColor: theme.colors.neutral2,
    },
  })),
  small: createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.itemHeight.small,
      height: theme.values.itemHeight.small,
      ...theme.spacing.padding(theme.values.borderWidth.small),
      borderRadius: theme.borderRadius.small,
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  large: createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.itemHeight.default,
      height: theme.values.itemHeight.default,
    },
  })),
  huge: createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.itemHeight.default,
      height: theme.values.itemHeight.default,
      borderRadius: theme.borderRadius.tiny,
      ...theme.spacing.paddingVertical(2),
      ...theme.spacing.paddingHorizontal(4),
    },
  })),
  'iconSize:1': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  'iconSize:2': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  'iconSize:3': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  'iconSize:4': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[4],
      height: theme.values.iconSize[4],
    },
  })),
  floating: createActionIconStyle((theme) => ({
    touchableWrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  outline: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral5(theme.values.borderWidth.small),
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    'touchableWrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral2(theme.values.borderWidth.small),
    },
  })),
  'primary:outline': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    'touchableWrapper:disabled': {
      backgroundColor: theme.colors.neutral3,
      ...theme.border.primary3(theme.values.borderWidth.small),
    },
  })),
  minimal: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    touchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'touchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },
  })),
  'minimal:neutral1': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    touchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'touchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },
  })),
  'minimal:neutral10': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    touchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'touchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },
  })),
  destructive: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.destructive2,
    },
  })),
  'destructive:outline': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.destructive2(theme.values.borderWidth.small),
    },
  })),
  'destructive:minimal': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral5(theme.values.borderWidth.small),
    },
  })),
  selected: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  neutral1: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
  })),
  neutral2: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral9,
    },
    touchableWrapper: {
      background: theme.colors.neutral2,
    },
  })),
  originalColor: createActionIconStyle((theme) => ({
    icon: {
      color: 'unset',
    },
  })),
  primary3: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,
    },
  })),
  destructive2: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
  })),
  positive2: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.positive2,
    },
  })),
  neutral5: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral5,
    },
  })),
  neutral10: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  neutral5: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral5,
    },
  })),
  player: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
  })),
  normalize: createActionIconStyle((theme) => ({
    touchableWrapper: {
      ...theme.spacing.padding(0),
      ...theme.spacing.margin(0),
      borderRadius: theme.spacing.value(0),
      backgroundColor: theme.colors.transparent,
      width: theme.values.itemHeight.tiny,
      height: theme.values.itemHeight.tiny,
    },
  })),
  'icon:lessonView': createActionIconStyle((theme) => ({
    touchableWrapper: {
      ...theme.spacing.padding(0),
      backgroundColor: shadeColor(theme.colors.neutral1, 0, 0.5),
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
      borderRadius: theme.borderRadius.rounded,
    },
    icon: {
      color: theme.colors.neutral9,
      height: theme.values.iconSize[1],
      width: theme.values.iconSize[1],
    },
  })),
  'bg-neutral1': createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
    },
  })),
  'bg-primary1': createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.primary1,
    },
  })),
  'learn:filter': createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.iconSize[4],
      height: theme.values.iconSize[4],
      backgroundColor: theme.colors.neutral2,
      ...theme.spacing.padding(0),
      borderRadius: theme.borderRadius.tiny,
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
      color: theme.colors.neutral10,
    },
  })),
  neutral4: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral4,
    },
  })),
  iconOnly: createActionIconStyle((theme) => ({
    touchableWrapper: {
      ...theme.spacing.padding(0),
      ...theme.spacing.margin(0),

      backgroundColor: theme.colors.transparent,
    },
  })),
  noBorder: createActionIconStyle((theme) => ({
    touchableWrapper: {
      ...theme.border.neutral1(0),
    },
    'touchableWrapper:disabled': {
      ...theme.border.neutral1(0),
    },
  })),
  overlap: createActionIconStyle((theme) => ({
    touchableWrapper: {
      zIndex: 10,
    },
  })),
  playerClose: createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[4],
      height: theme.values.iconSize[4],
    },
    touchableWrapper: {
      width: 'auto',
      height: 'auto',
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral10,
      padding: theme.spacing.value(1.5),
      position: 'absolute', 
      top: theme.spacing.value(2), 
      right: theme.spacing.value(3), 
      zIndex: 999,
    },
  })),
}
