import { React, variantProvider, Theme, INTERACTIVE_SPLASH_TRANSITION } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { onMount, onUpdate, useI18N, useUnmount } from '@codeleap/common'
import { Icon, Overlay, View, ActivityIndicator, Text } from '@/components'
import { Logo } from './Logo'
import { useLocation } from '@reach/router'
import { InteractiveSplashContent, useInteractiveSplash } from './InteractiveSplash'
import { useId } from 'react'

const indicatorSize = 60

const useTimeout = () => {
  const timerRef = React.useRef<NodeJS.Timer | null>(null)
  const labelRef = React.useRef<string | null>(null)
  const hookId = useId()

  const timeoutLogger = React.useMemo(() => ({
    log: (message: string) => {
      console.log(`[useTimeout:${hookId}] ${message}`)
    },
  }), [hookId])

  function clear(source = '') {
    if (timerRef.current) {
      timeoutLogger.log(`Clearing timer for ${labelRef.current}, source: ${source || 'unknown'}`)
      clearTimeout(timerRef.current)
      timerRef.current = null
      labelRef.current = null
    }
  }

  function _setTimeout(label: string, callback: () => void, delay: number) {
    timeoutLogger.log(`Setting timeout for ${label}`)
    clear(`Set timer for ${label}`)
    labelRef.current = label
    timerRef.current = setTimeout(() => {
      timeoutLogger.log(`Timeout for ${label}`)
      callback()
      clear(`Timeout for ${label}`)
    }, delay)
  }

  return {
    get hasTimer() {
      return !!timerRef.current
    },
    set: _setTimeout,
    clear,

  }
}

const AppStatusOverlayComponent = () => {
  const status = useAppSelector((store) => store.AppStatus.status)

  const hideTimer = useTimeout()

  const visibleOverlay = ['loading', 'done'].includes(status)

  const interactiveSplash = useInteractiveSplash()

  const isInteractive = status === 'interactive'

  const location = useLocation()

  const hideInteractive = location.pathname.includes('climate-and-sustainability')

  const { t } = useI18N()

  onMount(() => {
    logger.log('Mount app status')
    if (!isInteractive) return
    interactiveSplash.startIconBounce()
  })

  onUpdate(() => {

    if (status === 'done' && !hideTimer.hasTimer) {
      hideTimer.set('Hide done status', () => {

        AppStatus.set('idle')
      }, 2000)
    }

    if (['interactive', 'splash'].includes(status) && !hideTimer.hasTimer) {

      hideTimer.set('Hide status ' + status, () => {
        AppStatus.set('idle')
      }, INTERACTIVE_SPLASH_TRANSITION)
    }

    return () => {

      hideTimer.clear(`onUpdate clear ${status}`)
    }
  }, [status])

  const visibilityStyle = React.useCallback((appStatus) => {
    const isStatusVisible = status === appStatus

    return ({
      transform: `scale(${isStatusVisible ? 1 : 0})`,
      transition: 'transform 0.3s ease',
    })
  }, [status])

  const onInteract = () => {
    if (!isInteractive) return
    hideTimer.clear('Interacting with splash')

    hideTimer.set('Hide status interactive from press', () => {
      AppStatus.set('idle')
    }, INTERACTIVE_SPLASH_TRANSITION)
    interactiveSplash.startTransition()

  }

  const showInteractive = isInteractive && !hideInteractive

  return (
    <>
      <Overlay visible={visibleOverlay} styles={{ wrapper: { zIndex: 199 } }} />

      <View css={[styles.wrapper]}>
        <View style={styles.overlay}>
          <View css={[styles.container, visibilityStyle('done')]}>
            <Icon
              debugName='AppStatus:done'
              name='check'
              size={Theme.values.iconSize[4]}
              color={Theme.colors.light.primary3}
              style={visibilityStyle('done')}
            />
          </View>
        </View>

        <View style={styles.overlay}>
          <View css={[styles.container, visibilityStyle('loading')]}>
            <ActivityIndicator debugName='AppStatus:loading' css={visibilityStyle('loading')} />
          </View>
        </View>
      </View>

      <View css={[styles.intermediateSplashWrapper, { opacity: status === 'splash' ? 1 : 0, pointerEvents: status === 'splash' ? undefined : 'none' }]}>
        <View variants={['column', 'gap:2', 'fullHeight', 'flex']}>

          <View variants={['fullWidth']}>
            <Logo
              debugName='AppStatus:splash'
              variants={['splash']}
              styles={{ wrapper: { width: 134 }, image: { width: 134 } }}
              responsive={false}
            />
          </View>

          <Text text={t('splashText')} variants={['h2', 'color:primary3', 'margin:auto', 'textCenter']} />

        </View>
      </View>

      <View css={[styles.splashWrapper, { opacity: showInteractive ? 1 : 0, pointerEvents: showInteractive ? 'auto' : 'none' }]}>
        <View variants={['fullHeight', 'fullWidth', 'center', 'relative']}>
          <InteractiveSplashContent animationData={interactiveSplash} />
          <InteractiveSplashContent.Button
            onPress={onInteract}
            style={interactiveSplash.iconStyle}
          />
        </View>
      </View>

    </>
  )
}

export const AppStatusOverlay = React.memo(AppStatusOverlayComponent, () => true)

const styles = variantProvider.createComponentStyle((theme) => ({
  overlay: {
    ...theme.presets.full,
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.center,
  },
  container: {
    width: indicatorSize,
    height: indicatorSize,
    backgroundColor: theme.colors.neutral1,
    borderRadius: theme.borderRadius.rounded,
    ...theme.presets.center,
  },
  wrapper: {
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 200,
    pointerEvents: 'none',
    transition: 'transform 0.3s ease',
  },
  splashWrapper: {
    display: 'flex',
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 200,
    pointerEvents: 'none',
    transition: 'all 0.3s ease',
    backgroundColor: theme.colors.primary1,
  },
  intermediateSplashWrapper: {
    display: 'flex',
    ...theme.spacing.paddingHorizontal(40),
    paddingTop: theme.spacing.value(10),
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.alignCenter,
    zIndex: 200,
    transition: 'all 0.3s ease',
    backgroundColor: theme.colors.primary1,

    [theme.media.down('large')]: {
      ...theme.spacing.paddingHorizontal(16),
      paddingTop: theme.spacing.value(10),
    },
    [theme.media.down('largeish')]: {
      ...theme.spacing.paddingHorizontal(8),
      paddingTop: theme.spacing.value(8),
    },
    [theme.media.down('mid')]: {
      ...theme.spacing.paddingHorizontal(4),
      paddingTop: theme.spacing.value(4),
    },
    [theme.media.down('small')]: {
      ...theme.spacing.paddingHorizontal(2),
      paddingTop: theme.spacing.value(2),
    },
  },
}), true)
