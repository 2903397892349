import React from 'react'
import { ActionIcon } from '@/components'

import { PropsOf } from '@codeleap/common'

export const ShareButton = (props: Partial<PropsOf<typeof ActionIcon>>) => {
  return (
    <ActionIcon
      debugName='share lesson'
      name='share'
      variants={['minimal', 'iconSize:3']}
      {...props}
    />
  )
}
