import { React, I18N, useAppI18N, Theme } from '@/app'
import { Link, Page, Text, View, Button, Image, Touchable, Icon } from '@/components'
import { APIClient } from '@/services'
import { PropsOf } from '@codeleap/common'
import { AppStatus } from '@/redux'
import { authWall } from '@/utils'

const BRIEFCASE_ICON_SIZE = 20

type WorkingAtProps = {
  iconProps?: PropsOf<typeof Icon>
  textLeftProps?: PropsOf<typeof Text>
  textRightProps?: PropsOf<typeof Text>
  wrapperProps?: PropsOf<typeof Touchable>
  white?: boolean
  disabled?: boolean
}

export const WorkingAt = (props: WorkingAtProps) => {

  const { iconProps, textLeftProps, textRightProps, white = false, disabled = false } = props

  const { profile } = APIClient.Session.useSession()
  const unknownOrg = APIClient.Organisations.useUnknownOrganisation()
  const { t } = useAppI18N()
  const organisation = profile?.organization?.name ?? unknownOrg?.organisation?.name

  return (
    <Touchable
      debugName={'Working At Pressed'}
      onPress={authWall(() => AppStatus.setModal('organisationInfo'))}
      disabled={disabled}
      variants={['row', 'justifyStart']}
      tabIndex={0}
      aria-label={`${t('components.workingat.title')} ${organisation}`}
    >
      <Icon
        {...iconProps}
        variants={[...(iconProps?.variants ?? []), white ? 'white' : 'neutral10']}
        name='briefcase'
        size={BRIEFCASE_ICON_SIZE}
      />
      <View variants={['row', 'marginLeft:1', 'gap:0.5']}>
        <Text
          text={t('components.workingat.title')}
          {...textLeftProps}
          variants={[...(textLeftProps?.variants ?? []), white ? 'color:neutral1' : 'color:neutral10']}
        />
        <Text
          {...textRightProps}
          text={organisation}
          variants={['bold', ...(textRightProps?.variants ?? []), white ? 'color:neutral1' : 'color:neutral10']}
        />
      </View>
    </Touchable>
  )
}
