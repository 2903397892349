import { api } from '@/app'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { LessonCategory } from '@/types'

// NOTE this is not being used, perhaps in the future.

const BASE_URL = 'learn/categories/'

export const lessonCategoriesManager = new QueryManager({
  itemType: {} as LessonCategory,
  name: 'lessonCategories',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<LessonCategory>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<LessonCategory>(`${BASE_URL}${id}/`)

    return response.data
  },
})
