import { analytics } from '@/services'
import { Profile } from '@/types'
import { useIsomorphicEffect } from '@codeleap/common'
import { useLocation } from '@reach/router'

export const useUtm = (profile: Profile) => {
  const currentURL = useLocation()

  useIsomorphicEffect(() => {
    const queryParams = new URLSearchParams(currentURL.search)

    const utmMedium = queryParams.get('utm_medium')
    const utmSource = queryParams.get('utm_source')
    const utmCampaign = queryParams.get('utm_campaign')
    const utmContent = queryParams.get('utm_content')
    const utmTerm = queryParams.get('utm_term')

    if (utmSource || utmMedium || utmCampaign || utmContent || utmTerm) {
      const storedURLs = JSON.parse(localStorage.getItem('urlsWithUTM')) || []

      const isURLStored = storedURLs.some((url: string) => url === currentURL.href)

      if (!isURLStored) {
        storedURLs.push(currentURL.href)
        localStorage.setItem('urlsWithUTM', JSON.stringify(storedURLs))
        const user = { ...profile, utm: currentURL.search }
        analytics?.identify({ user: user, isLoggedIn: !!profile })
      }
    }
  })

}
