import { Settings } from '@/app'
import { APIClient } from '@/services'
import { AnyFunction } from '@codeleap/common'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { getLinkedInAccessToken } from '.'

const LinkedInAuth = Settings.ApiCredentials.LinkedIn

export function useLinkedInAuth(handler: AnyFunction) {
  const onSuccess = async (code: string) => {
    const data = await getLinkedInAccessToken(code)

    if (!data?.access_token) return

    const socialResult = await APIClient.Session.socialLogin({
      provider: 'linkedIn',
      params: data?.access_token,
    })

    handler?.(socialResult)
  }

  const { linkedInLogin } = useLinkedIn({
    clientId: LinkedInAuth.ClientID,
    redirectUri: LinkedInAuth.RedirectUri,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess,
  })

  return linkedInLogin
}
