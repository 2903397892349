import { createSlice } from '@codeleap/common'

export type SessionState = {
  firebaseUserClaims: {
    [key: string]: any
  }
  onboardingQuestionsWasAppeared: boolean
}

const initialState: SessionState = {
  firebaseUserClaims: {},
  onboardingQuestionsWasAppeared: false,
}

export const sessionSlice = createSlice({
  name: 'Session',
  initialState,
  reducers: {
    setUserClaims: (state, firebaseUserClaims: SessionState['firebaseUserClaims']) => ({
      ...state,
      firebaseUserClaims,
    }),
    setOnboardingQuestionsWasAppeared: (state, setTo: boolean) => ({
      ...state,
      onboardingQuestionsWasAppeared: setTo,
    }),
  },
  asyncReducers: {},
})
