import React from 'react'
import { I18N } from '@/app'
import { Text, View, Button, WorkingAt, Modal } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'

type OrganisationInfoModalProps = {
  onClose?: () => void
}

export const OrganisationInfo = (props: OrganisationInfoModalProps) => {
  const isOpen = useAppSelector(store => store.AppStatus.modals.organisationInfo)

  return (
    <Modal
      debugName={'Organisation Info Modal'}
      visible={isOpen}
      showClose={false}
      title={'Organisation Information'}
      variants={['centered', 'center', 'boxPadding:4']}
      responsiveVariants={{ ['small']: ['topRadiusLarge'] }}
      onClose={() => AppStatus.setModal('organisationInfo')}
      zIndex={9999}
      {...props}
    >
      <WorkingAt disabled />
      <View variants={['column', 'alignCenter', 'gap:2']}>
        <Text variants={['alignCenter', 'textCenter', 'marginTop:2']} text={I18N.t('modals.orgInfo.description')} />
        <Button
          variants={['fullWidth']}
          text={I18N.t('modals.orgInfo.confirmButton')}
          debugName={'Organisation Info Button'}
          onPress={() => AppStatus.setModal('organisationInfo')}
        />
      </View>
    </Modal>
  )
}
