import { v4 } from 'uuid'
import { Settings } from '@/app'

const createCertification = (name: string, level: string): string => {
  const url = new URL('https://www.linkedin.com/profile/add')

  const certificateId = v4()

  const currentYear = (new Date()).getFullYear()
  const currentMonth = (new Date()).getMonth() + 1

  const data = {
    startTask: 'CERTIFICATION_NAME',
    name: `${name} - ${level}`,
    organizationId: Settings.ApiCredentials.LinkedIn.ORG_ID,
    issueYear: currentYear,
    issueMonth: currentMonth,
    expirationYear: null,
    expirationMonth: null,
    certUrl: Settings.Website.AppSite,
    certId: certificateId,
  }

  var searchParams = new URLSearchParams(url.search)

  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      searchParams.set(key, data[key])
    }
  }

  url.search = searchParams.toString()

  return url.toString()
}

export const LinkedIn = {
  createCertification,
}
