import React from 'react'
import { Button, Text, View } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { onUpdate, useI18N } from '@codeleap/common'
import { APIClient } from '@/services'
import { useFirebase } from '@/utils'

type VerifyEmailProps = {}

export const VerifyEmailBeforeForm = (props: VerifyEmailProps) => {
  const { isEmailVerified, resendEmailConfirmation } = APIClient.Session.useVerifyEmail()
  const { t } = useI18N()

  const emailVerifyOpen = useAppSelector(store => store.AppStatus.modals.verifyEmailBefore)
  const firebase = useFirebase()

  const email = firebase?.auth().currentUser?.email

  const openEmail = () => {
    window.open('mailto:', '_blank', 'noopener,noreferrer')
  }

  const onSendEmail = () => {
    resendEmailConfirmation()
  }

  onUpdate(() => {
    if (isEmailVerified && emailVerifyOpen) {
      AppStatus.transitionModals(['verifyEmailBefore', 'verifyEmailAfter'])
    }
  }, [isEmailVerified, emailVerifyOpen])

  return (
    <View variants={['flex', 'column']}>
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('VerifyEmail.title1')}
      />
      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={t('VerifyEmail.sub1', {
          email,
        })}
      />

      <View variants={['column', 'gap:2', 'marginTop:auto']}>
        <Button
          text={t('VerifyEmail.openEmail')}
          onPress={openEmail}
          debugName={'Open email'}
          variants={['marginHorizontal:auto', 'fullWidth', 'large', 'pill']}
        />

        <Button onPress={onSendEmail} debugName={'Resend verification'} variants={['outline', 'pill', 'large', 'row', 'gap:1', 'alignCenter', 'justifyCenter']}>
          <Text text={t('VerifyEmail.txt1')} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          <Text text={t('VerifyEmail.txt2')} variants={['medium', 'bold', 'color:primary3']} />
        </Button>
      </View>
    </View>
  )
}
