import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { TypeGuards } from '@codeleap/common'

export const authWall = (onAuth?: () => any) => {
  return () => {
    const profile = APIClient.Session.QueryKeys.me.getData()
    if (!profile) {
      AppStatus.setModal('authWall')
      return
    }

    if (TypeGuards.isFunction(onAuth)) {
      onAuth?.()
    }
  }

}

export const unknownOrgWall = (onAuth?: () => any) => {
  return () => {
    const profile = APIClient.Session.QueryKeys.me.getData()

    if (profile?.organization?.is_unknown) {
      AppStatus.setModal('unknownOrganisation')
      return
    }

    if (TypeGuards.isFunction(onAuth)) {
      onAuth?.()
    }
  }
}

export const useAuthWall = () => {
  const session = APIClient.Session.useSession()

  return !session.profile
}
