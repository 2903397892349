import React from 'react'
import { ActionIcon } from '@/components'
import { Theme, variantProvider } from '@/app'

export const ModalHeaderActions = ({ onClose, onShare }) => {
  return <>
    <ActionIcon
      debugName='achievementDetail:close'
      name='share'
      variants={['minimal', 'overlap', 'iconSize:2', 'absolute', 'neutral1']}
      style={styles.positionLeft}
      onPress={onShare}
    />

    <ActionIcon
      debugName='achievementDetail:close'
      name='x'
      variants={['outline', 'noBorder', 'overlap', 'iconSize:1', 'absolute']}
      style={styles.positionRight}
      onPress={onClose}
    />
  </>
}

const spacing = Theme.spacing.value(4)

const styles = variantProvider.createComponentStyle(theme => ({
  positionLeft: {
    top: spacing,
    left: spacing,

    [theme.media.down('small')]: {
      top: spacing / 2,
      left: spacing / 2,
    }
  },
  positionRight: {
    top: spacing,
    right: spacing,

    [theme.media.down('small')]: {
      top: spacing / 2,
      right: spacing / 2,
    }
  }
}), true)
