import React from 'react'
import { Avatar, AvatarProps } from '@/components'
import { APIClient } from '@/services'

type MyAvatarProps = Partial<AvatarProps>

export const MyAvatar = (props: MyAvatarProps) => {
  const { profile } = APIClient.Session.useSession()

  return (
    <Avatar
      debugName={'My Profile Avatar'}
      name={[profile?.first_name, profile?.last_name]}
      image={profile?.avatar}
      {...props}
    />
  )
}
