import { AchievementLevel } from '@/types'
import { View, Text, Image, ProgressBar, Icon } from '@/components'
import { variantProvider } from '@/app'
import { calcPercentage } from '@/utils'

type LevelRowProps = {
  level: AchievementLevel
}

export const LevelRow = (props: LevelRowProps) => {
  const { level } = props

  if (!level?.id) return null

  const showImage = level?.achievement_progress >= level?.progression_required

  const progress = calcPercentage({ partial: level?.achievement_progress, total: level?.progression_required })

  return (
    <View 
      variants={['row', 'gap:2', 'paddingHorizontal:4', 'paddingBottom:2']} 
      responsiveVariants={{ small: ['paddingHorizontal:2'] }} 
      style={styles.wrapper}
    >
      <View css={[styles.wrapperImage, showImage && { background: level?.bg_color_light }]}>
        {showImage ? (
          <Image style={styles.image} source={level?.image} objectFit='contain' />
        ) : (
          <Icon debugName='achievement:labelRow' style={styles.image} name='padlock' />
        )}
      </View>

      <View variants={['column', 'fullWidth']}>
        <View variants={['justifySpaceBetween']}>
          <Text variants={['p2', 'color:neutral6']} text={level?.name} />
          {!!level?.verbose_progress && <Text variants={['p4', 'color:neutral5']} text={level?.verbose_progress} />}
        </View>

        <ProgressBar variants={['achievement']} progress={progress} />
      </View>
    </View>
  )
}

const IMAGE_SIZE = 20

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.border.neutral3({ width: 1, directions: ['bottom'] })
  },
  wrapperImage: {
    borderRadius: theme.borderRadius.tiny,
    ...theme.spacing.padding(1),
    ...theme.presets.center,
    background: theme.colors.neutral2,
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE
  }
}), true)
