import { LocalStorageKeys } from '@/app'
import { TypeGuards } from '@codeleap/common'
import { LocalStorage } from './localStorage'

export const ReauthenticationStore = {
  set(is: boolean) {
    LocalStorage.replaceItem(LocalStorageKeys.IN_REAUTHENTICATION, is)
  },
  get() {
    const is = LocalStorage.getItem(LocalStorageKeys.IN_REAUTHENTICATION)

    const isReauthentication = TypeGuards.isString(is) ? is === 'true' : false

    return {
      isReauthentication,
    }
  },
  reset() {
    LocalStorage.removeItem(LocalStorageKeys.IN_REAUTHENTICATION)
  }
}
