import { PaginationResponse, QueryManager, ReactQuery } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { Answer, Organisation, OverallScoreRank } from '@/types'

const BASE_URL = 'questions/answers/'

type AnswerFilters = {
  search?: string
  organisation?: Organisation['id']
}

export const answersManager = new QueryManager({
  itemType: {} as Answer,
  name: 'answers',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: AnswerFilters) => {

    const response = await api.get<PaginationResponse<Answer>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
        search: filters?.content,
        organization: filters.organisation,
      },
    })

    return response.data
  },

  retrieveItem: async (id) => {
    const response = await api.get<Answer>(`${BASE_URL}${id}/`)

    return response.data
  },

})

const scoreRanksKey = ['scoreRankings']

const getScoreRanks = async () => {
  const res = await api.get<PaginationResponse<OverallScoreRank>>(`questions/score_ranks/`)

  return res.data.results
}

export const prefetchScoreRanks = () => {
  queryClient.client.prefetchQuery(scoreRanksKey, getScoreRanks)
}

export const useScoreRanks = () => {
  const query = ReactQuery.useQuery(
    scoreRanksKey,
    getScoreRanks,
  )

  return query
}
