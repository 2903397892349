import { AppStatus } from '@/redux'
import { getFirebase } from '../../firebaseApp'
import { useSession } from './useSession'
import { ERRORS, isEmailAvailable, Profile } from './views'

export function useEdit() {
  const { profile, updateProfile, warnEmailInUse, options } = useSession()
  const editProfile = async (data: Partial<Profile>) => {

    AppStatus.set('loading')
    try {
      const emailAddressChanged = !!data.email?.trim() && (data?.email?.trim() !== profile.email.trim())

      if (emailAddressChanged) {
        const emailAvailable = await isEmailAvailable(data.email)

        if (!emailAvailable) {
          warnEmailInUse()
          throw ERRORS.emailInUse
        }
        try {
          const firebase = await getFirebase()

          await firebase.auth().currentUser.updateEmail(data.email)
        } catch (e) {
          if (e.code === 'auth/requires-recent-login') {
            throw ERRORS.reautenticationRequired
          }
        }
      }
      await updateProfile.mutateAsync(data)

      setTimeout(() => {
        AppStatus.set('done')
      }, 1000)

    } catch (e) {
      logger.error('Failed to edit profile', e, 'Auth')
      AppStatus.set('idle')
      throw e

    }

  }

  const requestPasswordReset = async () => {
    // TODO - implement this
    // OSAlert.ask({
    //   title: 'Reset password?',
    //   body: 'You will be emailed instructions to proceed.',
    //   options: [
    //     {
    //       text: 'Cancel',
    //       onPress: () => logger.info('Cancel password reset'),
    //       style: 'cancel',
    //     },
    //     {
    //       text: 'Reset',
    //       onPress: async () => {
    //         AppStatus.set('loading')
    //         await withFirebase(({ auth }) => {
    //           return auth().sendPasswordResetEmail(profile.email)
    //         })
    //         AppStatus.set('done')
    //       },
    //     },
    //   ],
    // })
  }

  return { profile, editProfile, requestPasswordReset, options }
}
