import React from 'react'
import { Tag, Text, View, Image, WorkingAt, Link } from '@/components'
import { I18N, Settings, variantProvider } from '@/app'
import { CategoryData, Question, QuestionChoice } from '@/types'
import { AnswerButton } from './AnswerButton'
import { useIsMobile } from '@/utils'
import { QuestionSlider } from './QuestionSlider'
import { APIClient } from '@/services'
import { assignTextStyle, TypeGuards } from '@codeleap/common'

export type PulsePageProps = & {
  setAnswer: (value: APIClient.PulseQuestions.AnswerData) => void
  question: Question
  visible: boolean
  answers: APIClient.PulseQuestions.AnswerData[]
  idx: number
  commentInput?: React.ReactNode
  footerHeight?: number
  onCategoryPress: (value: CategoryData) => void
}

const Wrapper = ({ children, footerHeight }) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    const _style = TypeGuards.isNumber(footerHeight) ? {
      maxHeight: `calc(100svh - ${footerHeight}px)`,
      height: `calc(100svh - ${footerHeight}px)`,
    } : {}

    return (
      <View variants={['column']} style={_style}>
        {children}
      </View>
    )
  } else {
    return <>{children}</>
  }
}

export const PulsePage = (props: PulsePageProps) => {
  const {
    question,
    setAnswer,
    visible,
    answers,
    idx,
    commentInput = null,
    footerHeight,
    onCategoryPress,
  } = props

  const {
    title,
    comment,
    category,
    choices,
    sub_category,
    show_scale,
    label_left,
    label_right,
    min,
    max,
    scale_step,
    default_value,
  } = question
  const hasImage = choices?.length ? choices?.some(c => !!c?.mobile_image) : null

  const answer = answers?.[idx] ?? {
    value: choices?.length ? null : default_value,
    comment: null,
  }

  const onPressAnswer = (item: QuestionChoice) => {
    const selected = answer?.value === item?.value

    const newValue = selected ? null : item?.value

    setAnswer({ ...answer, value: newValue })
  }

  const renderChoice = (choice: QuestionChoice, i) => {
    return <AnswerButton
      key={'choice' + choice?.id + i}
      selected={answer?.value === choice?.value}
      onPress={() => onPressAnswer(choice)}
      image={choice?.mobile_image}
      text={choice?.label}
    />
  }

  if (!TypeGuards.isNumber(footerHeight)) return <View />

  return <Wrapper footerHeight={footerHeight}>
    <View
      variants={['fullWidth', 'alignCenter', 'justifySpaceBetween', 'relative', 'paddingHorizontal:4', 'paddingTop:2']}
      responsiveVariants={{ small: ['column', 'alignStart', 'gap:2', 'paddingHorizontal:2'] }}
    >
      <WorkingAt />

      <View variants={['row', 'gap:1', 'center']}>
        <Tag
          debugName={'pulseQuestion:tag'}
          text={category?.name}
          textProps={{ variants: ['p3', 'color:neutral9'] }}
          leftIconProps={{ source: category?.mobile_icon, variants: ['size:1', 'color:neutral5'], objectFit: 'contain' }}
          onPress={() => onCategoryPress(category)}
        />
        <Tag
          debugName={'pulseQuestion:tag'}
          text={sub_category?.name}
          textProps={{ variants: ['p3', 'color:neutral9'] }}
          leftIconProps={{ source: sub_category?.mobile_icon, variants: ['size:1', 'color:neutral5'], objectFit: 'contain' }}
          onPress={() => onCategoryPress(sub_category)}
        />
      </View>
    </View>

    <View
      css={[styles.content, !visible && { overflowY: 'hidden' }]}
      variants={['column', 'gap:2', 'marginVertical:2', 'paddingHorizontal:4']}
      responsiveVariants={{ small: ['paddingHorizontal:2'] }}
    >
      <View style={styles.image}>
        <Image source={question?.mobile_image} style={styles.image} />
      </View>

      <View variants={['gap:4', 'column']}>
        <Text text={title} variants={['h3']} />

        {!!choices?.length
          ? (
            <View style={hasImage ? styles.itemsGrid : styles.itemsFlex}>
              {choices?.map(renderChoice)}
            </View>
          )
          : (
            <QuestionSlider
              value={[answer?.value]}
              onValueChange={(vl) => setAnswer({ ...answer, value: vl?.[0] as number })}
              leftLabel={label_left}
              rightLabel={label_right}
              showScale={show_scale}
              min={min}
              max={max}
              step={scale_step}
              defaultValue={[default_value]}
            />
          )
        }

        <View variants={['gap:1', 'column']} responsiveVariants={{ small: ['gap:1', 'column', 'paddingBottom:6'] }}>
          <Text variants={['p3', 'color:neutral5']} text={I18N.t('pulseQuestion.optional')} />
          <Text variants={['p3', 'color:neutral5']} text={comment} />
          {commentInput}
          <Text style={{ display: 'inline-block' }} variants={['p3', 'gap:0.5', 'marginTop:-1']}>
            {I18N.t('pulseQuestion.txt1')} <Link
              variants={['color:neutral5', 'cursor:pointer', 'noUnderline']}
              style={styles.link}
              text={I18N.t('pulseQuestion.communityGuidelines')}
              to={Settings?.ContactINFO.CommunityGuidelines}
              openNewTab
            />
          </Text>
        </View>
      </View>
    </View>
  </Wrapper>
}

const PAGE_HEIGHT = 340

const styles = variantProvider.createComponentStyle(theme => ({
  link: {
    display: 'inline-block',
    ...assignTextStyle('p3')(theme).text,
    fontWeight: '700',
  },
  image: {
    ...theme.presets.fullWidth,
    borderRadius: theme.borderRadius.medium,

    [theme.media.up('small')]: {
      display: 'none',
    },
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',

    [theme.media.up('small')]: {
      maxHeight: PAGE_HEIGHT,
      height: PAGE_HEIGHT,
    },
  },
  itemsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: theme.spacing.value(2),

    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  itemsFlex: {
    ...theme.presets.column,
    gap: theme.spacing.value(2),
  },
}), true)
