import React from 'react'
import { Alert } from '@/components'
import { AnyFunction, useI18N } from '@codeleap/common'
import { useAppSelector } from '@/redux'
import { useModal } from '@/utils'

type DeleteAccountAlertProps = {
  visible: boolean
  toggle: AnyFunction
  onDelete: AnyFunction
}

export const DeleteAccountAlert = (props: DeleteAccountAlertProps) => {
  const {
    visible,
    toggle,
    onDelete,
  } = props

  const status = useAppSelector(state => state?.AppStatus?.status)

  const { t } = useI18N()

  const confirmModal = useModal()

  return <>
    <Alert
      visible={visible && !(status === 'loading' || status === 'done')}
      toggle={toggle}
      type='destructive'
      title={t('profile.deleteAccountModal.title1')}
      body={t('profile.deleteAccountModal.body1')}
      onAction={confirmModal.toggle}
      debugName={'delete account alert'}
      actionButtonProps={{
        text: t('profile.deleteAccountModal.confirm'),
        debugName: 'delete account confirm',
      }}
      dismissButtonProps={{
        text: t('profile.deleteAccountModal.cancel'),
        debugName: 'delete account cancel',
        variants: ['flex', 'outline', 'large', 'pill'],
      }}
    />
    
    <Alert
      {...confirmModal}
      type='destructive'
      title={t('profile.deleteAccountModal.title2')}
      body={t('profile.deleteAccountModal.body2')}
      onAction={onDelete}
      debugName={'delete account alert confirm'}
      actionButtonProps={{
        text: t('profile.deleteAccountModal.delete'),
        debugName: 'delete account action',
      }}
      dismissButtonProps={{
        text: t('profile.deleteAccountModal.cancel'),
        debugName: 'delete account cancel',
        variants: ['flex', 'outline', 'large', 'pill'],
      }}
    />
  </>
}
