import { View } from '@/components';
import { arePropsEqual } from '@codeleap/common'
import React from 'react'

type ItemProps = {
  item: any
  section: { title: string; key: string }
  isFirst: boolean
  index: number
  indexBySection: number
}

type SectionsProps = {
  sections: { title: string; key: string; data: [] }[]
  SectionHeaderComponent: ({ section: { title, key }, isFirst }) => JSX.Element
  SectionSeparatorComponent: (props: { isFirst: boolean; indexBySection: number }) => JSX.Element
  renderItem: (props: ItemProps) => JSX.Element
  vertical?: boolean
  row?: boolean
}

function SectionsComponent(props: SectionsProps) {
  const {
    sections,
    SectionHeaderComponent,
    SectionSeparatorComponent,
    renderItem: _RenderItem,
    vertical = false,
    row = false,
  } = props

  const RenderItem = React.useCallback((props) => {
    return <_RenderItem {...props} />
  }, [_RenderItem])

  return <>
    {sections?.map((section, i) => {
      return <View variants={[row ? 'row' : 'column']} key={'section-wrapper' + section?.key + i}>
        <SectionHeaderComponent key={'section-header-' + i + section?.key} section={section} isFirst={i === 0} />

        {section?.data?.map((item, index) => {
          const isFirst = index === 0
          const isLast = index === section?.data?.length - 1

          const indexBySection = index + (sections[i - 1]?.data?.length ?? 0)

          return <View key={'section-innerWrapper' + index + section?.key}>
            <SectionSeparatorComponent key={'section-separator' + index + section?.key} indexBySection={indexBySection} isFirst={isFirst} />
            
            <RenderItem
              key={'section-item' + item?.id + section?.key}
              item={item} 
              section={section}
              isFirst={isFirst}
              index={index}
              indexBySection={indexBySection}
            />

            {(isLast && !vertical) ? <View key={'section-separator-last' + index + section?.key} variants={['marginRight:2']} /> : null}
          </View>
        })}
      </View>
    })}
  </>
}

export const Sections = React.memo(SectionsComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['sections', 'renderItem'],
  })
})
