import { yup } from '@codeleap/common'
import { I18N } from '../i18n'
import { setLocale } from 'yup'
import { verifyProfanity } from '@/utils'

/**Set Form default values */
setLocale({
  mixed: {
    required: () => I18N.t('form.validations.required'),
  },
  string: {
    url: () => I18N.t('form.validations.url'),
    email: () => I18N.t('form.validations.email'),
    min: ({ min }) => I18N.t('form.validations.min', { min }),
    max: ({ max }) => I18N.t('form.validations.max', { max }),
    length: ({ length }) => I18N.t('form.validations.max', { max: length }),
  },
})

yup.addMethod(yup.string, 'verifyProfanity', function (errorMessage) {
  return this.test(
    'test-profanity',
    errorMessage,
    function (txt) {
      const { path, createError } = this

      const { isProfanity, profanities } = verifyProfanity(txt)
      const words = profanities.join(', ')

      return (
        !isProfanity ||
        createError({ path, message: errorMessage || I18N.t('form.validations.profanity', { words }) })
      )
    })
})

export * from './posts'
export * from './auth'
export * from './story'
export * from './organisations'
export * from './notificationSettings'
export * from './passwordReset'
export * from './onboardingQuestions'
